import router from './router';
import User from './User';

import log from 'loglevel';
import firebase from './firebase';

class Navbar {
	constructor() {
		const clickHandler = (e) => this.clickHandler(e);
		document.querySelector('.navbar').addEventListener('click', clickHandler);

		if (!this.handlers) {
			this.handlers = [];
		}

		User.onSetCurrentUser((user) => this.userChangeHandler(user));

		document.getElementById('navbarVersion').innerHTML = window.state.version;
	}

	clearHandlers() {
		if (this.handlers) {
			this.handlers.forEach((item) => item());
			this.handlers = [];
		}
	}

	userChangeHandler(user) {
		if (user) {
			this.clearHandlers();
			document.querySelector('#navbarUser').innerHTML = user.displayName;
			this.newMessageHandler();
			this.groupHandler();
			//this.tempLoadTodo();

			if (user.type === 'admin') {
				const html = `
        
        <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Sidor
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="/kurs1">1/3</a>
            <a class="dropdown-item" href="/kurs2">2/3</a>
            <a class="dropdown-item" href="/kurs3">3/3</a>
            <a class="dropdown-item" href="/kurs3/ovningar-gs">3/3 (gehörsprov)</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="/folkmusik">Folkmusik</a>
            <a class="dropdown-item" href="/latar">Låtar</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="/old">Gamla</a>
            <a class="dropdown-item" href="/demo">Demo</a>
        </div>
    </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarAdmin" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Admin
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarAdmin">
              <a class="dropdown-item" href="/studerande">Studerande</a>
              <a class="dropdown-item" href="/gruppbyggare">Gruppbyggare</a>
              <a class="dropdown-item" href="/progressionOverview">Progression</a>
              <a class="dropdown-item" href="/exerciseHistory">Uppgift: historik</a>
              <a class="dropdown-item" href="/analysis">Analys och statisik (rytm)</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="/admintools">Admin tools</a>
              <a class="dropdown-item" href="/errorviewer" id="errorViewer">Errorviewer</a>
              <a class="dropdown-item" href="#" class="close-peerjs">Stäng PeerJS</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="/export">Export DEMO</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" data-key="user-type-admin">Typ: Admin</a>
              <a class="dropdown-item" href="#" data-key="user-type-teacher">Typ: Lärare</a>
            </div>
          </li>
        `;
				document.querySelector('#navbar_main_ul').insertAdjacentHTML('beforeend', html);
			} else {
				const el = document.querySelector('#navbarAdmin');
				if (el) el.remove();
			}
		}
	}

	newMessageHandler() {
		this.handlers.push(
			User.getCurrentUser().onLoadMessages((messages) => {
				if (messages !== undefined && messages.length > 0) {
					let unread = 0;
					messages.forEach((message, index) => {
						if (
							!(
								message.data().readRefs &&
								message
									.data()
									.readRefs.findIndex((item) => item.id === User.getCurrentUser().firebase.uid) >= 0
							)
						)
							unread++;
					});
					const unreadHtml = unread > 0 ? ` (${unread})` : '';
					document.querySelector('#nav-messages').innerHTML = `${unreadHtml}`;
				} else {
					document.querySelector('#nav-messages').innerHTML = ``;
				}
			}),
		);
	}

	tempLoadTodo() {
		this.handlers.push(
			User.getCurrentUser().onLoadTodo((todos) => {
				if (todos !== undefined && todos.length > 0) {
					let html = '';
					todos.forEach((todo, index) => {
						if (index === 10) {
							html += `... ytterligare ${todos.length - index} att-göra`;
						} else if (index < 10) {
							html += `<li class="list-group-item">${todo.data().title}</li>`;
						}
						document.querySelector('#bootstrap-modal-todo .modal-body').innerHTML =
							todo.data().body;
					});
					//document.querySelector('#user_todos').innerHTML = todo.data().body;
				}
			}),
		);
	}

	groupHandler() {
		this.handlers.push(
			User.getCurrentUser().onLoadGroups((groupDocs) => {
				if (groupDocs !== undefined && groupDocs.length > 0) {
					let html = '';
					const groups = [];
					groupDocs.forEach((doc) =>
						groups.push({
							id: doc.id,
							name: doc.data().name,
							zoomURL: doc.data().zoomURL,
							category: doc.data().category,
							weekday: doc.data().weekday,
						}),
					);
					groups.sort((a, b) =>
						a.weekday === b.weekday ? (a.name > b.name ? 1 : -1) : a.weekday > b.weekday ? 1 : -1,
					);
					if (User.getCurrentUser().type === 'admin') {
						const groupData = groups.map(
							(group) => `<a class="dropdown-item" href="/grupper/${group.id}">${group.name}</a>`,
						);
						html = `
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarGroups" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Grupper
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarGroups">
              ${groupData.join('')}
            </div>
          </li>`;

						document.querySelector('#nav-item-groups').innerHTML = html;
					} else {
						groups.forEach((group, index) => {
							html += `${group.name}`;
						});
						//document.querySelector('#nav-groups').innerHTML = html;
						//document.querySelector('#nav-link-groups').href = '#';
					}
				}
			}),
		);
	}

	show() {
		document.querySelector('.navbar').style.visibility = 'visible';
	}

	addErrorViewer(ew) {
		this.errorViewer = ew;
	}

	addError() {
		if (!this.errorCount) {
			this.errorCount = 1;
		} else {
			this.errorCount++;
		}
		this.updateErrorCountView();
	}
	updateErrorCountView() {
		document.getElementById('navbarAdmin').innerHTML = '<b>Admin (' + this.errorCount + ')</b>';
		document.getElementById('errorViewer').innerText = 'Errorviewer (' + this.errorCount + ')';
	}

	clickHandler(e) {
		/*if ((e.target.classList.contains('nav-link') || e.target.classList.contains('dropdown-item')) && !(e.target.classList.contains('dropdown-toggle'))) {
      e.preventDefault();
      e.stopPropagation();
      if (false) {

      } else {
        router.goto(e.target.getAttribute('href'));
      }
    }*/
		if (e.target.classList.contains('close-peerjs')) {
			this.getPeerJS().then((peer) => {
				//peer.requestConnection(e.target.dataset.to);
				peer.closeConnection();
				alert('close');
			});
		} else if (e.target.dataset.key === 'user-type-teacher') {
			// allow admin to temporary act as teacher
			firebase.db
				.collection('users')
				.doc(User.getCurrentUser().firebase.uid)
				.get()
				.then((doc) => {
					if (doc.exists && doc.data().type === 'admin') User.getCurrentUser().type = 'teacher';
					router.goto('/hem');
				});
		} else if (e.target.dataset.key === 'user-type-admin') {
			firebase.db
				.collection('users')
				.doc(User.getCurrentUser().firebase.uid)
				.get()
				.then((doc) => {
					if (doc.exists && doc.data().type === 'admin') User.getCurrentUser().type = 'admin';
					router.goto('/hem');
				});
		}
		log.debug('click', e);
	}

	async getPeerJS() {
		const { default: _ } = await import(/* webpackChunkName: "peerJS" */ '../core/webrtc/peerJS');
		return _;
	}

	/**
	 *
	 * @param {string} status disconnected, connected, disabled
	 */
	setMidiKeyboardStatus(status) {
		const el = document.getElementById('midiKeyboardStatus');
		if (!el) return;
		let opacity = 100;
		let src = 'piano-icon-red.svg';
		switch (status) {
			case 'disabled':
				opacity = 25; // fall through
			case 'enabled':
				src = 'piano-icon.svg';
				break;
			case 'disconnected':
				// do nothing...
				break;
			default:
				console.warn('setMidiKeyboardStatus: Invalid status ' + status);
				return;
		}
		el.style.opacity = opacity.toString() + '%';
		el.src = '/img/'+src;
	}
}

const nav = new Navbar();
export default nav;
