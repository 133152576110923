import firebase from './firebase';
import dayjs from 'dayjs';
import log from 'loglevel';
import logger from './Logger';
import User from './User';

export default class Syllabus {
	constructor(syllabusId, uid) {
		if (firebase.isAmi) Syllabus.initData();
		this.id = syllabusId;
		this.uid = uid;
		/*if (syllabusId) {
      this.init();
    }*/
	}

	static initData() {
		Syllabus.templates = {
			'new-user': {
				name: 'Uppgifter (ny användare)',
				chapters: ['newUser'],
			},
			'1-intro': {
				name: 'Uppgifter (introduktion)',
				chapters: [
					'rhythmQuarterPlain',
					'rhythmQuarterTies',
					'rhythmQuarterRests',
					'rhythm8plain',
					'rhythm8ties',
					'progressionMelody13',
					'progressionMelody14',
					'progressionMelody135',
					'progressionMelody15',
				],
			},
			'1-melody': {
				name: 'Uppgifter (melodi)',
				chapters: [
					'melodyA',
					'melodyOctaveGclef',
					'melodyOctaveFclef',
					'melodyA2',
					'melodyB',
					'major',
					'progressionNoteOctaveG',
					'progressionNoteOctaveF',
					'progressionNoteAccidentials',
					'progressionKeys',
					'progressionMelody13',
					'progressionMelody14',
					'progressionMelody135',
					'progressionMelody15',
				],
			},
			'1-default': {
				name: 'Uppgifter, kurs 1',
				chapters: [
					'rhythmQuarterPlain',
					'rhythmQuarterTies',
					'rhythmQuarterRests',
					'melodyA',
					'melodyOctaveGclef',
					'melodyOctaveFclef',
					'melodyA2',
					'melodyB',
					'major',
					'rhythm8plain',
					'rhythm8ties',
					'intervalA',
					'majorChordProgA',
					'melodyC',
					'rhythm8plain34',
					'minorA',
					'minorChordsA',
					'rhythm8rests',
					'interval2',
					'transpose1',
					'swing1',
					'misc1',
					//'review1',
					'review1post',
					'progressionRhythmWrite8Plain',
					'progressionRhythmWrite16Plain',
					'progressionNoteOctaveG',
					'progressionNoteOctaveF',
					'progressionNoteAccidentials',
					'progressionKeys',
					'progressionMelody13',
					'progressionMelody14',
					'progressionMelody135',
					'progressionMelody15',
					'progressionIntervalNatural1',
					'progressionIntervalNatural2',
					'progressionIntervalNatural3',
					'progressionIntervalNatural4',
					'progressionInterval15',
					'progressionInterva58',
					'progressionInterval18',
					'progressionIntervaDown15',
					'progressionChordMajorMinor',
					'progressionChordAugDim',
					'progressionChordSeventh',
					'progressionChordProgMajor145',
					'progressionChordProgMinor145',
					'progressionChordProgMajorParallels',
					'progressionChordProgMinorParallels',
				],
			},
			'2-default': {
				name: 'Uppgifter, kurs 2',
				chapters: [
					'review1post',
					'rhythm8plain',
					'rhythm8ties',
					'rhythm8rests',
					'melodyMajor16',
					'melodyMinor16',
					'rhythm16plain',
					'interval2b',
					'melodyMajor76',
					'harmonicMinor',
					'chordsMajorMinor',
					'minorB7',
					'minorChordProgA',
					'rhythm16ties',
					'intervalMinor',
					'transpose2',
					'majorChordProgB',
					'minorChordProgB',
					'melodyMajor65',
					'augIntervalChords',
					'dimIntervalChords',
					'melodyMajor55',
					'noteStuff',
					'melodicMinor',
					'intervalSummary1',
					'rhythm16rest',
					'chordMajorMinorOpen',
					'minorScalesSummary',
					'review2',
					'review2post',
					'progressionRhythmWrite8Plain',
					'progressionRhythmWrite16Plain',
					'progressionNoteOctaveG',
					'progressionNoteOctaveF',
					'progressionNoteAccidentials',
					'progressionKeys',
					'progressionMelody13',
					'progressionMelody14',
					'progressionMelody135',
					'progressionMelody15',
					'progressionIntervalNatural1',
					'progressionIntervalNatural2',
					'progressionIntervalNatural3',
					'progressionIntervalNatural4',
					'progressionInterval15',
					'progressionInterva58',
					'progressionInterval18',
					'progressionIntervaDown15',
					'progressionChordMajorMinor',
					'progressionChordAugDim',
					'progressionChordSeventh',
					'progressionChordProgMajor145',
					'progressionChordProgMinor145',
					'progressionChordProgMajorParallels',
					'progressionChordProgMinorParallels',
				],
			},
			'3-default': {
				name: 'Uppgifter, kurs 3',
				chapters: [
					'review1post',
					'review2post',
					'rhythm8plain',
					'rhythm8ties',
					'rhythm8rests',
					'rhythm16plain',
					'rhythm16ties',
					'rhythm16rest',
					'harmonicAnalysisMajor145',
					'harmonicAnalysisMinor145',
					'rhythm38',
					'rhythm68',
					'melodyMajor',
					'seventhChords',
					'dominantSeventh',
					'interval3',
					'chordInversions',
					'rhythm22',
					'melodyPentatonic',
					'harmonicAnalysisMajorParallels',
					'swing2',
					'blues',
					'harmonicAnalysisMinorParallels',
					'rhythmDupletTriplet',
					'transposingInstruments',
					'scaleSummary',
					'miscReview3',
					'chordlistensummary',
					'progressionRhythmWrite8Plain',
					'progressionRhythmWrite16Plain',
					'progressionNoteOctaveG',
					'progressionNoteOctaveF',
					'progressionNoteAccidentials',
					'progressionKeys',
					'progressionMelody13',
					'progressionMelody14',
					'progressionMelody135',
					'progressionMelody15',
					'progressionIntervalNatural1',
					'progressionIntervalNatural2',
					'progressionIntervalNatural3',
					'progressionIntervalNatural4',
					'progressionInterval15',
					'progressionInterva58',
					'progressionInterval18',
					'progressionIntervaDown15',
					'progressionChordMajorMinor',
					'progressionChordAugDim',
					'progressionChordSeventh',
					'progressionChordProgMajor145',
					'progressionChordProgMinor145',
					'progressionChordProgMajorParallels',
					'progressionChordProgMinorParallels',
				],
			},
			'I-default': {
				name: 'Uppgifter, kurs I',
				chapters: [
					'rhythm16I',
					'rhythmTripletsI',
					'rhythmQuintupletI',
					'rhythmOddMeter',
					'melodyAtonal',
					'modi',
					'chordI',
					'nonHarmonicTonesI',
					'voicing356',
					'harmonicAnalysisMajorParallelsI',
					'harmonicAnalysisInversion4',
					'harmonicAnalysisCadenza',
					'harmonicAnalysisSecDom',
					'harmonicAnalysisMellankadens',
					'harmonicAnalysisKpBk',
					'harmonicAnalysisDom9',
					'voicingChoral',
				],
			},
		};
		Syllabus.chapters = {
			newUser: {
				text: 'Välkommen',
				sections: [
					{
						text: 'Om denna sida',
						type: 'article',
						id: 'newUser',
						key: 'new_user_welcome__1',
					},
				],
			},
			rhythmQuarterPlain: {
				text: 'Fjärdedelar, grundfigurer',
				category: 'rhythm',
				sections: [
					{ text: 'Introduktion', type: 'section' },
					//{text: 'Notio', type: 'embed', url: 'https://deploy-preview-67--notio-novia-fi.netlify.app/', id: 'notio', key: 'notio'},
					{
						text: 'Incredibox',
						type: 'url',
						target: '_blank',
						url: 'https://www.incredibox.com/demo/',
						id: 'incredibox',
						key: 'rhythm_incredibox',
					},
					{
						text: 'Noter och 4/4-taktart',
						type: 'article',
						id: 'dt10',
						key: 'rhythm_quarter_plain_math__dt10',
					},
					{
						text: 'Knacka en rytm',
						type: 'exercise',
						id: '23PHVoUIVNXvgaxTTOE4',
						key: 'rhythm_quarter_plain_play__intro1',
					},
					{
						text: 'En långsam puls',
						type: 'exercise',
						id: 'qOWMVMQb4CVewq9bZBrF',
						key: 'rhythm_quarter_plain_play__intro2',
					},
					{
						text: 'En snabb puls',
						type: 'exercise',
						id: 'Xxib958dWsKiLWznWIrN',
						key: 'rhythm_quarter_plain_play__intro3',
					},
					{
						text: 'Fjärdedelsnot',
						type: 'exercise',
						id: 'eekr0LEdGVaG5SgIX53Z',
						key: 'rhythm_quarter_plain_play__intro4',
					},
					{
						text: 'Takt',
						type: 'exercise',
						id: 'f5eELJOhVHVy1FKY7tOa',
						key: 'rhythm_quarter_plain_play__intro5',
					},
					{
						text: 'Se hur det går',
						type: 'exercise',
						id: 'KO4Bz0zFrxq7Hov6CMeG',
						key: 'rhythm_quarter_plain_play__intro6',
					},
					{ text: 'Grundfigurer', type: 'section' },
					{
						text: 'Spela: Fjärdedel och halvnot',
						type: 'exercise',
						id: 'TieHr1gUW8alvqIPSdyo',
						key: 'rhythm_quarter_plain_play__lvl1',
						showHistory: 1,
					},
					{
						text: 'Spela: Punkterad halvnot',
						type: 'exercise',
						id: 'eIRmgkcRaQFkcFq9DFtp',
						key: 'rhythm_quarter_plain_play__lvl2',
						showHistory: 1,
					},
					{
						text: 'Spela: Fyra takter',
						type: 'exercise',
						id: 'ETUuvbPQtshD85o57Y3S',
						key: 'rhythm_quarter_plain_play__lvl3',
						showHistory: 1,
					},
					{
						text: 'Spela: Utan slagmarkeringar',
						type: 'exercise',
						id: 'LCmLTtBHF1fvA16RgWaZ',
						key: 'rhythm_quarter_plain_play__lvl4',
						showHistory: 1,
					},
					{
						text: 'Beskriv notbilden',
						type: 'article',
						id: 'rhythm4plainExplain',
						key: 'rhythm_quarter_plain_44_explain__lvl1',
					},
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt13a',
						key: 'rhythm_quarter_plain_read__dt13',
					},
					{
						text: 'Skriva: Introduktion',
						type: 'exercise',
						id: 'vdy0n5vqLTk0Bix5DzGz',
						key: 'rhythm_quarter_plain_write__lvl1',
					},
					{
						text: 'Skriva: Fyra figurer',
						type: 'exercise',
						id: 'vzcPwqYwNrvFO79grqA2',
						key: 'rhythm_quarter_plain_write__lvl2',
						showHistory: 1,
					},
					{
						text: 'Skriva: Åtta figurer',
						type: 'exercise',
						id: 'XFyoe3Ihe6r9zLRmPdyt',
						key: 'rhythm_quarter_plain_write__lvl3',
						showHistory: 1,
					},
					{
						text: 'Skriva: Fyra takter',
						type: 'exercise',
						id: 'ztgfAriZsblkqbMo7APM',
						key: 'rhythm_quarter_plain_write__lvl4',
						showHistory: 1,
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt13m',
						key: 'rhythm_quarter_plain_write__dt13',
					},
				],
			},
			rhythmQuarterTies: {
				text: 'Fjärdedelar, bindebåge',
				category: 'rhythm',
				sections: [
					{
						text: 'Spela',
						type: 'exercise',
						id: '5NVMzxIzn81JwXiQakP7',
						key: 'rhythm_quarter_ties_play__lvl1',
						showHistory: 1,
					},
					{
						text: 'Spela med metronom',
						type: 'exercise',
						id: '1uO45OsERCG5GpBi7qFY',
						key: 'rhythm_quarter_ties_play__lvl2',
						showHistory: 1,
					},
					{
						text: 'Beskriv notbilden',
						type: 'article',
						id: 'rhythm4tiesExplain',
						key: 'rhythm_quarter_ties_44_explain__lvl1',
					},
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt15a',
						key: 'rhythm_quarter_ties_read__dt15',
					},
					{
						text: 'Skriva (introduktion)',
						type: 'exercise',
						id: 'br4z6DVV5kpfy2hofxJj',
						key: 'rhythm_quarter_ties_write__intro',
					},
					{
						text: 'Skriva',
						type: 'exercise',
						id: 'SwF7H1DXPC06yIxJV96x',
						key: 'rhythm_quarter_ties_write__lvl1',
						showHistory: 1,
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt15m',
						key: 'rhythm_quarter_ties_write__dt15',
					},
				],
			},
			rhythmQuarterRests: {
				text: 'Fjärdedelar, paus',
				category: 'rhythm',
				sections: [
					{
						text: 'Introduktion',
						type: 'article',
						id: 'dt16',
						key: 'rhythm_quarter_rest_intro__dt16',
					},
					{
						text: 'Spela',
						type: 'exercise',
						id: 'h4h2QoPZ7Avm42w8nhwz',
						key: 'rhythm_quarter_rest_play__lvl1',
						showHistory: 1,
					},
					{
						text: 'Spela utan metronom',
						type: 'exercise',
						id: 'ruYLDxnf7Udm83Yf8z7X',
						key: 'rhythm_quarter_rest_play__lvl2',
					},
					{
						text: 'Beskriv notbilden',
						type: 'article',
						id: 'rhythm4restsExplain',
						key: 'rhythm_quarter_rests_44_explain__lvl1',
					},
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt17a',
						key: 'rhythm_quarter_rest_read__dt17',
					},
					{
						text: 'Skriva',
						type: 'exercise',
						id: 'Jb6LpFCtHxcFF0Z83dGE',
						key: 'rhythm_quarter_rest_write__lvl1',
						showHistory: 1,
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt17m',
						key: 'rhythm_quarter_rest_write__dt17',
					},
				],
			},
			melodyA: {
				text: 'Notsystem, g-klav och melodi',
				category: 'note',
				sections: [
					{ text: 'Tonernas namn', type: 'section' },
					{
						text: 'Notsystem och g-klav',
						type: 'article',
						id: 'dt88',
						key: 'note_system__dt88',
					},
					{
						text: 'Tonnamn',
						type: 'collection',
						id: 'notenames',
						key: 'note_diatonic_piano_play__introduction',
					},
					{
						text: 'Tonnamn på piano',
						type: 'exercise',
						id: 'AgT3AGv9zzzPXDTFPlkJ',
						key: 'note_diatonic_piano_play__lvl1',
						showHistory: 1,
					},
					{ text: 'G-klav', type: 'section' },
					{
						text: 'Noter i g-klav',
						type: 'exercise',
						id: 'dxOlF7zs0invZaCvn4cU',
						key: 'note_diatonic_gclef_write__lvl1',
						showHistory: 1,
					},
					{
						text: 'Tonnamn i g-klav',
						type: 'exercise',
						id: 'AfFbVUeWcs31g5o7keGe',
						key: 'note_diatonic_gclef_read__lvl1',
						showHistory: 1,
					},
					{
						text: 'G-klav på piano',
						type: 'exercise',
						id: 't3kQhaR2XQZXsdnuYK19',
						key: 'note_diatonic_gclef_play__lvl1',
						showHistory: 1,
					},
					{ text: 'C-dur, ton 1-2', type: 'section', id: '', key: '' },
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt90a',
						key: 'melody_key_major_c_tunes_sing_dt90',
					},
					{
						text: 'Skriva (i boken) ',
						type: 'article',
						id: 'dt90m',
						key: 'melody_key_major_c_tunes_write_dt90',
					},
					{ text: 'C-dur, ton 1-3 ', type: 'section', id: '', key: '' },
					{
						text: 'Spela (introduktion)',
						type: 'collection',
						id: 'melodyPlayIntroduction',
						key: 'melody_key_major_c_tunes_13_play__introduction',
					},
					{
						text: 'Spela melodier',
						type: 'exercise',
						id: 'gdnEvJKtqxYxrKtYoDm4',
						key: 'melody_key_major_c_tunes_13_play__lvl1',
					},
					{
						text: 'Om speluppgifter',
						type: 'article',
						id: 'progressionMelody',
						key: 'misc_aboutProgressionExercises',
						activateProgression: 'progressionMelody13',
					},
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt91a',
						key: 'melody_key_major_c_tunes_sing_dt91',
					},
					{
						text: 'Skriva (i boken) ',
						type: 'article',
						id: 'dt91m',
						key: 'melody_key_major_c_tunes_write_dt91',
					},
				],
			},
			melodyOctaveGclef: {
				text: 'Oktaver och g-klav',
				category: 'note',
				sections: [
					{ text: 'Oktaver', type: 'section' },
					{
						text: 'Oktaver, introduktion',
						type: 'collection',
						id: 'noteoctaves1',
						key: 'note_octaves_common_piano_play__introduction',
					},
					{
						text: 'Tvåstrukna oktaven på piano',
						type: 'exercise',
						id: 'JKNnZtdlOiJNsUsbvVNi',
						key: 'note_octaves_common_piano_play__lvl1',
						showHistory: 1,
					},
					{
						text: 'Oktaver, fortsättning',
						type: 'collection',
						id: 'noteoctaves2',
						key: 'note_octaves_common_piano_play__introduction2',
					},
					{
						text: 'Oktaver på piano',
						type: 'exercise',
						id: 'vNRfKIAIK35miPiIxvMs',
						key: 'note_octaves_common_piano_play__lvl2',
						showHistory: 1,
						activateProgression: 'progressionNoteOctaveG:RnWSUDocw0jau2Sano95',
					},
					{ text: 'G-klav och oktaver', type: 'section' },
					{
						text: 'Ettstrukna oktaven i g-klav',
						type: 'exercise',
						id: '6oRx4aj2zmr41dyKCyFh',
						key: 'note_octaves_common_gclef_read__lvl1',
						showHistory: 1,
						activateProgression: 'progressionNoteOctaveG:qBRX87zGowO2lt2QVhoA',
					},
					{
						text: 'En oktav till i g-klav',
						type: 'collection',
						id: 'noteoctaves3',
						key: 'note_octaves_common_piano_play__introduction3',
						showHistory: 1,
					},
					{
						text: 'Tvåstrukna oktaven i g-klav (skriva)',
						type: 'exercise',
						id: 'wtQjat0Nj0xpTjnPJOkY',
						key: 'note_octaves_common_gclef_write__lvl2',
						showHistory: 1,
					},
					{
						text: 'Tvåstrukna oktaven i g-klav',
						type: 'exercise',
						id: 'RVb9UR23Y2Ewk7Fx9AS6',
						key: 'note_octaves_common_gclef_read__lvl2',
						showHistory: 1,
					},
					{
						text: 'Under ettstrukna oktaven',
						type: 'collection',
						id: 'noteoctaves4',
						key: 'note_octaves_common_piano_play__introduction4',
					},
					{
						text: 'Lilla oktaven i g-klav (skriva)',
						type: 'exercise',
						id: '1nNZQ7vKq5ujDVohAgxI',
						key: 'note_octaves_common_gclef_write__lvl3',
						showHistory: 1,
					},
					{
						text: 'Lilla oktaven i g-klav',
						type: 'exercise',
						id: '62oVr2QLSxkrOzEcSbfq',
						key: 'note_octaves_common_gclef_read__lvl3',
						showHistory: 1,
					},
					{
						text: 'Oktaver i g-klav (skriva)',
						type: 'exercise',
						id: 'j5e7C0RZGuKdQ182uTIy',
						key: 'note_octaves_common_gclef_write__lvl4',
						showHistory: 1,
						activateProgression: 'progressionNoteOctaveG:v31eUZMhHW1m0PhUzgPs',
					},
					{
						text: 'Oktaver i g-klav',
						type: 'exercise',
						id: '7t3Ux2BBgLyrU7joBk95',
						key: 'note_octaves_common_gclef_read__lvl4',
						showHistory: 1,
						activateProgression: 'progressionNoteOctaveG:B93cyKtKkEbIdQQqaH1y',
					},
					{
						text: 'G-klav på piano',
						type: 'exercise',
						id: 'Fa4HIdULSP1tSuVIxUT0',
						key: 'note_octaves_common_gclef_read__lvl5',
						showHistory: 1,
						activateProgression: 'progressionNoteOctaveG:LewNZZGkM9s16pPNVzmR',
					},
				],
			},
			melodyOctaveFclef: {
				text: 'Oktaver och f-klav',
				category: 'note',
				sections: [
					{ text: 'Lilla oktaven', type: 'section' },
					{
						text: 'F-klaven',
						type: 'exercise',
						id: 'yBCfkABLf2oprFWWEG9Q',
						key: 'note_octaves_common_fclef__introduction',
					},
					{
						text: 'F-klav och lilla oktaven (skriva)',
						type: 'exercise',
						id: 'Pfj4PHQ6w2xzMJiz8lZr',
						key: 'note_octaves_common_fclef_write__lvl1',
						showHistory: 1,
					},
					{
						text: 'F-klav och lilla oktaven',
						type: 'exercise',
						id: '1xlBFaIa3VuvLCBg5IpU',
						key: 'note_octaves_common_fclef_read__lvl1',
						showHistory: 1,
						activateProgression: 'progressionNoteOctaveF:tuU6kvDPstdfGmMqjq64',
					},
					{
						text: 'F-klav på piano',
						type: 'exercise',
						id: 'l3TlxL7fXbsM2cKo62Sm',
						key: 'note_octaves_common_fclef_play_piano__lvl1',
						showHistory: 1,
					},
					{ text: 'Stora oktaven', type: 'section' },
					{
						text: 'Introduktion',
						type: 'collection',
						id: 'noteoctaves5',
						key: 'note_octaves_common_fclef__introduction2',
					},
					{
						text: 'Toner stora oktaven (skriva)',
						type: 'exercise',
						id: 'z1vn3L0SUyhl0MJU4j30',
						key: 'note_octaves_common_fclef_write__lvl2',
						showHistory: 1,
					},
					{
						text: 'Toner stora oktaven',
						type: 'exercise',
						id: '5yp3cd5cNcf4apwTiYZ5',
						key: 'note_octaves_common_fclef_read__lvl2',
						showHistory: 1,
					},
					{ text: 'Ettstrukna oktaven', type: 'section' },
					{
						text: 'Introduktion',
						type: 'collection',
						id: 'noteoctaves6',
						key: 'note_octaves_common_fclef__introduction3',
					},
					{
						text: 'Toner ettstrukna oktaven (skriva)',
						type: 'exercise',
						id: 'wropoGzH32RticcjhhJa',
						key: 'note_octaves_common_fclef_write__lvl3',
						showHistory: 1,
					},
					{
						text: 'Toner ettstrukna oktaven',
						type: 'exercise',
						id: 'eD6xpgl8L0yuUqNBhLFq',
						key: 'note_octaves_common_fclef_read__lvl3',
						showHistory: 1,
					},
					{ text: 'Slutuppgifter', type: 'section' },
					{
						text: 'I noter (skriva)',
						type: 'exercise',
						id: 'wT3jZMBqXSovBfWYcOQr',
						key: 'note_octaves_common_fclef_write__lvl4',
						showHistory: 1,
						activateProgression: 'progressionNoteOctaveF:RYwazCKxOpwslbLclALG',
					},
					{
						text: 'I noter',
						type: 'exercise',
						id: 'NV8IiO5IoGEGO2X7dekg',
						key: 'note_octaves_common_fclef_read__lvl4',
						showHistory: 1,
						activateProgression: 'progressionNoteOctaveF:ZgXAZkTASVcbANR3Py4C',
					},
					{
						text: 'På piano',
						type: 'exercise',
						id: 'OrovxrSpOns8GDUBkEr3',
						key: 'note_octaves_common_fclef_play_piano__lvl2',
						showHistory: 1,
						activateProgression: 'progressionNoteOctaveF:4WKcbM2pQDJuHZXGJtiE',
					},
					{
						text: 'Skrivuppgifter',
						type: 'article',
						id: 'dt93',
						key: 'note_octaves_common_write_dt93',
						activateProgression: 'progressionMelody14',
					},
				],
			},
			melodyA2: {
				text: 'C-dur, ton 4 och 5',
				category: 'key',
				sections: [
					{ text: 'C-dur, ton 1-4', type: 'section', id: '', key: '' },
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt94',
						key: 'melody_key_major_c_tunes_sing_dt94',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt94m',
						key: 'melody_key_major_c_tunes_write_dt94',
					},
					{ text: 'C-dur, ton 1-5', type: 'section', id: '', key: '' },
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt95',
						key: 'melody_key_major_c_tunes_sing_dt95',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt95m',
						key: 'melody_key_major_c_tunes_write_dt95',
					},
				],
			},
			melodyB: {
				text: 'Förtecken',
				category: 'note',
				sections: [
					{ text: 'Förtecken', type: 'section' },
					{
						text: 'På piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'VZTQVEE648OchmxsGAr1',
						key: 'note_accidentials_piano_common_play',
						activateProgression:
							'progressionNoteAccidentials:pju9w5OWXJpYnwUAOM7r',
					},
					{
						text: 'G-klav',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'cZA5GDiPxfsufYYchNly',
						key: 'note_accidentials_common_gclef_read',
						activateProgression:
							'progressionNoteAccidentials:kZIGPFwyziXs9vIZz2DY',
					},
					{
						text: 'G-klav (skriva)',
						showHistory: 1,
						type: 'exercise',
						id: 'gu2JTGQTawOtn0S5LBLl',
						key: 'note_accidentials_common_gclef_write',
						activateProgression:
							'progressionNoteAccidentials:cM0FlJ7M69vO8kJGqAKp',
					},
					{
						text: 'G-klav på piano',
						type: 'exercise',
						id: 'UPkQ7rn9JVuzratX7Kno',
						key: 'note_accidentials_common_gclef_play_piano__lvl1',
						activateProgression:
							'progressionNoteAccidentials:D9fQqjwIsAaCEIKQfulr',
						showHistory: 1,
					},
					{
						text: 'F-klav',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'QeKuEOH2OxsggzG9HlR7',
						key: 'note_accidentials_common_fclef_read',
						activateProgression:
							'progressionNoteAccidentials:W6KC7jqYgY2EWuleRQd9',
					},
					{
						text: 'F-klav (skriva)',
						showHistory: 1,
						type: 'exercise',
						id: 'r3VB6xwSyKgXtTDPlz9R',
						key: 'note_accidentials_common_fclef_write',
						activateProgression:
							'progressionNoteAccidentials:b9vPRy8OkmBrss7AADg0',
					},
					{
						text: 'F-klav på piano',
						type: 'exercise',
						id: 'RhuPHVbCDa0ubAdcoS79',
						key: 'note_accidentials_common_fclef_play_piano__lvl1',
						activateProgression:
							'progressionNoteAccidentials:x8lhDjxtX9JNQNRIWYk7',
						showHistory: 1,
					},
					{
						text: 'Skriva toner med förtecken (96-97)',
						type: 'article',
						id: 'dt96-97',
						key: 'note_accidentials_common_paper',
					}, // berätta vad man ska göra, markdown
					//{text: 'Förtecken och ditt instrument', type: '', id: ''},
				],
			},
			major: {
				text: 'Durtonart, skalor, tillfälliga förtecken',
				category: 'key',
				sections: [
					{ text: 'Durtonarter', type: 'section' },
					{
						text: 'Tonsteg',
						type: 'article',
						id: 'major1a',
						key: 'note_accidentials_notesteps_paper',
					},
					{
						text: 'Durskalans uppbyggnad',
						type: 'article',
						id: 'major1b',
						key: 'scale_major_paper_read',
					},
					{
						text: 'Skapa durskalor',
						type: 'article',
						id: 'major1c',
						key: 'scale_major_paper_write',
					},
					{
						text: 'Ramsor',
						type: 'article',
						id: 'major1d',
						key: 'key_major_rhymes_paper',
					},
					{
						text: 'Vilken durtonart?',
						type: 'exercise',
						id: '2G9H9V440pNmNQjASlHu',
						showHistory: 1,
						key: 'key_major_rhymes__6lvl1',
					},
					{
						text: 'Mer durtonarter',
						type: 'exercise',
						id: 'awLbszq20S8yPSOtpb7t',
						showHistory: 1,
						key: 'key_major_score__6lvl1',
					},
					//{text: 'Identifiera', type: 'exercise', id: '', key: 'key_major_key_identifiy_paper'},
					{
						text: 'Fasta förteckens placering',
						type: 'article',
						id: 'major1e',
						key: 'key_sign_all_paper',
					},
					{
						text: 'Skriva fasta korsförtecken',
						type: 'exercise',
						id: 'UKYcJCyn5Yiili0vIeGQ',
						showHistory: 1,
						key: 'key_major_sharps_write__lvl1',
					},
					{
						text: 'Skriva fasta b-förtecken',
						type: 'exercise',
						id: 'wSRbtj1UY3HgCfczeWwB',
						showHistory: 1,
						key: 'key_major_flats_write__lvl1',
					},
					{
						text: 'Durtonarters förtecken',
						type: 'article',
						id: 'major1f',
						key: 'key_major_key_write_paper',
					},
					{
						text: 'Vad för förtecken?',
						type: 'exercise',
						id: 'GhjOmAZHBA9tWy0fYhGO',
						showHistory: 1,
						activateProgression: 'progressionKeys:Tc2r3tmfqK5sYtAYDKVU',
						key: 'key_major_signs__6lvl1',
					},
					{
						text: 'Skriva fasta förtecken',
						type: 'exercise',
						id: 'g9VOLxZTiivxABqSFAiV',
						showHistory: 1,
						key: 'key_major_signs_write__lvl1',
					},
					{ text: 'Melodier', type: 'section' },
					{
						text: '104 (d, e, f)',
						type: 'article',
						id: 'dt104a',
						key: 'melody_key_major_tunes_sing_dt104',
					},
					{
						text: '104 (melodi)',
						type: 'article',
						id: 'dt104m',
						key: 'melody_key_major_tunes_write_dt104',
					},
					{ text: 'Tillfälliga förtecken', type: 'section' },
					{
						text: 'Tillfälliga förtecken',
						type: 'article',
						id: 'accidentials1',
						key: 'melody_key_accidentials_paper',
					},
				],
			},
			rhythm8plain: {
				text: 'Åttondelar, grundfigurer',
				category: 'rhythm',
				sections: [
					{
						text: 'Åttondelsnoter',
						type: 'article',
						id: 'rhythm8intro',
						key: 'rhythm_eights_plain_math_dt19',
					},
					{
						text: 'Beskriv notbilden',
						type: 'article',
						id: 'rhythm8plainExplain',
						key: 'rhythm_eights_plain_24_explain__lvl1',
					},
					{ text: 'Grundfigur 1-4', type: 'section' },
					{
						text: 'Läsa',
						type: 'article',
						id: 'rhythm_eights_plain_24_read__lvl2',
						key: 'rhythm_eights_plain_24_read__lvl2',
					},
					{
						text: 'Spela (två takter)',
						showHistory: 1,
						type: 'exercise',
						id: 'AsbyzsNXYpnBpy0sMYLT',
						key: 'rhythm_eights_plain_24_play__lvl2',
					},
					{
						text: 'Spela (fyra takter)',
						showHistory: 1,
						type: 'exercise',
						id: 'C9M9AXsc3XV3yKrCBz6M',
						key: 'rhythm_eights_plain_24_play__lvl2-4',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: 'yjYQYyRdp6ymL69u4ccT',
						key: 'rhythm_eights_plain_24_write__lvl2',
					},
					{ text: 'Grundfigur 5-6', type: 'section' },
					{
						text: 'Läsa',
						type: 'article',
						id: 'rhythm_eights_plain_24_read__lvl3',
						key: 'rhythm_eights_plain_24_read__lvl3',
					},
					{
						text: 'Spela (två takter)',
						showHistory: 1,
						type: 'exercise',
						id: 'LeLakKhQ29h6jGrFOFUQ',
						key: 'rhythm_eights_plain_24_play__lvl3',
					},
					{
						text: 'Spela (fyra takter)',
						showHistory: 1,
						type: 'exercise',
						id: 'hobM2JV5bqrcL1Iar4N5',
						key: 'rhythm_eights_plain_24_play__lvl3',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: 'xJZb7OVWkFgRVbjli7Fo',
						key: 'rhythm_eights_plain_24_write__lvl3',
					},
					{ text: 'Grundfigur 7-8', type: 'section' },
					{
						text: 'Läsa',
						type: 'article',
						id: 'rhythm_eights_plain_24_read__lvl4',
						key: 'rhythm_eights_plain_24_read__lvl4',
					},
					{
						text: 'Spela (två takter)',
						showHistory: 1,
						type: 'exercise',
						id: 'leAVVOvSmQCAbDJKfHap',
						key: 'rhythm_eights_plain_24_play__lvl4',
					},
					{
						text: 'Spela (fyra takter)',
						showHistory: 1,
						type: 'exercise',
						id: 'zzvlrvKIXDkDdaPFOf3J',
						key: 'rhythm_eights_plain_24_play__lvl4',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: 'I1mZRL3q6H6razdp5ywF',
						key: 'rhythm_eights_plain_24_write__lvl4',
					},
					{ text: 'Slutuppgifter', type: 'section' },
					{
						text: 'Spela',
						showHistory: 1,
						type: 'exercise',
						id: 'lYpkieUJKWTfrXYoNvDU',
						key: 'rhythm_eights_plain_24_play__lvl5',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: '4Jr4hva9VDnhrNlPJxsO',
						key: 'rhythm_eights_plain_24_write__lvl5',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt21m',
						key: 'rhythm_eights_plain_24_write__dt21',
					},
				],
			},
			rhythm8ties: {
				text: 'Åttondelar, bindebågar',
				category: 'rhythm',
				sections: [
					{ text: '2/4 taktart', type: 'section' },
					{
						text: 'Läsa: Börja utan (1)',
						type: 'article',
						id: 'rhythm_eights_ties_24_read__lvl1',
						key: 'rhythm_eights_ties_24_read__lvl1',
					},
					{
						text: 'Läsa: Börja utan (2)',
						type: 'article',
						id: 'rhythm_eights_ties_24_read__lvl2',
						key: 'rhythm_eights_ties_24_read__lvl2',
					},
					{
						text: 'Läsa: Börja utan (3)',
						type: 'article',
						id: 'rhythm_eights_ties_24_read__lvl3',
						key: 'rhythm_eights_ties_24_read__lvl3',
					},
					{
						text: 'Spela (två takter)',
						showHistory: 1,
						type: 'exercise',
						id: 'cBDk7QgCZIwgb4xj5DaR',
						key: 'rhythm_eights_ties_24_play__lvl5',
					},
					{
						text: 'Spela (fyra takter)',
						showHistory: 1,
						type: 'exercise',
						id: '1jXCPCh1dWYhZPULeNZq',
						key: 'rhythm_eights_ties_24_play__lvl5-4',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: 'wBMk2lmds69FWX96N51a',
						key: 'rhythm_eights_ties_24_write__lvl5',
					},
					{ text: '4/4 taktart', type: 'section' },
					{
						text: 'Spela',
						showHistory: 1,
						type: 'exercise',
						id: 'YcXiNAIb8rRuAJipUzcE',
						key: 'rhythm_eights_ties_44_play__lvl5',
					},
					{
						text: 'Rita in slagen',
						type: 'article',
						id: 'rhythm_eights_beats_44_write__lvl1',
						key: 'rhythm_eights_beats_44_write__lvl1',
					},
					{
						text: 'Läsa',
						type: 'article',
						id: 'rhythm_eights_ties_44_read__lvl1',
						key: 'rhythm_eights_ties_44_read__lvl1',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: 'PEv7K4mgB1mS9TdH0MUH',
						key: 'rhythm_eights_ties_44_write__lvl5',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt23m',
						key: 'rhythm_eights_ties_44_write__dt23',
					},
				],
			},
			intervalA: {
				text: 'Intervall och durackordet',
				category: 'interval',
				sections: [
					{ text: 'Intervall', type: 'section' },
					{
						text: 'Vad är intervall?',
						type: 'article',
						id: 'dt148',
						key: 'interval_diatonic__dt148',
					},
					{
						text: 'Identifiera intervall',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'me4Uw0uYp96agD88S0mN',
						key: 'interval_diatonic_read_score_18__lvl1',
					},
					{
						text: 'Lyssna på intervall',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'JSMsRj1BY1Zq6UDlxMK6',
						key: 'interval_diatonic_listen_15__lvl1',
					},
					{ text: 'Ackord', type: 'section' },
					{
						text: 'Powerackord',
						type: 'article',
						id: 'dt149',
						key: 'chord_powerchord__dt149',
					},
					{
						text: 'C-durtreklangen',
						type: 'article',
						id: 'dt150',
						key: 'chord_majorMinor__dt150',
					},
					{
						text: 'Spela durackord på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'vmEqmoy8Uce7eGBrUh9e',
						key: 'chord_majorMinor_play_piano_chordSymbol__lvl1',
					},
					{
						text: 'Arpeggio',
						type: 'article',
						id: 'dt151',
						key: 'chord_majorMinor_sing__lvl1',
					},
				],
			},
			majorChordProgA: {
				text: 'C-durs tre vanligaste ackord',
				category: 'chordProg',
				sections: [
					{ text: 'C-dur: steg I och V', type: 'section' },
					{
						text: 'C-durs femte steg',
						type: 'article',
						id: 'dt152',
						key: 'harmony_progression_15__dt152',
					},
					//{text: 'Ackordläggning', type: '', id: '', key: ''},
					{
						text: 'Sjunga basstämman',
						type: 'article',
						id: 'dt153a',
						key: 'harmony_progression_15_sing__dt153a',
					},
					{
						text: 'Lyssna vilka ackord',
						type: 'article',
						id: 'dt153m',
						key: 'harmony_progression_15_write__dt153m',
					},
					{
						text: 'Basen',
						type: 'article',
						id: 'dt154',
						key: 'harmony_progression_15__dt154',
					},
					{
						text: 'Lyssna vilka ackord (2)',
						type: 'article',
						id: 'dt155m',
						key: 'harmony_progression_15_write__dt153m',
					},
					{
						text: 'Harmonisera Spanien',
						type: 'exercise',
						id: 'WUENPGWEzIYCsYxxJX0Q',
						key: 'harmony_progression_15_write__dt161',
					},
					{ text: 'Huvudfunktionerna i C-dur', type: 'section' },
					{
						text: 'C-durs fjärde steg',
						type: 'article',
						id: 'dt160',
						key: 'harmony_progression_145__dt160',
					},
					//{text: 'Ackordläggning', type: '', id: '', key: ''},
					{
						text: 'Sjunga basstämman',
						type: 'article',
						id: 'dt161a',
						key: 'harmony_progression_145_sing__dt161a',
					},
					{
						text: 'Lyssna på basen: IV eller V?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '8dFJFQpNIvsey93XaWgB',
						key: 'harmony_progression_145_listen_base__lvl1',
					},
					{
						text: 'Lyssna på ackord: IV eller V?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'a8bNHreiExa6RDp3F6O2',
						key: 'harmony_progression_145_listen__lvl1',
					},
					{
						text: 'Lyssna på basen: I, IV och V',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'C4DBGElsPtwUoWXTIC9g',
						key: 'harmony_progression_145_listen_base__lvl2',
					},
					{
						text: 'Lyssna på ackord: I, IV och V',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'f79wxfaXzrLuOd99B5CB',
						key: 'harmony_progression_145_listen__lvl2',
					},
					{
						text: 'Skriva I, IV och V',
						type: 'article',
						id: 'dt161m',
						key: 'harmony_progression_145__dt161',
					},
					{
						text: 'Harmonisera  Pippi',
						type: 'exercise',
						id: 'ajt4sh3eAITFxDDWkaRQ',
						key: 'harmony_progression_145_write__dt161',
					},
					{ text: 'Bas: ned från grundtonen', type: 'section' },
					{
						text: 'Basens riktning',
						type: 'article',
						id: 'upDownBass',
						key: 'harmony_progression_major_145__upDownBass',
					},
					{
						text: 'Lyssna på basen: IV eller V?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'JOF2r5yY0LmcaxhRDWIH',
						key: 'harmony_progression_145_listen_base__lvl3',
					},
					{
						text: 'Lyssna på ackord: IV eller V?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'ofEgvvMGL8v2TEErP4Sb',
						key: 'harmony_progression_145_listen__lvl3',
					},
					{
						text: 'Lyssna på basen: I, IV och V',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '6wu2sIwa8Co4XHwMx9VD',
						key: 'harmony_progression_145_listen_base__lvl4',
					},
					{
						text: 'Lyssna på ackord: I, IV och V',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'S9gKNkt9cInoycox9qBc',
						key: 'harmony_progression_145_listen__lvl4',
					},
					{ text: 'Stämföring', type: 'section' },
					{
						text: 'Ackordbakgrund',
						type: 'article',
						id: 'dt162',
						key: 'harmony_progression_major_145_voiceLeading__dt162',
					},
					// få in harmonisering
				],
			},
			melodyC: {
				text: 'Melodier i olika durtonarter',
				category: 'key',
				sections: [
					{ text: 'Durtonarter, repetition', type: 'section' },
					{
						text: 'Vilka förtecken?',
						type: 'exercise',
						id: 'fJXk6iUPkNXS1QeCwFju',
						key: 'key_major_signs_6lvl2',
						showHistory: 1,
					},
					{
						text: 'Vilken tonart?',
						type: 'exercise',
						id: 'OJon08bQjF2FHyBwbObJ',
						key: 'key_major_rhymes_6lvl2',
						showHistory: 1,
					},
					{
						text: 'Vilken durskala?',
						type: 'exercise',
						id: 'sDw5qiOS5gYPvAikwuWX',
						key: 'scale_major_score__3lvl1',
						showHistory: 1,
					},
					{ text: 'Melodier', type: 'section' },
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt105a',
						key: 'melody_key_major_tunes_identify_dt105',
					},
					{
						text: 'Sjunga efter noter',
						type: 'article',
						id: 'dt105b',
						key: 'melody_key_major_tunes_sing_dt105',
					},
					{
						text: 'Repristecken',
						type: 'article',
						id: 'dt80a',
						key: 'form_repeats_repeatMarks__dt80',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt105m',
						key: 'melody_key_major_tunes_write_dt105',
					},
					{ text: 'Rättskrivning', type: 'section' },
					{
						text: 'Notskaft, balkar och flaggor',
						isHomework: true,
						type: 'article',
						id: 'dt106',
						key: 'melody_notation_stemsandbeams__dt106',
					},
				],
			},
			rhythm8plain34: {
				text: '3/4 taktart',
				category: 'rhythm',
				sections: [
					{
						text: 'Om taktarten 3/4',
						type: 'article',
						id: 'dt30',
						key: 'rhythm_eights_plain_34_intro__dt30',
					},
					{
						text: 'Beskriv notbilden',
						type: 'article',
						id: 'rhythm8ties34Explain',
						key: 'rhythm_eights_ties_34_explain__lvl1',
					},
					{
						text: 'Spela',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'fxX6pSOvuPGTl1U9JQXD',
						key: 'rhythm_eights_plain_34_play__lvl1',
					},
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt31a',
						key: 'rhythm_eights_plain_34_read__dt31',
					},
					//{text: 'Skriva', type: 'exercise', id: '', key: ''},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt31m',
						key: 'rhythm_eights_plain_34_write__dt31',
					},
				],
			},
			minorA: {
				text: 'Molltonart och parallelltonart',
				category: 'key',
				sections: [
					{ text: 'C-moll', type: 'section' },
					{
						text: 'Introduktion',
						type: 'article',
						id: 'dt98',
						key: 'melody_key_minor_introduction_about__dt98',
					},
					{
						text: 'Sjunga melodier',
						type: 'article',
						id: 'dt99a',
						key: 'melody_key_minor_introduction_sing__dt99',
					},
					{
						text: 'Skriva melodi',
						type: 'article',
						id: 'dt99m',
						key: 'melody_key_minor_introudction_write__dt99',
					},
					{ text: 'Parallelltonarter', type: 'section' },
					{
						text: 'Introduktion',
						type: 'article',
						id: 'dt108',
						key: 'melody_key_relativeKey_fromMajor__dt108',
					},
					{
						text: 'Räkna ut parallelltonart',
						type: 'article',
						id: 'relativeKey',
						key: 'melody_key_relativeKey_steps',
					},
					{
						text: 'Parallelltonart (från dur)',
						showHistory: 1,
						type: 'exercise',
						activateProgression: 'progressionKeys:2rP1onXmy0JanFIISlJ2',
						id: 'Ux5OlK0shBVXuhdMWAGS',
						key: 'key_relativeKey_major__3lvl1',
					},
					{
						text: 'Parallelltonart (från moll)',
						showHistory: 1,
						activateProgression: 'progressionKeys:l22MlRnSGcOOC5fdS5Me',
						type: 'exercise',
						id: 'wvPEtwI25dg877FPBYgD',
						key: 'key_relativeKey_minor__3lvl1',
					},
					{
						text: 'Vilka förtecken?',
						type: 'exercise',
						activateProgression: 'progressionKeys:F2gMzdfBogq3lEw9yftY',
						id: '0cVOD5Co96KNRtO2juXT',
						key: 'key_relativeKey_rhymes__3lvl1',
					},
					{ text: 'Melodier i olika molltonarter', type: 'section' },
					{
						text: 'Vilken dur eller moll?',
						type: 'exercise',
						id: 'pNLu5BN3GiLtCRLvkEBZ',
						key: 'key_majorminor_score__3lvl1',
					},
					{
						text: 'Vilken tonart?',
						type: 'article',
						id: 'relativeKey_dt109',
						key: 'key_minor_identify',
					},
					{
						text: 'Sjunga efter noter',
						type: 'article',
						id: 'dt109a',
						key: 'melody_key_minor_tunes_sing__dt109',
					},
					{
						text: 'Skriva förtecken (moll)',
						type: 'exercise',
						activateProgression: 'progressionKeys:sGkfB6sQ50loR8Gm0xyu',
						id: 'auD44UjKqLTV4ovWTfUZ',
						key: 'key_minor_signs_write__lvl1',
						showHistory: 1,
					},
					{
						text: 'Skriva ned en melodi',
						type: 'article',
						activateProgression: 'progressionKeys:mvJiRym02fmEeg3q7Bbp',
						id: 'dt109m',
						key: 'melody_key_minor_tunes_write__dt109',
					},
				],
			},
			minorChordsA: {
				text: 'Dur- och moll: Skalor och ackord',
				category: 'kechordy',
				sections: [
					{ text: 'Skalor', type: 'section' },
					{
						text: 'Dur- eller mollskala?',
						type: 'exercise',
						id: 'KdHXRJNsQ6KI7uJ17HgH',
						key: 'scale_majorminor_score__3lvl1',
						showHistory: 1,
					},
					{
						text: 'Skriva skalor',
						type: 'article',
						id: 'b19',
						key: 'scale_majorminor_write__b19',
					},
					{
						text: 'Lyssna på skalor',
						type: 'exercise',
						id: 'qsD24PN7SN5TVtLAkwtx',
						key: 'scale_majorminor_listen__lvl1',
						showHistory: 1,
					},
					{ text: 'Ackord', type: 'section' },
					{
						text: 'C-molltreklangen',
						type: 'article',
						id: 'dt156',
						key: 'chord_majorMinor__dt156',
					},
					{
						text: 'Sjunga C-molltreklang',
						type: 'article',
						id: 'dt157',
						key: 'chord_majorMinor_sing__lvl2',
					},
					{
						text: 'Spela mollackord på piano',
						type: 'exercise',
						id: 'dTsMfbJkGfHJFuf9Iouu',
						key: 'chord_majorMinor_play_piano_chordSymbol__lvl2',
					},
					{
						text: 'Spela dur- och mollackord på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'mdwcVmGyGgqwfywb0PZb',
						key: 'chord_majorMinor_play_piano_chordSymbol__lvl3',
					},
					{
						text: 'Identifiera dur- och mollackord på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'dPCVl1BtoPDZPlLD166y',
						key: 'chord_majorMinor_identify_piano__lvl1',
					},
					{
						text: 'Skriva ackord',
						type: 'article',
						id: 'b32',
						key: 'chord_majorMinor_write__lvl1',
					},
					{
						text: 'Identifiera ackord från noter',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'XxLkmEnY97EOx27gxgZl',
						key: 'chord_majorMinor_identify_sheetmusics__lvl1',
					},
					//{text: 'Toner i ackord', type: 'exercise', id: 'EDKJA2TMxlgi9kcIQTI1', key: 'chord_majorMinor_write_buttons__lvl1'},
					{
						text: 'Lyssna: Brutet/arpeggio',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'm3FASsbZfxD3klsDKeqn',
						key: 'chord_majorMinor_listen_arpeggio__lvl1',
					},
					{
						text: 'Lyssna: Samklang',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'iyA57zs06BCqzGVSyprA',
						key: 'chord_majorMinor_listen_chord__lvl1',
					},
				],
			},
			rhythm8rests: {
				text: 'Åttondelar, paus',
				category: 'rhythm',
				sections: [
					{
						text: 'Åttondelspaus',
						type: 'article',
						id: 'dt24',
						key: 'rhythm_eights_rests_intro__dt24',
					},
					{
						text: 'Beskriv notbilden',
						type: 'article',
						id: 'rhythm8rests44Explain',
						key: 'rhythm_eights_rests_44_explain__lvl1',
					},
					{ text: '2/4 taktart', type: 'section' },
					{
						text: 'Läsa: börja utan',
						type: 'article',
						id: 'dt25a',
						key: 'rhythm_eights_rests_24_read__lvl1',
					},
					{
						text: 'Spela',
						showHistory: 1,
						type: 'exercise',
						id: 'mNC5VfMsKARKxGwnxzcc',
						key: 'rhythm_eights_rests_24_play__lvl1',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: 'UzJdB6h1gmjBkbCM6SaL',
						key: 'rhythm_eights_rests_24_write__lvl1',
					},
					{ text: '4/4 taktart', type: 'section' },
					{
						text: 'Rita in slagen',
						type: 'article',
						id: 'rhythm_eights_beats_44_write__lvl2',
						key: 'rhythm_eights_beats_44_write__lvl2',
					},
					{
						text: 'Läsa: bindebågar och pauser',
						type: 'article',
						id: 'dt25b',
						key: 'rhythm_eights_rests_44_read__lvl1',
					},
					{
						text: 'Spela',
						showHistory: 1,
						type: 'exercise',
						id: 'R4dJi4MtUzasVwTheGIY',
						key: 'rhythm_eights_rests_44_play__lvl1',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: 'O4YUD1u7NZ2viY3RiTrt',
						key: 'rhythm_eights_rests_44_play__lvl1',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt25m',
						key: 'rhythm_eights_rests_44_write__dt25',
					},
					{ text: 'Rättskrivning', type: 'section' },
					{
						text: 'Balkning och mellanrum',
						isHomework: true,
						type: 'article',
						id: 'dt26',
						key: 'rhythm_notation_eights_beamsSpacing__lvl1',
					},
				],
			},
			interval2: {
				text: 'Intervall: stora och rena',
				category: 'interval',
				sections: [
					{
						text: 'Två familjer',
						type: 'article',
						id: 'interval_diatonic_types',
						key: 'interval_diatonic_types',
					},
					{
						text: 'Identifiera intervall',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'LbSCF6PhLO7T9STmI87c',
						key: 'interval_diatonic_read_score_18__lvl2',
					},
					{
						text: 'Identifiera intervall (papper)',
						type: 'article',
						id: 'interval_diatonic_read_score_18__paper',
						key: 'interval_diatonic_read_score_18__paper',
					},
					{
						text: 'Hur låter intervallen?',
						type: 'article',
						id: 'interval_diatonic_soundsLike',
						key: 'interval_diatonic_soundsLike',
					},
					//{text: 'Hjälpmelodier', type: '', id: 'article', key: 'interval_diatonic_helpMelodies'},
					{
						text: 'Lyssna på intervall 1-5',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'POFu4Cm40i7Kir7TpGyF',
						key: 'interval_diatonic_listen_15__lvl2',
						activateProgression: 'progressionIntervalNatural1',
					},
					//{text: 'Lyssna på intervall 5-8', type: 'exercise', id: '', key: 'interval_diatonic_listen_58__lvl2'},
					{
						text: 'Skriva intervall',
						type: 'article',
						id: 'interval_diatonic_write_score_18__lvl2',
						key: 'interval_diatonic_write_score_18__lvl2',
					},
				],
			},
			transpose1: {
				text: 'Transponering',
				category: 'key',
				sections: [
					{
						text: 'Transponera en melodi',
						type: 'article',
						id: 'melody_transpose_diatonic__lvl1',
						key: 'melody_transpose_diatonic__lvl1',
					},
					/*{
            text: 'Om man glömmer något...',
            type: 'article',
            id: 'melody_transpose_diatonic__lvl2',
            key: 'melody_transpose_diatonic__lvl2',
          },
          {
            text: 'Transponerande instrument',
            type: 'article',
            id: 'melody_transpose_transponsingInstruments__lvl1',
            key: 'melody_transpose_transponsingInstruments__lvl1',
          },*/
				],
			},
			swing1: {
				text: 'Swing',
				category: 'rhythm',
				sections: [
					{
						text: 'Om swing och triolindelning',
						type: 'article',
						id: 'dt32',
						key: 'rhythm_eights_swing_feel__dt32',
					},
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt33a',
						key: 'rhythm_eights_swing_read__dt33',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt33m',
						key: 'rhythm_eights_swing_write__dt33',
					},
				],
			},
			misc1: {
				text: 'Form och musikord',
				category: 'other',
				sections: [
					{
						text: 'Musikord',
						type: 'article',
						id: 'words1',
						key: 'symbols_words',
					},
					{ text: 'Form', type: 'article', id: 'form1', key: 'form_repeats' },
				],
			},
			/*'review1': {
              text: 'Repetition',
              sections: [
                {text: 'Ton och oktav', type: 'section'},
                {text: 'todo', type: '', id: '', key: ''},
                {text: 'Interval', type: 'section'},
                {text: 'Tonart och förtecken', type: 'section'},
                {text: 'Skalor', type: 'section'},
                {text: 'Ackord', type: 'section'},
                {text: 'Musikord', type: 'section'},
              ]
            },*/

			review1post: {
				text: 'Repetition', // uppgifter som motsvarar första kursen, används för repeititon i senare kurser
				hidden: true,
				category: 'review',
				sections: [
					{ text: 'Ton och oktav, stamtoner', type: 'section' },
					{
						text: 'Ton och oktav på piano (stamtoner)',
						showHistory: 1,
						type: 'exercise',
						id: '3UiFrHXaZqZHMfLjTD0E',
						key: 'note_octaves_common_piano_play__review1post',
					},
					{
						text: 'G-klav och oktaver (stamtoner)',
						showHistory: 1,
						type: 'exercise',
						id: '3Pq6n7ZjcfdfcYWuiGKC',
						key: 'note_octaves_common_gclef_read__review1post',
					},
					{
						text: 'G-klav och oktaver (stamtoner, skriva)',
						showHistory: 1,
						type: 'exercise',
						id: '01QscXstQ2OpXck90gMt',
						key: 'note_octaves_common_gclef_write__review1post',
					},
					{
						text: 'G-klav på piano (stamtoner)',
						showHistory: 1,
						type: 'exercise',
						id: 'ACX0ynrk8FGCAmQMi3aB',
						key: 'note_octaves_common_gclef_play_plano__review1post',
					},
					{
						text: 'F-klav och oktaver (stamtoner)',
						showHistory: 1,
						type: 'exercise',
						id: 'RDJgInLhxN7GdGAjPIhR',
						key: 'note_octaves_common_fclef_read__review1post',
					},
					{
						text: 'F-klav och oktaver (stamtoner, skriva)',
						showHistory: 1,
						type: 'exercise',
						id: 'nYxb6YSPHqL7PrSd5tzQ',
						key: 'note_octaves_common_fclef_write_review1post',
					},
					{
						text: 'F-klav på piano (stamtoner)',
						showHistory: 1,
						type: 'exercise',
						id: '4oP9yW9hMOVEwGHuM7JC',
						key: 'note_octaves_common_fclef_play_plano__review1post',
					},
					{ text: 'Ton och oktav, förtecken', type: 'section' },
					{
						text: 'Ton och oktav på piano (vanliga)',
						showHistory: 1,
						type: 'exercise',
						id: 'BZQ09Ug1fUJutB50yHbY',
						key: 'note_accidentials_piano_common_play__review1post',
					},
					{
						text: 'Ton och oktav med förtecken, g-klav (vanliga)',
						showHistory: 1,
						type: 'exercise',
						id: 'JDzqmq9ZJOm5g3ODsKwy',
						key: 'note_accidentials_common_gclef_read__review1post',
					},
					{
						text: 'Ton och oktav med förtecken, g-klav (vanliga, skriva)',
						showHistory: 1,
						type: 'exercise',
						id: '2AwM8lYNeux1yokhQWPS',
						key: 'note_accidentials_common_gclef_write__review1post',
					},
					{
						text: 'G-klav med förtecken på piano (vanliga)',
						showHistory: 1,
						type: 'exercise',
						id: 'zWBMx6aJONrJiMZt9DVn',
						key: 'note_accidentials_common_gclef_play_piano__review1post',
					},
					{
						text: 'Ton och oktav med förtecken, f-klav (vanliga)',
						showHistory: 1,
						type: 'exercise',
						id: 'oeTjdwk3Nzn8FI4JYrO0',
						key: 'note_accidentials_common_fclef_read__review1post',
					},
					{
						text: 'Ton och oktav med förtecken, f-klav (vanliga, skriva)',
						showHistory: 1,
						type: 'exercise',
						id: 'ZSz5TLgdJCVMMenPQMTX',
						key: 'note_accidentials_common_fclef_write__review1post',
					},
					{
						text: 'F-klav med förtecken på piano (vanliga)',
						showHistory: 1,
						type: 'exercise',
						id: 'R6SlaavP6tahmdNa0Bdx',
						key: 'note_accidentials_common_fclef_play_piano__review1post',
					},
					{ text: 'Tonarter', type: 'section' },
					{
						text: 'Durtonarter',
						showHistory: 1,
						type: 'exercise',
						id: '4a7QScPYIj1S3vUd0iHs',
						key: 'key_major_rhymes__review1post',
					},
					{
						text: 'Durtonarter (fasta förtecken)',
						showHistory: 1,
						type: 'exercise',
						id: 'Kfw6jBADtZLSGBVwAYMi',
						key: 'key_major_score__review1post',
					},
					{
						text: 'Durtonarter (antal förtecken)',
						showHistory: 1,
						type: 'exercise',
						id: 'MeekECI97JNOQNaSLlhn',
						key: 'key_major_signs__review1post',
					},
					{
						text: 'Skriva förtecken (dur)',
						type: 'exercise',
						id: '2Wy24QaioLyTmg78zA8q',
						key: 'key_major_signs_write__review1post',
						showHistory: 1,
					},
					{
						text: 'Parallelltonart (dur till moll)',
						showHistory: 1,
						type: 'exercise',
						id: '7kiFzW9Pbad5Jq2RZCX0',
						key: 'key_relativeKey_major__review1post',
					},
					{
						text: 'Parallelltonart (moll till dur)',
						showHistory: 1,
						type: 'exercise',
						id: 's6qnN2KvmQYHoio3ZY67',
						key: 'key_relativeKey_minor__review1post',
					},
					{
						text: 'Parallelltonart',
						showHistory: 1,
						type: 'exercise',
						id: 'LJcRf9Lo6CZU1vmInahf',
						key: 'key_relativeKey_majorMinor__review1post',
					},
					{
						text: 'Molltonarter',
						showHistory: 1,
						type: 'exercise',
						id: 'Fj11kkQmKJPkFyoPKlrB',
						key: 'key_minor__review1post',
					},
					{
						text: 'Molltonarter (fasta förtecken)',
						showHistory: 1,
						type: 'exercise',
						id: 'VNjTHfZfWrSYmGMl2uso',
						key: 'key_minor_score__review1post',
					},
					{
						text: 'Molltonarter (antal förtecken)',
						showHistory: 1,
						type: 'exercise',
						id: 'QVUXj509pbCk6DXfHQzZ',
						key: 'key_minor_signs__review1post',
					},
					{
						text: 'Skriva förtecken (moll)',
						type: 'exercise',
						id: 'J2E9DT57ck4PZ4BD2nhX',
						key: 'key_minor_signs_write__review1post',
						showHistory: 1,
					},
					{
						text: 'Dur- och molltonart',
						showHistory: 1,
						type: 'exercise',
						id: 'fF5d76DDWXqNHXHLI4aN',
						key: 'key_majorminor_score__review1post',
					},
					{
						text: 'Dur och moll, antal förtecken',
						showHistory: 1,
						type: 'exercise',
						id: 'LnAb8bijlbBRircl9uXM',
						key: 'key_majorminor_signs__review1post',
					},
					{
						text: 'Dur och moll, skriva förtecken',
						type: 'exercise',
						id: '5GDXPtUZyxcXqC00fysJ',
						key: 'key_majorminor_signs_write__review1post',
						showHistory: 1,
					},
					{ text: 'Ackord', type: 'section' },
					{
						text: 'Spela dur-/mollackord på piano',
						showHistory: 1,
						type: 'exercise',
						id: 'BPkgHVPl7HgHZpZzzBxA',
						key: 'chord_majorMinor_play_piano_chordSymbol__review1post',
					},

					{
						text: 'Identifiera dur- och mollackord på piano',
						showHistory: 1,
						type: 'exercise',
						id: 'sQPBzWA9sppAa2hjeWy0',
						key: 'chord_majorMinor_identify_piano__review1post',
					},
					{
						text: 'Identifiera ackord från noter',
						showHistory: 1,
						type: 'exercise',
						id: 'IZKO9z08KT5wFtvwuCz7',
						key: 'chord_majorMinor_identify_sheetmusics__review1post',
					},
					{ text: 'Rytm, 1/8', type: 'section' },
					{
						text: 'Spela åttondelsrytmer, grundfigur 1-4',
						showHistory: 1,
						type: 'exercise',
						id: '3VqJFNdv6cZjpFDjQcSZ',
						key: 'rhythm_eights_plain_24_play__review1post_lvl1',
					},
					{
						text: 'Spela åttondelsrytmer, paus',
						showHistory: 1,
						type: 'exercise',
						id: 'Hw2oppSu3sjVvzpJzsEk',
						key: 'rhythm_eights_rests_24_play__review1post_lvl1',
					},
				],
			},
			/* #####################
               Kursplan 2/3
            ######################## */

			melodyMajor16: {
				text: 'Durskalan, ton 1-6',
				category: 'key',
				sections: [
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt110a',
						key: 'melody_key_major_tunes_identify_dt110',
					},
					// pianister och sångare: kompa sig själva, övriga sjunga till inspelat ackompanjemang?
					//{text: 'Sjunga till ackompanjemang', type: 'exercise', id: 'zWh7lerBkXhttgs4CiRn', key: 'melody_key_major_tunes_sing_dt110b'},
					{
						text: 'Sjunga efter noter',
						type: 'article',
						id: 'dt110b',
						key: 'melody_key_major_tunes_sing_dt110',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt110m',
						key: 'melody_key_major_tunes_write_dt110',
					},
				],
			},
			melodyMinor16: {
				text: 'Mollskalan, ton 1-b6',
				category: 'key',
				sections: [
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt111a',
						key: 'melody_key_minor_tunes_identify_dt111',
					},
					{
						text: 'Sjunga melodier',
						type: 'article',
						id: 'dt111b',
						key: 'melody_key_minor_tunes_sing_dt111',
					},
					{
						text: 'Fermattecken',
						type: 'article',
						id: 'dt86fermata',
						key: 'symbols_tempo_fermata',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt111m',
						key: 'melody_key_minor_tunes_write_dt111',
					},
				],
			},
			rhythm16plain: {
				text: 'Sextondelar, grundfigurer',
				category: 'rhythm',
				sections: [
					{
						text: 'Sextondelsnoter',
						type: 'article',
						id: 'dt34',
						key: 'rhythm_sixteens_plain_math_dt34',
					},
					{
						text: 'Beskriv notbilden',
						type: 'article',
						id: 'rhythm16plain24explain',
						key: 'rhythm_sixteens_ties_24_explain__lvl1',
					},
					{ text: 'Grundfigur 1-4', type: 'section' },
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt37a',
						key: 'rhythm_sixteens_plain_24_read__lvl1',
					},
					{
						text: 'Spela',
						showHistory: 1,
						type: 'exercise',
						id: '0TAeO7c2JNCbUy0zdSu3',
						key: 'rhythm_sixteens_plain_24_play__lvl1',
					},
					//{text: 'Spela, längre uppgifter', type: 'exercise', id: '', key: 'rhythm_sixteens_plain_24_play__lvl2'},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: '3pStDTLwyRfivi042Tvk',
						key: 'rhythm_sixteens_plain_24_write__lvl1',
					},
					{ text: 'Grundfigur 5-6', type: 'section' },
					//{ text: 'Upptakt', type: 'article', id: 'pickup1', key: 'form_pickup' },
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt37b',
						key: 'rhythm_sixteens_plain_24_read__lvl2',
					},
					{
						text: 'Spela',
						showHistory: 1,
						type: 'exercise',
						id: 'k3oLSXIReFMCQaxfJud8',
						key: 'rhythm_sixteens_plain_24_play__lvl2',
					},
					{
						text: 'Skriva',
						type: 'exercise',
						id: 'baH65axWTyOPCoXvXbZH',
						key: 'rhythm_sixteens_plain_24_write__lvl2',
					},
					{ text: 'Grundfigur 7-8', type: 'section' },
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt37c',
						key: 'rhythm_sixteens_plain_24_read__lvl3',
					},
					{
						text: 'Spela',
						showHistory: 1,
						type: 'exercise',
						id: 'mtWlLjyjEu1CJmDJ6pAo',
						key: 'rhythm_sixteens_plain_24_play__lvl3',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: 'qRHcXNKnyYHFozIkxHdx',
						key: 'rhythm_sixteens_plain_24_write__lvl3',
					},
					{ text: 'Slutuppgifter', type: 'section' },
					{
						text: 'Spela',
						showHistory: 1,
						type: 'exercise',
						id: 'otyJdaEahdnZwZvA9DLQ',
						key: 'rhythm_sixteens_plain_24_play__lvl4',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: 'DUtx1AVgSOkHLO3YfHY3',
						key: 'rhythm_sixteens_plain_24_write__lvl4',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt37m',
						key: 'rhythm_sixteeens_plain_24_write__dt37',
					},
				],
			},
			interval2b: {
				text: 'Intervall (repetition)',
				category: 'interval',
				sections: [
					{
						text: 'Identifiera intervall',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'QXPJ5pRXC8LXlpBl7EM8',
						key: 'interval_diatonic_read_score_18__lvl3',
					},
					/*{
						text: 'Skriva intervall',
						isHomework: true,
						type: 'article',
						id: '2k3',
						key: 'interval_diatonic_write_score_18__lvl3',
					},*/
					{
						text: 'Lyssna på intervall 1-6',
						showHistory: 1,
						showHistorySummary: 5,
						comment: 'Brutet, startton alltid c',
						type: 'exercise',
						id: 'w24IXCljcQqnlzPU3ej2',
						key: 'interval_diatonic_listen_15__lvl3',
						activateProgression: 'progressionIntervalNatural4',
					},
				],
			},
			melodyMajor76: {
				text: 'Kromatik samt ledton i dur',
				category: 'key',
				sections: [
					{
						text: 'Kromatisk skala',
						type: 'article',
						id: 'dt112a',
						key: 'scale_chromatic__dt112',
					},
					{
						text: 'Ledton',
						type: 'article',
						id: 'dt112b',
						key: 'melody_guideTones__dt112',
					},
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt113a',
						key: 'melody_key_major_tunes_identify_dt113',
					},
					{
						text: 'Sjunga melodier',
						type: 'article',
						id: 'dt113b',
						key: 'melody_key_major_tunes_sing_dt113',
					},
					{
						text: 'Skriva förtecken',
						type: 'exercise',
						id: 'ob8dEhRWjOSAfOYwMp0u',
						key: 'key_major_signs_write__lvl3',
						showHistory: 1,
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt113m',
						key: 'melody_key_major_tunes_write_dt113',
					},
				],
			},
			harmonicMinor: {
				text: 'Harmonisk moll',
				category: 'key',
				sections: [
					//{text: 'Harmonisk moll', type: 'section'},
					{
						text: 'Parallelltonarter',
						type: 'article',
						id: 'relativeKey2',
						key: 'melody_key_relativeKey_steps__lvl2',
					},
					{
						text: 'Skriva förtecken (moll)',
						type: 'exercise',
						id: 'PPo2LUZ8NgVaAptmTHFA',
						key: 'key_minor_signs_write__lvl2',
						showHistory: 1,
					},
					{
						text: 'Harmonisk moll',
						type: 'article',
						id: 'dt114',
						key: 'scale_minor_harmonic__dt114',
					},
					{
						text: 'Lyssna på skalor',
						type: 'exercise',
						id: '9NLrGjnq1HamJ7cNo2Ig',
						key: 'scale_harmonic_listen__lvl1',
						showHistory: 1,
					},
					{
						text: 'Ren eller harmonisk skala?',
						type: 'exercise',
						id: 'pvqXtuJzT3xs6G0M3hwb',
						key: 'scale_harmonic_score_read__lvl1',
						showHistory: 1,
					},
					{
						text: 'Skalor med tillfälliga förtecken',
						type: 'exercise',
						id: 'NA4CxMmqB6HKkf4M9LSJ',
						key: 'scale_harmonic_score_read__lvl2',
						showHistory: 1,
					},
					{
						text: 'Skriva skalor',
						type: 'article',
						id: 'dt115',
						key: 'scale_minor_harmonic_write__dt115',
					},
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt116a',
						key: 'melody_key_minor_tunes_identify_dt116f',
					},
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt116b',
						key: 'melody_key_minor_tunes_sing_dt116f',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt116m',
						key: 'melody_key_minor_tunes_write_dt116',
					},
					//{text: 'Skriva', type: 'article', id: '', key: ''},
				],
			},
			chordsMajorMinor: {
				text: 'Dur- och mollackord',
				category: 'chord',
				sections: [
					{
						text: 'Spela på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'bbNPaeRF9AYDnGydD6qr',
						key: 'chord_majorMinor_play_piano_chordSymbol__lvl4',
					},
					{
						text: 'Identifiera på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'nwN3rWpatXeOAyvh7rsg',
						key: 'chord_majorMinor_identify_piano__lvl2',
					},
					//{text: 'Att identifiera ackord från noter', type: 'article', id: 'identifyChordsSheetmusic', key: 'chord_majorMinor_identify_score__about'},
					{
						text: 'Identifiera från noter',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '2fTp1HexmuNRyo9u9AXy',
						key: 'chord_majorMinor_identify_score__lvl1',
					},
					{
						text: 'Ackordtoner',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'G1vD3uIpNsM3x5XEngrx',
						key: 'chord_majorMinor_write_buttons__lvl2',
					},
					/*{
						text: 'Skriva och identifiera',
						isHomework: true,
						type: 'article',
						id: '2k10',
						key: 'chord_majorMinor_identify_score__2k10',
					},*/
				],
			},
			minorB7: {
				text: 'Moll med b7',
				category: 'key',
				sections: [
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt117a',
						key: 'melody_key_minor_tunes_identify_dt117f',
					},
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt117b',
						key: 'melody_key_minor_tunes_sing_dt117f',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt117m',
						key: 'melody_key_minor_tunes_write_dt117',
					},
				],
			},
			minorChordProgA: {
				text: 'Huvudfunktioner i C-moll',
				category: 'chordProg',
				sections: [
					{ text: 'C-moll och IVm', type: 'section' },
					{
						text: 'IVm i c-moll',
						type: 'article',
						id: 'dt164',
						key: 'harmony_progression_minor_145__dt164',
					},
					{
						text: 'Sjunga basstämman',
						type: 'article',
						id: 'dt165a',
						key: 'harmony_progression_minor_145_sing__dt165',
					},
					{
						text: 'Skriva vilka ackord',
						type: 'article',
						id: 'dt165m',
						key: 'harmony_progression_minor_145_write__dt165',
					},
					{ text: 'C-molls dominant', type: 'section' },
					{
						text: 'Harmonisk moll och ackord',
						type: 'article',
						id: 'dt166',
						key: 'harmony_progression_minor_145__dt166',
					},
					{
						text: 'Sjunga basstämman',
						type: 'article',
						id: 'dt167a',
						key: 'harmony_progression_minor_145_sing__dt167',
					},
					{
						text: 'Lyssna på basen',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '4Sjco6Vj1RXVIuSSclNU',
						key: 'harmony_progression_minor_145_listen__lvl1',
					},
					{
						text: 'Lyssna på ackord',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'LJ44H2ujQNH75E1kPumn',
						key: 'harmony_progression_minor_145_listen__lvl2',
					},
					{
						text: 'Vanliga ackordföljder',
						type: 'article',
						id: 'chordProgCommonMinor45',
						key: 'harmony_progression_minor_145__common45',
					},
					{
						text: 'Lyssna på basen (längre)',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'ZjDoZsvilWuNtyJYo1NL',
						key: 'harmony_progression_minor_145_listen_base__lvl3',
					},
					{
						text: 'Lyssna på ackord (längre)',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'Xjz0FxogK9MYPpvABbBV',
						key: 'harmony_progression_minor_145_listen__lvl3',
					},
					{
						text: 'Skriva vilka ackord',
						type: 'article',
						id: 'dt167m',
						key: 'harmony_progression_minor_145_write__dt167',
					},
					{
						text: 'Lyssna på ackord: V eller Vm?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'ZD1NluKTcQaWrUKeFTCr',
						key: 'harmony_progression_minor_145_listen__lvl3',
					},
				],
			},
			rhythm16ties: {
				text: 'Sextondelar, bindebåge',
				category: 'rhythm',
				sections: [
					{ text: '2/4 taktart', type: 'section' },
					{
						text: 'Läsa: Börja utan',
						type: 'article',
						id: 'rhythm_sixteens_ties_24_read__lvl1',
						key: 'rhythm_sixteens_ties_24_read__lvl1',
					},
					{
						text: 'Läsa: Ändra ord?',
						type: 'article',
						id: 'rhythm_sixteens_ties_24_read__lvl2',
						key: 'rhythm_sixteens_ties_24_read__lvl2',
					},
					{
						text: 'Spela (två takter)',
						showHistory: 1,
						type: 'exercise',
						id: '8QREZv3YiHqsQScgZRr0',
						key: 'rhythm_sixteens_ties_24_play__lvl5',
					},
					{
						text: 'Spela (fyra takter)',
						showHistory: 1,
						type: 'exercise',
						id: 'NUIo5GfWajvnJKvddiRv',
						key: 'rhythm_sixteens_ties_24_play__lvl5-4',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: 'R8L28jqW3JUVdpwYPcO2',
						key: 'rhythm_sixteens_ties_24_write__lvl5',
					},
					{ text: '4/4 taktart', type: 'section' },
					{
						text: 'Läsa: öva bakifrån',
						type: 'article',
						id: 'rhythm_sixteens_ties_44_read__lvl1',
						key: 'rhythm_sixteens_ties_44_read__lvl1',
					},
					{
						text: 'Rita in slagen',
						type: 'article',
						id: 'rhythm_sixteens_beats_44_write__lvl2',
						key: 'rhythm_sixteens_beats_44_write__lvl2',
					},
					{
						text: 'Läsa: Mera bindebågar',
						type: 'article',
						id: 'rhythm_sixteens_ties_44_read__lvl2',
						key: 'rhythm_sixteens_ties_44_read__lvl2',
					},
					{
						text: 'Spela',
						showHistory: 1,
						type: 'exercise',
						id: 'VO97zVEbL8V7OuYoKTRW',
						key: 'rhythm_sixteens_ties_44_play__lvl5',
					},
					{
						text: 'Skriva',
						showHistory: 1,
						type: 'exercise',
						id: 'spS0LHJt5B2eeQXBJCDL',
						key: 'rhythm_sixteens_ties_44_write__lvl5',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt39m',
						key: 'rhythm_sixteens_ties_44_write__dt39',
					},
				],
			},
			intervalMinor: {
				text: 'Litet intervall',
				category: 'interval',
				sections: [
					{
						text: 'Litet intervall',
						type: 'article',
						id: '2k4',
						key: 'interval_minor_write_score_18__lvl1',
					},
					{
						text: 'Intervall från noter',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'qoCxvGuG9rE800f6EGdx',
						key: 'interval_minor_read__lvl1',
					},
					{
						text: 'Lyssna på intervall: sekund',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'evDaKqLPj3eAUYjNkRp1',
						key: 'interval_minor_listen_2__lvl1',
					},
					{
						text: 'Lyssna på intervall: ters',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'CEOWQjDMRR0me3LgsO74',
						key: 'interval_minor_listen_3__lvl1',
					},
					{
						text: 'Lyssna på intervall: sekund och ters',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'iEmf2BChETJyjNRR9ksI',
						key: 'interval_minor_listen_23__lvl1',
					},
				],
			},
			transpose2: {
				text: 'Transponering med tillfälliga förtecken',
				category: 'key',
				sections: [
					//'', type: 'article', id: 'melody_transpose_accidentials__example', key: 'melody_transpose_accidentials__example'},
					{
						text: 'Transponera melodier',
						isHomework: true,
						type: 'article',
						id: 'melody_transpose_accidentials__lvl1',
						key: 'melody_transpose_accidentials__lvl1',
					},
				],
			},
			majorChordProgB: {
				text: 'Huvudfunktioner i olika durtonarter',
				category: 'chordProg',
				sections: [
					{
						text: 'Ackorden i olika tonarter',
						type: 'article',
						id: 'dt168',
						key: 'harmony_progression_major_145__dt168',
					},
					{
						text: 'Ackord från basstämma',
						type: 'article',
						id: 'dt169a',
						key: 'harmony_progression_major_145__dt169',
					},
					{
						text: 'Sjunga basstämman',
						type: 'article',
						id: 'dt169b',
						key: 'harmony_progression_major_145_sing__dt169',
					},
					{ text: 'Lyssna på ackordprogressioner', type: 'section' },
					{
						text: 'Lyssna på basen',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'BR0nixWvbWqpk0TjqmLe',
						key: 'harmony_progression_major_145_listen_base__lvl1',
					},
					{
						text: 'Lyssna på ackord',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'wrg4Oqh6FpfDgfqiSX0r',
						key: 'harmony_progression_major_145_listen__lvl1',
					},
					{
						text: 'Lyssna på basen, längre',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'RFC60rkRaNx4wivLg4Pq',
						key: 'harmony_progression_major_145_listen_base__lvl2',
					},
					{
						text: 'Lyssna på ackord, längre',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'yJR2r5YxPF0S39inU5Zr',
						key: 'harmony_progression_major_145_listen__lvl2',
					},
					{
						text: 'Skriva I, IV och V (boken)',
						type: 'article',
						id: 'dt169m',
						key: 'harmony_progression_major_145_write__dt169',
					},
					{ text: 'Harmonisering', type: 'section' },
					{
						text: 'Spela durackord på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'KBHAgJcQwd96TQdfoo8Q',
						key: 'chord_majorMinor_play_piano_chordSymbol__lvl2',
					},
					//{text: 'Vilka toner innehåller ackorden?', type: '', id: '', key: ''},
					{
						text: 'Introduktion: "Du ska inte tro..."',
						type: 'exercise',
						id: 'HHy7xgdQikp5sCBy0YMI',
						key: 'harmony_progression_145_write__lvl1',
					},
					{
						text: '"Blinka lilla stjärna där"',
						type: 'exercise',
						id: 'U9tJNefHeHhRTzFeCZ9V',
						key: 'harmony_progression_145_write__lvl1',
					},
				],
			},
			minorChordProgB: {
				text: 'Huvudfunktioner i olika molltonarter',
				category: 'chordProg',
				sections: [
					{
						text: 'Ackorden i olika tonarter',
						type: 'article',
						id: 'dt170',
						key: 'harmony_progression_minor_145__dt170',
					},
					{
						text: 'Ackord från basstämma',
						type: 'article',
						id: 'dt171a',
						key: 'harmony_progression_minor_145__dt171',
					},
					{
						text: 'Sjunga basstämman',
						type: 'article',
						id: 'dt171b',
						key: 'harmony_progression_minor_145_sing__dt171',
					},
					{ text: 'Lyssna på ackordprogressioner', type: 'section' },
					{
						text: 'IVm V eller V IVm?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'JEOBIbttwQyfvhlubKl5',
						key: 'harmony_progression_minor_145_listen__lvl1',
					},
					{
						text: 'IVm och V, längre',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'LejhMnzlBzqcy1WowOoT',
						key: 'harmony_progression_minor_145_listen__lvl2',
					},
					{
						text: 'Skriva Im, IVm och V (boken)',
						type: 'article',
						id: 'dt171m',
						key: 'harmony_progression_minor_145_write__dt171',
					},
					{ text: 'Dur eller mollackord?', type: 'section' },
					//{text: 'Höra skillnad på V och Vm', type: 'article', id: '', key: ''},
					{
						text: 'V eller Vm?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '9FflRvRQHA4nVEtQy2bw',
						key: 'harmony_progression_minor_145_listen__lvl3',
					},
					{
						text: 'IV eller IVm?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'wo3ivkXz6J01CjolgxZO',
						key: 'harmony_progression_minor_145_listen__lvl4',
					},
				],
			},
			melodyMajor65: {
				text: 'Durskalan, ton 6-5',
				category: 'key',
				sections: [
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt120a',
						key: 'melody_key_major_tunes_identify_dt120',
					},
					{
						text: 'Sjunga melodier',
						type: 'article',
						id: 'dt120b',
						key: 'melody_key_major_tunes_sing_dt120',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt120m',
						key: 'melody_key_major_tunes_write_dt120',
					},
				],
			},
			augIntervalChords: {
				text: 'Överstigande intervall och ackord',
				category: 'chord',
				sections: [
					{ text: 'Dubbelhöjning', type: 'section' },
					{
						text: 'Dubbelhöjning',
						showHistory: 1,
						type: 'article',
						id: 'doubleAccidentialSharp',
						key: 'note_accidentials_double_sharp__about',
					},
					{
						text: 'Spela på piano',
						showHistory: 1,
						type: 'exercise',
						id: 'OFNTqVUt1aok6ZsFq1Ag',
						key: 'note_accidentials_piano_double_play__lvl1',
					},
					{
						text: 'Identitifera från noter',
						showHistory: 1,
						type: 'exercise',
						id: 'dXcQSIWcJoUegOmNhwM9',
						key: 'note_accidentials_double_read__lvl1',
					},
					{
						text: 'Skriva i noter',
						showHistory: 1,
						type: 'exercise',
						id: 'kgEsoumln6dfkXL6lo2j',
						key: 'note_accidentials_double_write__lvl1',
					},
					{ text: 'Överstigande intervall', type: 'section' },
					{
						text: 'Överstigande intervall',
						type: 'article',
						id: '2k5',
						key: 'interval_dimAug_aug_write_score_18__lvl1',
					},
					{
						text: 'Identifiera intervall',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'bNyJf0Rh8oNZ92q4xLMK',
						key: 'interval_dimAug_aug_read_score_18__lvl1',
					},
					{ text: 'Överstigande ackord (#5)', type: 'section' },
					{
						text: 'Överstigande ackord',
						type: 'article',
						id: 'augChords',
						key: 'chord_augDim_aug__lvl1',
					},
					{
						text: 'Spela på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'AXC0IKzDV0wgv2minYAn',
						key: 'chord_augDim_aug_piano_play__lvl1',
					},
					{
						text: 'Tips för ackordtoner',
						type: 'article',
						id: 'augChordsNoteNameTips',
						key: 'chord_augDim_aug__lvl2',
					},
					{
						text: 'Ackordtoner',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'iVE5GwtqME1H6phb7U74',
						key: 'chord_augDim_aug_write_buttons__lvl1',
					},
					{
						text: 'Lyssna, dur eller överstigande?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'W1KGptRNSw37ZCriN8EQ',
						key: 'chord_augDim_aug_listen__lvl1',
					},
					{
						text: 'Skriva och identifiera',
						isHomework: true,
						type: 'article',
						id: '2k10b',
						key: 'chord_augDim_aug_write__2k10b',
					},
				],
			},
			dimIntervalChords: {
				text: 'Förminskade intervall och ackord',
				category: 'chord',
				sections: [
					{ text: 'Dubbelsänkning', type: 'section' },
					{
						text: 'Dubbelsänkning',
						type: 'article',
						id: 'doubleAccidentialFlat',
						key: 'note_accidentials_double_flat__about',
					},
					{
						text: 'Spela på piano',
						type: 'exercise',
						showHistory: 1,
						id: 'TRzLPAPGpEBPnoAirmaF',
						key: 'note_accidentials_piano_double_play__lvl2',
					},
					{
						text: 'Identitifera från noter',
						type: 'exercise',
						showHistory: 1,
						id: 'KzvAAzKzvwzm3Mrd7oT8',
						key: 'note_accidentials_double_read__lvl2',
					},
					{
						text: 'Skriva i noter',
						type: 'exercise',
						showHistory: 1,
						id: '5OUEIJUbvVzeZxFZ7NPD',
						key: 'note_accidentials_double_write__lvl2',
					},
					{ text: 'Förminskade intervall', type: 'section' },
					{
						text: 'Förminskade intervall',
						type: 'article',
						id: '2k6',
						key: 'interval_dimAug_dim_write_score_18__lvl1',
					},
					{
						text: 'Identifiera intervall',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '4hSHuBv6iqfseBBWVRig',
						key: 'interval_dimAug_dim_read_score_18__lvl1',
					},
					{
						text: 'Lyssna på intervall: b5',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'ozuYJA2yLFuplpkszZ28',
						key: 'interval_augDim_listen_45__lvl1',
						activateProgression: 'progressionInterval15',
					},
					{ text: 'Förminskade ackord (b5)', type: 'section' },
					{
						text: 'Förminskade ackord',
						type: 'article',
						id: 'dimChords',
						key: 'chord_augDim_dim_lvl1',
					},
					{
						text: 'Spela på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'fjx5Q9rFzz7WT8h45Z3N',
						key: 'chord_augDim_dim_piano_play__lvl1',
					},
					{
						text: 'Ackordtoner',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '8PrMMS5ka4iMuyhopnxm',
						key: 'chord_augDim_dim_write_buttons__lvl1',
					},
					{
						text: 'Lyssna, moll eller förminskat?',
						type: 'exercise',
						showHistory: 1,
						id: '7Ge5fQ3LLHcoDUPLqv9Q',
						key: 'chord_augDim_dim_listen__lvl1',
					},
					{
						text: 'Skriva och identifiera',
						isHomework: true,
						type: 'article',
						id: '2k11a',
						key: 'chord_augDim_dim_write__2k11a',
					},
				],
			},
			melodyMajor55: {
				text: 'Durskalan, ton  5-5',
				category: 'key',
				sections: [
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt121a',
						key: 'melody_key_major_tunes_identify_dt121',
					},
					//{text: 'Pianoackompanjemang', type: '', id: '', key: ''},
					//{text: 'Att kompa sig själv', type: '', id: '', key: ''},
					//{text: 'Sjunga två melodier till', type: '', id: '', key: ''},
					{
						text: 'Sjunga melodier',
						type: 'article',
						id: 'dt121b',
						key: 'melody_key_major_tunes_sing_dt121',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt121m',
						key: 'melody_key_major_tunes_write_dt121',
					},
				],
			},
			noteStuff: {
				text: 'Oktavatecken, dubbelförtecken',
				category: 'note',
				sections: [
					{ text: 'Dubbelförtecken', type: 'section' },
					{
						text: 'Dubbelförtecken',
						type: 'article',
						id: 'doubleAccidentials',
						key: 'note_accidentials_double__about',
					},
					{
						text: 'Spela på piano',
						type: 'exercise',
						showHistory: 1,
						id: 'RIQk35P9NwXDaRNSjet8',
						key: 'note_accidentials_piano_double_play__lvl3',
					},
					{
						text: 'Identitifera från noter',
						type: 'exercise',
						showHistory: 1,
						id: '2co6dFMB8X6IaPNu2rLA',
						key: 'note_accidentials_double_read__lvl3',
					},
					{ text: 'Oktavatecken', type: 'section' },
					{
						text: 'Oktavatecken och dubbelförtecken',
						isHomework: true,
						type: 'article',
						id: 'dt122',
						key: 'note_octaveds_8va__dt122',
					},
				],
			},
			melodicMinor: {
				text: 'Melodisk moll',
				category: 'key',
				sections: [
					//{text: 'Harmonisk moll', type: 'section'},
					{
						text: 'Förteckens placering i moll',
						type: 'article',
						id: 'dt124',
						key: 'melody_monir_key_fromMajor__dt124',
					},
					{
						text: 'Melodisk moll',
						type: 'article',
						id: 'dt125',
						key: 'scale_minor_melodic__dt125',
					},
					{
						text: 'Lyssna på skalor',
						type: 'exercise',
						id: 'Xl2iTxy7gEejbPRjLv06',
						key: 'scale_melodic_listen__lvl1',
						showHistory: 1,
					},
					{
						text: 'Identifiera skalor',
						type: 'exercise',
						id: 'ouU3QSAXw5uBjDZAjH7E',
						key: 'scale_melodic_score__lvl1',
						showHistory: 1,
					},
					{
						text: 'Skalor, tillfälliga förtecken',
						type: 'exercise',
						id: 'BydBHEycWlC16YldeOjY',
						key: 'scale_melodic_score__lvl1',
						showHistory: 1,
					},
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt126a',
						key: 'melody_key_minor_tunes_identify_dt126',
					},
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt126b',
						key: 'melody_key_minor_tunes_sing_dt126',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt126m',
						key: 'melody_key_minor_tunes_write_dt126',
					},
					//{text: 'Skriva', type: 'article', id: '', key: ''},
				],
			},
			intervalSummary1: {
				text: 'Sammanfattning intervall',
				category: 'interval',
				sections: [
					{
						text: 'Repetition',
						type: 'article',
						id: 'interval2review',
						key: 'interval_dimAug_about__lvl2',
					},
					{
						text: 'Identifiera intervall',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'KvvP4kVj0ZcId6ubM1Kc',
						key: 'interval_dimAug_dim_read_score_18__lvl2',
					},
					{
						text: 'Skriva och identifiera',
						isHomework: true,
						type: 'article',
						id: '2k7',
						key: 'interval_augDim_write__2k7',
					},
					{
						text: 'Lyssna: sext',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'Kym6Um87lpxyTOBe5SIh',
						key: 'interval_minor_listen_6__lvl1',
					},
					{
						text: 'Lyssna: septim',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'jAAo3ojGl7zSRCmlYZhj',
						key: 'interval_minor_listen_7__lvl1',
					},
					{
						text: 'Lyssna: sext och septim',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'bbPzciKJxJiivHtaeirh',
						key: 'interval_minor_listen_67__lvl1',
					},
				],
			},
			rhythm16rest: {
				text: 'Sextondelar, paus',
				category: 'rhythm',
				sections: [
					{
						text: 'Sextondelspaus',
						type: 'article',
						id: 'dt40',
						key: 'rhythm_sixteens_rests_intro__dt40',
					},
					{ text: '2/4 taktart', type: 'section' },
					{
						text: 'Läsa: Börja utan',
						type: 'article',
						id: 'rhythm_sixteens_rests_24_read__lvl1',
						key: 'rhythm_sixteens_rests_24_read__lvl1',
					},
					{
						text: 'Läsa: Ändra ord?',
						type: 'article',
						id: 'rhythm_sixteens_rests_24_read__lvl2',
						key: 'rhythm_sixteens_rests_24_read__lvl2',
					},
					{
						text: 'Spela (två takter)',
						showHistory: 1,
						type: 'exercise',
						id: 'xG1F4jEZoqf97O4uAEvJ',
						key: 'rhythm_sixteens_rests_24_play__lvl5',
					},
					{
						text: 'Spela (fyra takter)',
						showHistory: 1,
						type: 'exercise',
						id: 'FhecIBJHkXtSyfTlGToC',
						key: 'rhythm_sixteens_rests_24_play__lvl5-4',
					},
					{
						text: 'Skriva',
						type: 'exercise',
						id: 'RpL9mK5illbBAExt4wAq',
						key: 'rhythm_sixteens_rests_24_write__lvl5',
					},
					{ text: 'Rättskrivning', type: 'section' },
					{
						text: 'Balkning av sextondelar',
						isHomework: true,
						type: 'article',
						id: 'dt42',
						key: 'rhythm_notation_sixteens_beams__lvl1',
					},
					{ text: '4/4 taktart', type: 'section' },
					{
						text: 'Rita in slagen',
						type: 'article',
						id: 'rhythm_sixteens_beats_44_write__lvl3',
						key: 'rhythm_sixteens_beats_44_write__lvl3',
					},
					{
						text: 'Läsa: Öva bakifrån',
						type: 'article',
						id: 'rhythm_sixteens_rests_44_read__lvl1',
						key: 'rhythm_sixteens_rests_44_read__lvl1',
					},
					{
						text: 'Läsa: Mera pauser',
						type: 'article',
						id: 'rhythm_sixteens_rests_44_read__lvl2',
						key: 'rhythm_sixteens_rests_44_read__lvl2',
					},
					{
						text: 'Spela',
						showHistory: 1,
						type: 'exercise',
						id: 'Myw4h8ybuesUSMX1Eviu',
						key: 'rhythm_sixteens_rests_44_play__lvl5',
					},
					{
						text: 'Skriva',
						type: 'exercise',
						id: 'xvHhCAufAkeuLzotzVyp',
						key: 'rhythm_sixteens_rests_44_write__lvl5',
					},
					{
						text: 'Artikulationstecken',
						type: 'article',
						id: 'dt77a',
						key: 'symbols_articulation__dt77a',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt41m',
						key: 'rhythm_sixteens_rests_44_write__dt41',
					},
				],
			},
			chordMajorMinorOpen: {
				text: 'Ackord, spritt läge',
				category: 'chord',
				sections: [
					{ text: 'Sammanfattning treklanger', type: 'section' },
					//{ text: 'Olika treklanger', type: 'article', id: '', key: 'chord_augDim__lvl1' },
					{
						text: 'Spela på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'GNA5XhTWd6OWfE1nFY7Q',
						key: 'chord_augDim_play_piano_chordSymbol__lvl5',
					},
					{
						text: 'Identifiera på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '3HviHK11lNa43BRXbxko',
						key: 'chord_augDim_identify_piano__lvl3',
					},
					{
						text: 'Identifiera från noter',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'ddjIYywFkfk1v4ov8TBx',
						key: 'chord_augDim_identify_score__lvl3',
					},
					{
						text: 'Ackordtoner',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'cbw5892gubL54VhGMfLK',
						key: 'chord_augDim_write_buttons__lvl3',
					},
					{
						text: 'Skriva och identifiera',
						type: 'article',
						id: '2k12a',
						key: 'chord_augDim_identify_score__2k12a',
					},
					{
						text: 'Ackordgehör (brutet)',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'RYQawNtTSVmVPrW0QAiO',
						key: 'chord_augDim_listen__lvl1',
					},
					{
						text: 'Ackordgehör (samklang)',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'DQ6Q2b05JyqATkSiMUAL',
						key: 'chord_augDim_listen__lvl2',
					},
					{ text: 'Spritt läge', type: 'section' },
					//{ text: 'Om spritt läge', type: 'article', id: '', key: 'chord_augDim_openVoicing__lvl1' }, //chordsOpenVoicing
					{
						text: 'Skriva och identifiera',
						type: 'article',
						id: '2k12b',
						key: 'chord_augDim_identify_score__2k12b',
					},
					// {text: 'Identifiera från noter', showHistory: 1, showHistorySummary: 5, type: 'exercise', id: '', key: 'chord_augDim_identify_score__lvl3'},
				],
			},
			minorScalesSummary: {
				text: 'Skalor, sammanfattning',
				category: 'key',
				sections: [
					//{ text: 'Sammanfattning', type: 'article', id: '', key: 'scale_minor_summary__lvl2' }, //minorScalesSummary
					{
						text: 'Skriva skalor',
						type: 'article',
						id: '2k14',
						key: 'scale_minor_summary_write__2k14',
					}, //2k14
				],
			},
			review2: {
				text: 'Repetition',
				category: 'review',
				sections: [],
			},

			review2post: {
				text: 'Repetition',
				category: 'review',
				hidden: true,
				sections: [
					{
						text: 'Parallelltonarter"',
						type: 'exercise',
						showHistory: 1,
						id: 'APsVgrW1RkTXfJGYG01f',
						key: 'key_relativeKey_majorMinor__review2post',
					},
					{
						text: 'Dur och mollackord, ackordtoner"',
						type: 'exercise',
						showHistory: 1,
						id: 'uBCKXosPHbRUFgSmlxai',
						key: 'chord_majorMinor_write_buttons__review2post',
					},
					{ text: 'Dubbelhöjning, överstigande', type: 'section' },
					{
						text: 'Dubbelhöjning: Spela på piano',
						type: 'exercise',
						showHistory: 1,
						id: 'npbwlDKO6cN5sUstekPt',
						key: 'note_accidentials_piano_double_play__review2post',
					},
					{
						text: 'Dubbelhöjning: Identitifera från noter',
						type: 'exercise',
						showHistory: 1,
						id: 'Mh7tbBysiFuSvgN5mWOX',
						key: 'note_accidentials_double_read__review2post',
					},
					{
						text: 'Överstigande ackord: Spela på piano',
						showHistory: 1,
						type: 'exercise',
						id: '7bvomlcD9sJSeBoWD3hp',
						key: 'chord_augDim_aug_piano_play__review2post',
					},
					{
						text: 'Överstigande ackord: Ackordtoner',
						showHistory: 1,
						type: 'exercise',
						id: 'JkHUT9cwjxUUtU3W1AUZ',
						key: 'chord_augDim_aug_write_buttons__review2post',
					},
					{
						text: 'Överstigande ackord: Lyssna, dur eller överstigande?',
						showHistory: 1,
						type: 'exercise',
						id: 'WJ2B0xmSXwCyZZ7GZo2M',
						key: 'chord_augDim_aug_listen__review2post',
					},
					{ text: 'Dubbelsänkning, förminskade', type: 'section' },
					{
						text: 'Dubbelsänkning: Spela på piano',
						type: 'exercise',
						showHistory: 1,
						id: 'EbIhLoSKHip4DNLLWHw8',
						key: 'note_accidentials_piano_double_play__review2post',
					},
					{
						text: 'Dubbelsänkning: Identitifera från noter',
						type: 'exercise',
						showHistory: 1,
						id: 'RgMMdIiVjjmaTnMgGumr',
						key: 'note_accidentials_double_read__review2post',
					},
					{
						text: 'Förminskade ackord: Spela på piano',
						showHistory: 1,
						type: 'exercise',
						id: 'l6yUce95BGUe2NbXPMGo',
						key: 'chord_augDim_dim_piano_play__review2post',
					},
					{
						text: 'Förminskade ackord: Ackordtoner',
						showHistory: 1,
						type: 'exercise',
						id: 'xwoj7B19XAHzySlu7fo9',
						key: 'chord_augDim_dim_write_buttons__review2post',
					},
					{
						text: 'Förminskade ackord: Lyssna, moll eller förminskat?',
						type: 'exercise',
						id: 'K0a31PtolhV8zX5zOEpL',
						key: 'chord_augDim_dim_listen__review2post',
					},
					{ text: 'Intervall', type: 'section' },
					{
						text: 'Identifiera intervall',
						showHistory: 1,
						type: 'exercise',
						id: 'kuGYYooCcJNeR7s3o5sj',
						key: 'interval_dimAug_dim_read_score_18__review2post',
					},
					{ text: 'Treklanger', type: 'section' },
					{
						text: 'Treklanger: Spela på piano',
						showHistory: 1,
						type: 'exercise',
						id: 'W1HXL6D9OtiV8Bm3vJia',
						key: 'chord_augDim_play_piano_chordSymbol__review2post',
					},
					{
						text: 'Treklanger: Identifiera på piano',
						showHistory: 1,
						type: 'exercise',
						id: 'Er3mkheueLb3egsMTwQR',
						key: 'chord_augDim_identify_piano__review2post',
					},
					{
						text: 'Treklanger: Identifiera från noter',
						showHistory: 1,
						type: 'exercise',
						id: 'XUBmAFHMezo2b3XP0HUM',
						key: 'chord_augDim_identify_score__review2post',
					},
					{
						text: 'Treklanger: Ackordtoner',
						showHistory: 1,
						type: 'exercise',
						id: 'C3ooA8GxMCEVDztziEg8',
						key: 'chord_augDim_write_buttons__review2post',
					},
					{
						text: 'Treklanger: Ackordgehör (brutet)',
						showHistory: 1,
						type: 'exercise',
						id: 'rmhHWXs6B4N7uhbTB0xz',
						key: 'chord_augDim_listen__review2post',
					},
					{
						text: 'Treklanger: Ackordgehör (samklang)',
						showHistory: 1,
						type: 'exercise',
						id: 'ZRmNHTB5dnfdJcRJWfw2',
						key: 'chord_augDim_listen__review2post',
					},
					{ text: 'Rytm', type: 'section' },
					{
						text: 'Spela 1/16-rytm, grundfigurer',
						showHistory: 1,
						type: 'exercise',
						id: 'oTDypRt4bPfpbHxvTnjh',
						key: 'rhythm_sixteens_plain_24_play__review2post_lvl4',
					},
				],
			},
			/* #####################
               Kursplan 3/3
            ######################## */
			harmonicAnalysisMajor145: {
				text: 'Analys: huvudfunktioner i dur',
				category: 'chordProg',
				sections: [
					{ text: 'Huvudfunktioner', type: 'section' },
					{
						text: 'Introduktion',
						type: 'article',
						id: '3k14',
						key: 'harmony_progression_145_write__3k14',
					},
					{
						text: 'Mera från grunden',
						type: 'article',
						id: '3k16',
						key: 'harmony_progression_145_write__3k16',
					},
					{
						text: 'Harmonisera "Blinka lilla"',
						type: 'exercise',
						id: 'FiazFRZiuSkJwy5Nvix6',
						key: 'harmony_progression_145_write__lvl2',
					},
					{
						text: 'Transponera ackord',
						type: 'article',
						id: '3k16b',
						key: 'harmony_progression_145_transpose__3k16',
					},
					{
						text: 'Kadenser',
						type: 'article',
						id: '3k17',
						key: 'harmony_progression_145_cadenza__3k17a',
					},
					{ text: 'Lyssna på ackordprogressioner', type: 'section' },
					{
						text: 'Bas: IV till V?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '10wAoYo7FO748pRQYpPt',
						key: 'harmony_progression_major_145_listen_base__lvl3',
					},
					{
						text: 'IV till V?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '2QFhVsISRm7aoyfAcoJA',
						key: 'harmony_progression_major_145_listen__lvl3',
					},
					{
						text: 'IV till V (längre)?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'SEaLRPP0zB3X5UaaYpAU',
						key: 'harmony_progression_major_145_listen__lvl4',
					},
					{
						text: 'Skriva ackord utan att ens lyssna',
						type: 'article',
						id: '3k17b',
						key: 'harmony_progression_145_write__3k17b',
					},
				],
			},
			harmonicAnalysisMinor145: {
				text: 'Analys: huvudfunktioner i moll',
				category: 'chordProg',
				sections: [
					{ text: 'Huvudfunktioner', type: 'section' },
					{
						text: 'Lite fler valmöjligheter',
						type: 'article',
						id: '3k18',
						key: 'harmony_progression_145_write__3k18',
					},
					{
						text: 'Harmonisera i moll',
						type: 'article',
						id: '3k18b',
						key: 'harmony_progression_145_write__3k18b',
					},
					{ text: 'Lyssna på ackordprogressioner', type: 'section' },
					{
						text: 'IVm V eller V IVm?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'EruPxhwvzOGc3vUleiXF',
						key: 'harmony_progression_minor_145_listen__lvl5',
					},
					{
						text: 'IVm och V, längre',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'qvgmkdfPcmsAlRft0qhJ',
						key: 'harmony_progression_minor_145_listen__lvl6',
					},
					{ text: 'Dur eller mollackord?', type: 'section' },
					//{text: 'Höra skillnad på V och Vm', type: 'article', id: '', key: ''},
					{
						text: 'V eller Vm?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'AYW5lD5BKjMVK7i23YMO',
						key: 'harmony_progression_minor_145_listen__lvl7',
					},
					{
						text: 'IV eller IVm?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'xQHqvpJW5YLbpg9HH4El',
						key: 'harmony_progression_minor_145_listen__lvl8',
					},
					{
						text: 'IV, IVm, V och Vm?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'mIpVHQDdmCFqunPFa5ef',
						key: 'harmony_progression_minor_145_listen__lvl9',
					},
				],
			},
			rhythm38: {
				text: '3/8-taktart',
				category: 'rhythm',
				sections: [
					{
						text: 'Om 3/8 taktart',
						type: 'article',
						id: 'dt44',
						key: 'rhythm_sixteens_x8_38__intro',
					},
					{
						text: 'Beskriv notbilden',
						type: 'article',
						id: 'rhythm16x838explain',
						key: 'rhythm_sixteens_x8_38_explain__lvl1',
						activateProgression: 'progressionInterva58',
					},
					{
						text: 'Läsa (tre slag/takt)',
						type: 'article',
						id: 'dt45a',
						key: 'rhythm_sixteens_x8_38_read__lvl1',
					},
					{
						text: 'Spela',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'Q0zdgMSHwVP0evWh7TIj',
						key: 'rhythm_sixteens_x8_38_play__lvl1',
					},

					{
						text: 'Läsa (ett slag/takt)',
						type: 'article',
						id: 'dt45b',
						key: 'rhythm_sixteens_x8_38_read__lvl2',
					},
					{
						text: 'Spela (ett slag/takt)',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'oVoskxK2ALWHWX2cQ0nU',
						key: 'rhythm_sixteens_x8_38_play__lvl2',
					},
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt45c',
						key: 'rhythm_sixteens_x8_38_read__lvl3',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt45m',
						key: 'rhythm_sixterhythm_sixteens_x8_38_write__dt45',
					},
				],
			},
			rhythm68: {
				text: '6/8-taktart',
				category: 'rhythm',
				sections: [
					{
						text: 'Om 6/8 taktart',
						type: 'article',
						id: 'dt46',
						key: 'rhythm_sixteens_x8_68__intro',
					},
					{
						text: 'Beskriv notbilden',
						type: 'article',
						id: 'rhythm16x868explain',
						key: 'rhythm_sixteens_x8_68_explain__lvl1',
					},
					{
						text: 'Läsa (sex slag/takt)',
						type: 'article',
						id: 'dt47a',
						key: 'rhythm_sixteens_x8_68_read__lvl1',
					},
					{
						text: 'Spela (sex slag/takt)',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '8bmXoFHpJlhN7TumQuIL',
						key: 'rhythm_sixteens_x8_68_play__lvl1',
					},
					{
						text: 'Läsa (två slag/takt)',
						type: 'article',
						id: 'dt47b',
						key: 'rhythm_sixteens_x8_68_read__lvl2',
					},
					{
						text: 'Spela',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'EOimhuukIGTgo4eo4bU7',
						key: 'rhythm_sixteens_x8_68_play__lvl2',
					},

					{
						text: 'Läsa, del 3',
						type: 'article',
						id: 'dt47c',
						key: 'rhythm_sixteens_x8_68_read__lvl3',
					},
					{
						text: 'Spela',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'OkBQlUaN7iRYWiFycJLN',
						key: 'rhythm_sixteens_x8_68_play__lvl3',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt47m',
						key: 'rhythm_sixterhythm_sixteens_x8_68_write__dt47',
					},
				],
			},
			melodyMajor: {
				text: 'Melodier i dur',
				category: 'key',
				sections: [
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt128a',
						key: 'melody_key_minor_tunes_identify_dt128',
					},
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt128b',
						key: 'melody_key_minor_tunes_sing_dt128',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt128m',
						key: 'melody_key_minor_tunes_write_dt128',
					},
				],
			},
			seventhChords: {
				text: 'Septimackord',
				category: 'chord',
				sections: [
					{ text: 'Repetition av treklanger', type: 'section' },
					{
						text: 'Om treklanger',
						type: 'article',
						id: 'augDimChords',
						key: 'chord_augDim_lvl1',
					},
					{
						text: 'Spela på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'PaVIrrbWjXTA9c4B66m6',
						key: 'chord_augDim_piano_play__lvl1',
					},
					{
						text: 'Identifiera från piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'hTZF0hEyrfx037PA09Q2',
						key: 'chord_augDim_identify_piano__lvl2',
					},
					{
						text: 'Ackordtoner',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'cBToggF3leSoDkLnEALE',
						key: 'chord_augDim_write_buttons__lvl2',
					},
					{
						text: 'Identifiera från noter',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'WCAePXERqrk0olGyAKTe',
						key: 'chord_augDim_identify_score__lvl1',
					},
					{
						text: 'Skriv och identifiera',
						isHomework: true,
						type: 'article',
						id: '3k10a',
						key: 'chord_augDim_identify_score__3k10',
					},
					{
						text: 'Lyssna',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'TiSf3X0nPoDxG8Y04qQe',
						key: 'chord_augDim_listen__lvl1',
					},
					{ text: 'Septimackord', type: 'section' },
					{
						text: 'Septimackord',
						type: 'article',
						id: 'seventhChords',
						key: 'chord_seventh_lvl1',
					},
					{
						text: 'Spela på piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'YK63PN08tUXCU8FQjp06',
						key: 'chord_seventh_piano_play__lvl1',
					},
					{
						text: 'Identifiera från piano',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '6axjxEqoDhQyuDqNUeVY',
						key: 'chord_seventh_identify_piano__lvl2',
					},
					{
						text: 'Ackordtoner',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'AyBfVkFQ4ltadJGWPQDO',
						key: 'chord_seventh_write_buttons__lvl2',
					},
					{
						text: 'Identifiera från noter',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'VSLfoZ1VVCayb35fSgWy',
						key: 'chord_seventh_identify_score__lvl1',
					},
					{
						text: 'Skriv och identifiera',
						type: 'article',
						id: '3k10b',
						key: 'chord_seventh_identify_score__3k10b',
					},
					{
						text: 'Lyssna',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'dvYFfbqenyMrkIVbCQrU',
						key: 'chord_seventh_listen__lvl1',
					},
					{
						text: 'Nya klaver',
						isHomework: true,
						type: 'article',
						id: '3k3',
						key: 'note_clef_c__3k3',
					},
				],
			},
			dominantSeventh: {
				text: 'Dominantseptimackordet',
				category: 'chordProg',
				sections: [
					{
						text: 'Om dominantseptimackordet',
						type: 'article',
						id: '3k19c',
						key: 'harmony_progression_dom7_read__3k19',
					},
					{
						text: 'Uppgifter',
						type: 'article',
						id: '3k20',
						key: 'harmony_progression_dom7_write__3k20',
					},
				],
			},
			interval3: {
				text: 'Intervall: över oktav samt omvändning',
				category: 'interval',
				sections: [
					{
						text: 'Repetition',
						type: 'article',
						id: '3k5',
						key: 'interval_diatonic_write_18__3k5',
						activateProgression: 'progressionInterval18',
					},
					{
						text: 'Nona och decima',
						type: 'article',
						id: '3k6',
						key: 'interval_diatonic_write_10__3k6',
					},
					{
						text: 'Intervallens omvändning',
						type: 'article',
						id: '3k6b',
						key: 'interval_diatonic_inversion__3k6',
					},
					{
						text: 'Intervall i ackord',
						type: 'article',
						id: '3k6c',
						key: 'interval_diatonic_inChords__3k6',
					},
					{
						text: 'Skriva intervall nedåt',
						type: 'article',
						id: '3k7',
						key: 'interval_diatonic_down__3k7',
					},
				],
			},
			chordInversions: {
				text: 'Ackordomvändningar',
				category: 'chord',
				sections: [
					{
						text: 'Identifiera',
						type: 'article',
						id: '3k11',
						key: 'chord_inversion_identify_score__3k11',
					},
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt193',
						key: 'chord_inversion_sing__dt193',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt194m',
						key: 'chord_inversion_write__dt194',
					},
					{ text: 'Omvändningar och analys', type: 'section' },
					{
						text: 'Analysera',
						type: 'article',
						id: '3k20b',
						key: 'chord_inversion_identify_score__3k20b',
					},
					{
						text: 'Lyssna',
						type: 'article',
						id: '3k21',
						key: 'chord_inversion_write__3k21',
					},
				],
			},
			rhythm22: {
				text: 'Alla breve-taktart',
				category: 'rhythm',
				sections: [
					{
						text: 'Om alla breve',
						type: 'article',
						id: 'dt48',
						key: 'rhythm_sixteens_22__intro',
					},
					{
						text: 'Beskriv notbilden',
						type: 'article',
						id: 'rhythm22explain',
						key: 'rhythm_sixteens_22_explain__lvl1',
					},
					{
						text: 'Spela',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'XnXFFEatSiV0UyaajlJQ',
						key: 'rhythm_sixteens_22_play__lvl1',
					},
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt49a',
						key: 'rhythm_sixteens_22_read__lvl1',
					},
					{
						text: 'Spela',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '2s7n4viZZWdmivsGpJuI',
						key: 'rhythm_sixteens_22_play__lvl2',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt49m',
						key: 'rhythm_sixterhythm_sixteens_22_write__dt47',
					},
					{
						text: 'Rättskrivning (del 3)',
						isHomework: true,
						type: 'article',
						id: 'dt50',
						key: 'rhythm_notation_sixteens_grouping__lvl2',
					},
				],
			},
			melodyPentatonic: {
				text: 'Pentatonik',
				category: 'key',
				sections: [
					{
						text: 'Om pentatoniska skalor',
						type: 'article',
						id: 'dt130',
						key: 'scale_pentatonic_read__dt130',
					},
					{
						text: 'Skriva skalor',
						type: 'article',
						id: 'dt131',
						key: 'scale_pentatonic_write__dt131',
					},
					{ text: 'Durpentatonisk', type: 'section' },
					{
						text: 'Spela durpenta',
						type: 'embed',
						url: 'https://notio-novia-fi.netlify.app/shared/mZYVjpPViumvhiRToDPz',
						id: 'notioPentatonicMajor',
						key: 'scale_pentatonicMajor_play__notio',
					},
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt132a',
						key: 'scale_pentatonicMajor_identify_dt132',
					},
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt132b',
						key: 'scale_pentatonicMajor_sing_dt132',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt132m',
						key: 'scale_pentatonicMajor_write_dt132',
					},
					{ text: 'Mollpentatonisk', type: 'section' },
					{
						text: 'Spela mollpenta',
						type: 'embed',
						url: 'https://notio-novia-fi.netlify.app/shared/z6Yi6hXoNiKSQqRb2Oa7',
						id: 'notioPentatonicMinor',
						key: 'scale_pentatonicMinor_play__notio',
					},
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt133a',
						key: 'scale_pentatonicMinor_identify_dt133',
					},
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt133b',
						key: 'scale_pentatonicMinor_sing_dt133',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt133m',
						key: 'scale_pentatonicMinor_write_dt133',
					},
				],
			},
			harmonicAnalysisMajorParallels: {
				text: 'Analys: paralleller i dur',
				category: 'chordProg',
				sections: [
					{
						text: 'Analysera paralleller',
						type: 'article',
						id: '3k22',
						key: 'harmony_progression_majorParallels_write__3k22',
					},
					{
						text: 'Harmonisera "Blinka lilla"',
						type: 'exercise',
						id: 'ZLsWESNnWpr3EfGdT0KV',
						key: 'harmony_progression_major_write__lvl1',
					},
					{
						text: 'Transponera ackord',
						type: 'article',
						id: '3k23',
						key: 'harmony_progression_majorParallels_transpose__3k23',
					},
					{ text: 'Lyssna på ackordprogressioner', type: 'section' },
					{
						text: 'VIm',
						type: 'article',
						id: 'dt173m',
						key: 'harmony_progression_major_1456_listen__dt173',
					},
					{
						text: 'IIm',
						type: 'article',
						id: 'dt175m',
						key: 'harmony_progression_major_12456_listen__dt175',
					},
					{
						text: 'VIm',
						type: 'article',
						id: 'dt177m',
						key: 'harmony_progression_major_123456_listen__dt177',
					},
				],
			},
			swing2: {
				text: 'Swing, del 2',
				category: 'rhythm',
				sections: [
					{
						text: 'Mera om swing',
						type: 'article',
						id: 'dt52',
						key: 'rhythm_eights_swing__intro',
					},
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt53a',
						key: 'rhythm_eights_swing_read__lvl1',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt53m',
						key: 'rhythm_eights_swing_write__dt47',
					},
				],
			},
			blues: {
				text: 'Blues',
				category: 'key',
				sections: [
					{
						text: 'Bluesskalan',
						type: 'article',
						id: 'dt134',
						key: 'scale_blues_write__dt134',
					},
					{
						text: 'Spela blues',
						type: 'embed',
						url: 'https://notio-novia-fi.netlify.app/shared/53yDrivDgMvqs9McYl3H',
						id: 'notioBlues',
						key: 'scale_blues_play__notio',
					},
					{
						text: 'Tonarter och tonplatser',
						type: 'article',
						id: 'dt135a',
						key: 'scale_blues_identify_dt135',
					},
					{
						text: 'Sjunga',
						type: 'article',
						id: 'dt135b',
						key: 'scale_blues_sing_dt135',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'dt135m',
						key: 'scale_blues_write_dt135',
					},
				],
			},
			harmonicAnalysisMinorParallels: {
				text: 'Analys: paralleller i moll',
				category: 'chordProg',
				sections: [
					{
						text: 'Analysera paralleller',
						type: 'article',
						id: '3k24b',
						key: 'harmony_progression_minorParallels_write__3k22',
						activateProgression: 'progressionChordProgMinorParallels',
					},
					{ text: 'Lyssna på ackordprogressioner', type: 'section' },
					{
						text: 'bIII',
						type: 'article',
						id: 'dt179m',
						key: 'harmony_progression_minor_1345_listen__dt173',
					},
					{
						text: 'bVI',
						type: 'article',
						id: 'dt181m',
						key: 'harmony_progression_minor_13456_listen__dt175',
					},
					{
						text: 'bVII',
						type: 'article',
						id: 'dt183m',
						key: 'harmony_progression_minor_134567_listen__dt177',
					},
				],
			},
			rhythmDupletTriplet: {
				text: 'Duol och triol',
				category: 'rhythm',
				sections: [
					{ text: 'Duol', type: 'section' },
					{
						text: 'Om duol',
						type: 'article',
						id: 'dt54',
						key: 'rhythm_duplet__dt54',
					},
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt55a',
						key: 'rhythm_duplet_read__lvl1',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt55m',
						key: 'rhythm_duplet_write__dt55',
					},
					{ text: 'Triol', type: 'section' },
					{
						text: 'Om triol',
						type: 'article',
						id: 'dt56',
						key: 'rhythm_triplet__dt56',
					},
					{
						text: 'Läsa',
						type: 'article',
						id: 'dt57a',
						key: 'rhythm_triplet_read__lvl1',
					},
					{
						text: 'Skriva (i boken)',
						type: 'article',
						id: 'dt57m',
						key: 'rhythm_triplet_write__dt57',
					},
				],
			},
			transposingInstruments: {
				text: 'Transponerande instrument',
				category: 'key',
				sections: [
					{
						text: 'Transponera melodier',
						type: 'article',
						id: '3k8',
						key: 'melody_transpose_accidentials__lvl2',
					},
					{
						text: 'Transponerande instrument',
						type: 'article',
						id: '3k8b',
						key: 'melody_transpose_instruments__lvl1',
					},
				],
			},
			scaleSummary: {
				text: 'Skalor (sammanfattning)',
				category: 'key',
				sections: [
					{
						text: 'Alla skalor vi lärt oss',
						isHomework: true,
						type: 'article',
						id: '3k12',
						key: 'scale_np3_summary__3k12',
					},
				],
			},
			chordlistensummary: {
				text: 'Ackordgehör, repetition',
				category: 'chord',
				sections: [
					{ text: 'Treklanger', type: 'section' },
					{
						text: 'Brutet',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'rJLkveKJnf4RyJEo1vcq',
						key: 'chord_augDim_listen__lvl2',
					},
					{
						text: 'Samklang',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'biRjAOgYhcgUEcarBsVe',
						key: 'chord_augDim_listen__lvl3',
					},
					{ text: 'Fyrklanger', type: 'section' },
					{
						text: 'Brutet',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'nUE3UoFKKKziWgOUniEO',
						key: 'chord_seventh_listen__lvl2',
					},
					{
						text: 'Samklang',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'SPsf8iGeIjYQZ3j5esKE',
						key: 'chord_seventh_listen__lvl3',
					},
				],
			},
			miscReview3: {
				text: 'Repetition',
				sections: [
					{
						text: 'Identifiera intervall (vanliga)',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '0ULDDvwKsu8lEuvMZnh0',
						key: 'interval_all_read_score_110__lvl2',
					},
					{
						text: 'Identifiera intervall (alla)',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'X9pcUmD1Xl1SN0XdbZt2',
						key: 'interval_all_read_score_110__lvl3',
					},
					{
						text: 'Identifiera intervall (fasta fört.)',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'ohkGWYRfQCzxKtJUEgWj',
						key: 'interval_all_read_score_110__lvl4',
					},
				],
			},

			/*
        Stora ettan
      */

			rhythm16I: {
				text: 'Åttondelar och sextondelar',
				category: 'rhythm',
				sections: [
					{
						text: 'Läsa, del 1',
						type: 'article',
						id: 'rhythm_sixteens_xx_read__lvl5',
						key: 'rhythm_sixteens_xx_read__lvl5',
					},
					{
						text: 'Skriva',
						type: 'article',
						id: 'rhythm_sixteens_xx_write__no1',
						key: 'rhythm_sixteens_xx_write__no1',
					},
					{
						text: 'Läsa, del 2',
						type: 'article',
						id: 'rhythm_sixteens_xx_read__lvl5b',
						key: 'rhythm_sixteens_xx_read__lvl5b',
					},
					{
						text: 'Brudrovet',
						type: 'article',
						id: 'rhythm_sixteens_xx_write__brudrovet',
						key: 'rhythm_sixteens_xx_write__brudrovet',
					},
					{
						text: 'Cotton Eye Joe',
						type: 'article',
						id: 'rhythm_sixteens_xx_write__cottonEyeJoe',
						key: 'rhythm_sixteens_xx_write__cottonEyeJoe',
					},
					{
						text: 'Indian Summer',
						type: 'article',
						id: 'rhythm_sixteens_xx_write__indianSummer',
						key: 'rhythm_sixteens_xx_write__indianSummer',
					},
				],
			},
			rhythmTripletsI: {
				text: 'Trioler och kvartoler',
				category: 'rhythm',
				sections: [
					{
						text: 'Läsa',
						type: 'article',
						id: 'rhythm_triplet_xx_read',
						key: 'rhythm_triplet_xx_read',
					},
					{
						text: 'Eye of the Tiger',
						type: 'article',
						id: 'rhythm_triplet_xx_write__eyeOfTheTiger',
						key: 'rhythm_triplet_xx_write__eyeOfTheTiger',
					},
					{
						text: 'Arabian Night',
						type: 'article',
						id: 'rhythm_triplet_xx_write__arabianNight',
						key: 'rhythm_triplet_xx_write__arabianNight',
					},
					{
						text: 'Trioler och kvartoler 1',
						type: 'article',
						id: 'rhythm_triplet_xx_write__triplet1',
						key: 'rhythm_triplet_xx_write__triplet1',
					},
					{
						text: 'Trioler och kvartoler 2',
						type: 'article',
						id: 'rhythm_triplet_xx_write__triplet2',
						key: 'rhythm_triplet_xx_write__triplet2',
					},
				],
			},
			rhythmQuintupletI: {
				text: 'Kvintol, sextol, septol',
				category: 'rhythm',
				sections: [
					{
						text: 'Läsa',
						type: 'article',
						id: 'rhythm_567_xx_read',
						key: 'rhythm_567_xx_read',
					},
					{
						text: 'Kvintoler och septoler 1',
						type: 'article',
						id: 'rhythm_567_xx_write__1',
						key: 'rhythm_567_xx_write__1',
					},
					{
						text: 'Kvintoler och septoler 2',
						type: 'article',
						id: 'rhythm_567_xx_write__2',
						key: 'rhythm_567_xx_write__2',
					},
				],
			},
			rhythmOddMeter: {
				text: 'Udda taktart',
				category: 'rhythm',
				sections: [
					{ text: 'Fjärdedel som puls', type: 'section' },
					{
						text: 'Läsa',
						type: 'article',
						id: 'rhythm_oddQuarter_xx_read',
						key: 'rhythm_oddQuarter_xx_read',
					},
					{
						text: 'Turn The World Around',
						type: 'article',
						id: 'rhythm_oddQuarter_xx_write__1',
						key: 'rhythm_oddQuarter_xx_write__1',
					},
					{ text: 'Åttondel som puls', type: 'section' },
					{
						text: 'Läsa',
						type: 'article',
						id: 'rhythm_oddEight_xx_read',
						key: 'rhythm_oddEight_xx_read',
					},
					{
						text: 'Fill Her Up',
						type: 'article',
						id: 'rhythm_oddEight_xx_write__1',
						key: 'rhythm_oddEight_xx_write__1',
					},
				],
			},
			rhythmMeterChange: {
				text: 'Byte av taktart',
				category: 'rhythm',
				sections: [
					{
						text: 'Läsa',
						type: 'article',
						id: 'rhythm_meterChange_xx_read',
						key: 'rhythm_meterChange_xx_read',
					},
					{
						text: 'Byte av taktart 1',
						type: 'article',
						id: 'rhythm_meterChange_xx_write__1',
						key: 'rhythm_meterChange_xx_write__1',
					},
					{
						text: 'Byte av taktart 2',
						type: 'article',
						id: 'rhythm_meterChange_xx_write__2',
						key: 'rhythm_meterChange_xx_write__2',
					},
					{
						text: 'Alfons Åberg',
						type: 'article',
						id: 'rhythm_meterChange_xx_write__3',
						key: 'rhythm_meterChange_xx_write__3',
					},
				],
			},
			melodyAtonal: {
				text: 'Atonal melodi',
				category: 'key',
				sections: [
					{
						text: 'L/s2 samt r4',
						type: 'article',
						id: 'melody_atonal_24_read__lvl1',
						key: 'melody_atonal_24_read__lvl1',
					},
					{
						text: 'L/s2 samt r4 och r5',
						type: 'article',
						id: 'melody_atonal_245_read__lvl1',
						key: 'melody_atonal_245_read__lvl1',
					},
				],
			},
			modi: {
				text: 'Kyrkotonarter (modi)',
				category: 'key',
				sections: [
					{
						text: 'Om kyrkotonarter',
						type: 'article',
						id: 'melody_modi__intro',
						key: 'melody_modi__intro',
					},
					{
						text: 'Lydisk, mixolydisk',
						type: 'article',
						id: 'dt140',
						key: 'melody_modi_45_write__dt140',
					},
					{
						text: 'Dorisk, frygisk',
						type: 'article',
						id: 'dt142',
						key: 'melody_modi_23_write__dt142',
					},
					{
						text: 'Lokrisk',
						type: 'article',
						id: 'dt144',
						key: 'melody_modi_7_write__dt144',
					},
				],
			},
			chordI: {
				text: 'Ackord',
				category: 'chord',
				sections: [
					{ text: 'Skriva och indentifiera', type: 'section' },
					{
						text: 'Durackord, stor septim',
						type: 'article',
						id: 'chord_major7types_write__lvl1',
						key: 'chord_major7types_write__lvl1',
					},
					{
						text: 'Durackord, liten septim',
						type: 'article',
						id: 'chord_dominant7types_write__lvl1',
						key: 'chord_dominant7types_write__lvl1',
					},
					{
						text: 'Mollackord',
						type: 'article',
						id: 'chord_minorTypes_write__lvl1',
						key: 'chord_minorTypes_write__lvl1',
					},
					{
						text: 'b5/terslösa ackord',
						type: 'article',
						id: 'chord_b5no3Types_write__lvl1',
						key: 'chord_b5no3Types_write__lvl1',
					},
					{ text: 'Sjunga', type: 'section' },
					{
						text: 'Dur maj7',
						type: 'article',
						id: 'chord_major7_sing__lvl1',
						key: 'chord_major7_sing__lvl1',
					},
					{
						text: 'Dominant-7',
						type: 'article',
						id: 'chord_dominant7_sing__lvl1',
						key: 'chord_dominant7_sing__lvl1',
					},
					{
						text: 'Moll7',
						type: 'article',
						id: 'chord_minor7_sing__lvl1',
						key: 'chord_minor7_sing__lvl1',
					},
					{
						text: 'Moll7b5',
						type: 'article',
						id: 'chord_minor7b5_sing__lvl1',
						key: 'chord_minor7b5_sing__lvl1',
					},
				],
			},
			nonHarmonicTonesI: {
				text: 'Ackordfrämmande toner',
				category: 'key',
				sections: [
					{
						text: 'Obetonade',
						type: 'article',
						id: 'melody_nonHarmonicTones_unaccented_about__lvl1',
						key: 'melody_nonHarmonicTones_unaccented_about__lvl1',
					},
					{
						text: 'Identifiera obetonade',
						type: 'article',
						id: 'melody_nonHarmonicTones_unaccented_identify__lvl1',
						key: 'melody_nonHarmonicTones_unaccented_identify__lvl1',
					},
					{
						text: 'Betonade',
						type: 'article',
						id: 'melody_nonHarmonicTones_accented_about__lvl1',
						key: 'melody_nonHarmonicTones_accented_about__lvl1',
					},
					{
						text: 'Identifiera betonade',
						type: 'article',
						id: 'melody_nonHarmonicTones_accented_identify__lvl1',
						key: 'melody_nonHarmonicTones_accented_identify__lvl1',
					},
				],
			},
			voicing356: {
				text: 'Understämma',
				category: 'voicing',
				sections: [
					{
						text: 'Bordun',
						type: 'article',
						id: 'melody_voicing_bordun__lvl1',
						key: 'melody_voicing_bordun__lvl1',
					},
					{
						text: 'Ters, sext och hornkvint',
						type: 'article',
						id: 'melody_voicing_365_read__lvl1',
						key: 'melody_voicing_365_read__lvl1',
					},
					{
						text: 'Övningar',
						type: 'article',
						id: 'melody_voicing_365_write__lvl1',
						key: 'melody_voicing_365_write__lvl1',
					},
					{
						text: 'Önskevisan',
						type: 'article',
						id: 'melody_voicing_365_write__lvl2',
						key: 'melody_voicing_365_write__lvl2',
					},
					{
						text: 'Vaggsång',
						type: 'article',
						id: 'melody_voicing_365_write__lvl3',
						key: 'melody_voicing_365_write__lvl3',
					},
					{
						text: 'Molltonart',
						type: 'article',
						id: 'melody_voicing_365_minor_read__lvl1',
						key: 'melody_voicing_365_minor_read__lvl1',
					},
					{
						text: 'Till Österland',
						type: 'article',
						id: 'melody_voicing_365_minor_write__lvl1',
						key: 'melody_voicing_365_minor_write__lvl1',
					},
					{
						text: 'Vindarna sucka',
						type: 'article',
						id: 'melody_voicing_365_minor_write__lvl2',
						key: 'melody_voicing_365_minor_write__lvl2',
					},
				],
			},
			harmonicAnalysisMajorParallelsI: {
				text: 'Analys: paralleller',
				category: 'chordProg',
				sections: [
					{ text: 'Paralleller i dur', type: 'section' },
					{
						text: 'Analysera paralleller',
						type: 'article',
						id: 'IkV-2',
						key: 'harmony_progression_majorParallels_write__IkV-2',
					},
					{
						text: 'Lyssna efter ackorden',
						type: 'article',
						id: 'IkV-3a',
						key: 'harmony_progression_majorParallels_listen__IkV-3a',
					},
					{
						text: 'Harmonisera "Mälarö kyrka"',
						type: 'article',
						id: 'IkV-3b',
						key: 'harmony_progression_majorParallels_harmonize__IkV-3b',
					},
					{ text: 'Paralleller i moll', type: 'section' },
					{
						text: 'Analysera paralleller',
						type: 'article',
						id: 'IkV-3c',
						key: 'harmony_progression_minorParallels_write__IkV-3c',
					},
					{
						text: 'Lyssna på "Drunken Sailor"',
						type: 'article',
						id: 'IkV-3d',
						key: 'harmony_progression_minorParallels_listen__IkV-3d',
					},
					{
						text: 'Harmonisera "Six Ribbons"',
						type: 'article',
						id: 'IkV-4a',
						key: 'harmony_progression_minorParallels_harmonize__IkV-4a',
					},
				],
			},
			harmonicAnalysisInversion4: {
				text: 'Analys: Omvändningar',
				category: 'chordProg',
				sections: [
					{
						text: 'Omvändningar',
						type: 'article',
						id: 'IkV-4b',
						key: 'harmony_progression_inversions4_read__IkV-4b',
					},
					{
						text: 'Analysera',
						type: 'article',
						id: 'IkV-5a',
						key: 'harmony_progression_inversions4_write__IkV-5a',
					},
					{
						text: 'Lyssna efter ackorden',
						type: 'article',
						id: 'IkV-5b',
						key: 'harmony_progression_inversions4_listen__IkV-5b',
					},
					{
						text: 'Lyssna efter ackorden',
						type: 'article',
						id: 'IkV-5c',
						key: 'harmony_progression_inversions4_listen__IkV-5c',
					},
					{
						text: 'Lyssna på "Can You Feel...',
						type: 'article',
						id: 'IkV-5d',
						key: 'harmony_progression_inversions4_listen__IkV-5d',
					},
				],
			},
			harmonicAnalysisCadenza: {
				text: 'Klassiska kadensackord',
				category: 'chordProg',
				sections: [
					{
						text: 'Om klassiska kadensackord',
						type: 'article',
						id: 'IkV-6',
						key: 'harmony_progression_cadenza_read__IkV-6',
					},
					{
						text: 'Harmonisera',
						type: 'article',
						id: 'IkV-8b',
						key: 'harmony_progression_cadenza_harmonize__IkV-8b',
					},
					{
						text: 'Analysera',
						type: 'article',
						id: 'IkV-8c',
						key: 'harmony_progression_cadenza_write__IkV-8c',
					},
				],
			},
			harmonicAnalysisSecDom: {
				text: 'Mellandominant',
				category: 'chordProg',
				sections: [
					{
						text: 'Om mellandominater',
						type: 'article',
						id: 'IkV-10',
						key: 'harmony_progression_secDom_read__IkV-6',
					},
					{
						text: 'Översätt till ackord',
						type: 'article',
						id: 'IkV-11a',
						key: 'harmony_progression_secDom_translate__IkV-11a',
					},
					{
						text: 'Analysera',
						type: 'article',
						id: 'IkV-11b',
						key: 'harmony_progression_secDom_write__IkV-11b',
					},
					{
						text: 'Harmonisera',
						type: 'article',
						id: 'IkV-11c',
						key: 'harmony_progression_secDom_harmonize__IkV-11c',
					},
					{
						text: 'Analysera',
						type: 'article',
						id: 'IkV-11d',
						key: 'harmony_progression_secDom_write__IkV-11d',
					},
				],
			},
			harmonicAnalysisMellankadens: {
				text: 'Mellankadens/utvikning',
				category: 'chordProg',
				sections: [
					{
						text: 'Om mellankadens/utvikning',
						type: 'article',
						id: 'IkV-12',
						key: 'harmony_progression_melKad_read__IkV-12',
					},
					{
						text: 'Analysera',
						type: 'article',
						id: 'IkV-13b',
						key: 'harmony_progression_melKad_write__IkV-13b',
					},
				],
			},
			harmonicAnalysisKpBk: {
				text: 'Kontraparallell/bedräglig kadens',
				category: 'chordProg',
				sections: [
					{
						text: 'Om bedräglig kadens',
						type: 'article',
						id: 'IkV-14',
						key: 'harmony_progression_KpBk_read__IkV-14',
					},
					{
						text: 'Harmonisera',
						type: 'article',
						id: 'IkV-15a',
						key: 'harmony_progression_KpBk_harmonize__IkV-15a',
					},
					{
						text: 'Lyssna',
						type: 'article',
						id: 'IkV-15b',
						key: 'harmony_progression_KpBk_listen__IkV-15b',
					},
					{
						text: 'Analysera',
						type: 'article',
						id: 'IkV-15c',
						key: 'harmony_progression_KpBk_write__IkV-15c',
					},
				],
			},
			harmonicAnalysisDom9: {
				text: 'Dominantnonackordet',
				category: 'chordProg',
				sections: [
					{
						text: 'Om dominantnonackordet',
						type: 'article',
						id: 'IkV-16',
						key: 'harmony_progression_dom9_read__IkV-16',
					},
					{
						text: 'Vilket dim7?',
						type: 'article',
						id: 'IkV-17a',
						key: 'harmony_progression_dom9_identify__IkV-17a',
					},
					{
						text: 'Harmonisera',
						type: 'article',
						id: 'IkV-17b',
						key: 'harmony_progression_dom9_harmonize__IkV-17b',
					},
					{
						text: 'Analysera',
						type: 'article',
						id: 'IkV-17c',
						key: 'harmony_progression_dom9_write__IkV-17c',
					},
				],
			},
			harmonicAnalysisJazz1: {
				text: 'Jazzanalys',
				category: 'chordProg',
				sections: [
					{
						text: 'Om jazzanalys',
						type: 'article',
						id: 'IkV-18',
						key: 'harmony_progression_jazz1_read__IkV-18',
					},
					{
						text: 'Autumn Leaves',
						type: 'article',
						id: 'IkV-19',
						key: 'harmony_progression_jazz1_write__IkV-19',
					},
					{
						text: 'I Should Care',
						type: 'article',
						id: 'IkV-20',
						key: 'harmony_progression_jazz1_write__IkV-20',
					},
					{
						text: 'Fly Me To The Moon',
						type: 'article',
						id: 'IkV-21',
						key: 'harmony_progression_jazz1_write__IkV-21',
					},
					{
						text: 'Angel Eyes',
						type: 'article',
						id: 'IkV-22',
						key: 'harmony_progression_jazz1_write__IkV-22',
					},
					{
						text: "It's Only A Paper Moon",
						type: 'article',
						id: 'IkV-23',
						key: 'harmony_progression_jazz1_write__IkV-23',
					},
				],
			},
			voicingChoral: {
				text: 'Fyrstämmig stats',
				category: 'voicing',
				sections: [
					{
						text: 'Regler för fyrstämmig sats',
						type: 'article',
						id: 'IkVI-11',
						key: 'melody_voicing_choral_read__lvlVI-11',
					},
					{
						text: 'Hitta fel',
						type: 'article',
						id: 'IkVI-13',
						key: 'melody_voicing_choral_read__lvlVI-13',
					},
					{
						text: 'Huvudtreklanger i grundläge',
						type: 'article',
						id: 'IkVI-14a',
						key: 'melody_voicing_choral_write__lvlVI-14a',
					},
					{
						text: 'Alt och tenor',
						type: 'article',
						id: 'IkVI-14b',
						key: 'melody_voicing_choral_write__lvlVI-14b',
					},
					{
						text: 'Alt, tenor och bas',
						type: 'article',
						id: 'IkVI-14c',
						key: 'melody_voicing_choral_write__lvlVI-14c',
					},
				],
			},

			/*
        ?-uppgifter, aktiveras när annat uppgift slutförd eller manuellt av lärare
      */
			progressionRhythmWrite8Plain: {
				text: 'Skriva, åttondelar',
				category: 'rhythm',
				progression: true,
				progressionPriority: 0,
				sections: [
					{
						text: 'Grundfigur 1-4',
						type: 'exercise',
						points: 300,
						pointsSingle: 90,
						activateNext: 'onComplete',
						activateNextDelayDays: 1,
						showHistory: 1,
						id: 'YZ1bmtulO0jCUaxcHYB0',
						key: 'rhythm_eights_plain_24_write__lvl2b',
					},
					{
						text: 'Grundfigur 5-6',
						type: 'exercise',
						points: 300,
						pointsSingle: 90,
						activateNext: 'onComplete',
						activateNextDelayDays: 1,
						showHistory: 1,
						id: 'KySnF4z0ZlCUP4SMPjmq',
						key: 'rhythm_eights_plain_24_write__lvl3b',
					},
					{
						text: 'Grundfigur 7-8',
						type: 'exercise',
						points: 300,
						pointsSingle: 90,
						activateNext: 'onComplete',
						activateNextDelayDays: 1,
						showHistory: 1,
						id: 'bahr6mclhYDYfqCyIK31',
						key: 'rhythm_eights_plain_24_write__lvl4b',
					},
					{
						text: 'Grundfigurer',
						type: 'exercise',
						points: 300,
						pointsSingle: 90,
						activateNext: false,
						showHistory: 1,
						id: 'p1ZKwyy0mFHxiZvQnxYG',
						key: 'rhythm_eights_plain_24_write__lvl5b',
					},
				],
			},
			progressionRhythmWrite16Plain: {
				text: 'Skriva, sextondelar',
				category: 'rhythm',
				progression: true,
				progressionPriority: 0,
				sections: [
					{
						text: 'Grundfigur 1-4',
						type: 'exercise',
						points: 300,
						pointsSingle: 90,
						activateNext: 'onComplete',
						activateNextDelayDays: 1,
						showHistory: 1,
						id: 'VSaVWaRwtBJGRRtSfIGl',
						key: 'rhythm_eights_plain_24_write__lvl2b',
					},
					{
						text: 'Grundfigur 5-6',
						type: 'exercise',
						points: 300,
						pointsSingle: 90,
						activateNext: 'onComplete',
						activateNextDelayDays: 1,
						showHistory: 1,
						id: '1CJg6i2NYOEG99PUXn8t',
						key: 'rhythm_eights_plain_24_write__lvl3b',
					},
					{
						text: 'Grundfigur 7-8',
						type: 'exercise',
						points: 300,
						pointsSingle: 90,
						activateNext: 'onComplete',
						activateNextDelayDays: 1,
						showHistory: 1,
						id: '7WJe5e8JEc23ccEXnC3j',
						key: 'rhythm_eights_plain_24_write__lvl4b',
					},
					{
						text: 'Grundfigurer',
						type: 'exercise',
						points: 300,
						pointsSingle: 90,
						activateNext: false,
						showHistory: 1,
						id: 'nO9dR8O3IS279IawcG35',
						key: 'rhythm_eights_plain_24_write__lvl5b',
					},
				],
			},
			progressionNoteOctaveG: {
				text: 'Oktaver och g-klav',
				category: 'note',
				progression: true,
				progressionPriority: 0,
				sections: [
					{
						text: 'G-klav och ettstrukna oktaven',
						type: 'exercise',
						points: 90,
						pointsSingle: 65,
						activateNext: false,
						showHistory: 1,
						id: 'qBRX87zGowO2lt2QVhoA',
						key: 'note_octaves_common_gclef_read__lvl1b',
					},
					{
						text: 'Ton och oktav på piano',
						type: 'exercise',
						points: 480,
						pointsSingle: 110,
						activateNext: false,
						showHistory: 1,
						id: 'RnWSUDocw0jau2Sano95',
						key: 'note_octaves_common_piano_play__lvl3',
					},
					{
						text: 'Oktaver och g-klav (skriva)',
						type: 'exercise',
						points: 100,
						pointsSingle: 50,
						activateNext: false,
						showHistory: 1,
						id: 'v31eUZMhHW1m0PhUzgPs',
						key: 'note_octaves_common_gclef_write__lvl4b',
					},
					{
						text: 'Oktaver och g-klav',
						type: 'exercise',
						points: 320,
						pointsSingle: 110,
						activateNext: false,
						showHistory: 1,
						id: 'B93cyKtKkEbIdQQqaH1y',
						key: 'note_octaves_common_gclef_read__lvl4b',
					},
					{
						text: 'G-klav på piano',
						type: 'exercise',
						points: 150,
						pointsSingle: 65,
						activateNext: false,
						showHistory: 1,
						id: 'LewNZZGkM9s16pPNVzmR',
						key: 'note_octaves_common_gclef_read__lvl5b',
					},
				],
			},
			progressionNoteOctaveF: {
				text: 'F-klaven',
				category: 'note',
				progression: true,
				progressionPriority: 0,
				sections: [
					{
						text: 'F-klav och lilla oktaven',
						type: 'exercise',
						points: 90,
						pointsSingle: 65,
						activateNext: false,
						showHistory: 1,
						id: 'tuU6kvDPstdfGmMqjq64',
						key: 'note_octaves_common_fclef_read__lvl1b',
					},
					{
						text: 'Oktaver och f-klav (skriva)',
						type: 'exercise',
						points: 100,
						pointsSingle: 50,
						activateNext: false,
						showHistory: 1,
						id: 'RYwazCKxOpwslbLclALG',
						key: 'note_octaves_common_fclef_write__lvl4b',
					},
					{
						text: 'Oktaver och f-klav',
						type: 'exercise',
						points: 320,
						pointsSingle: 110,
						activateNext: false,
						showHistory: 1,
						id: 'ZgXAZkTASVcbANR3Py4C',
						key: 'note_octaves_common_fclef_read__lvl4b',
					},
					{
						text: 'F-klav på piano',
						type: 'exercise',
						points: 150,
						pointsSingle: 65,
						activateNext: false,
						showHistory: 1,
						id: '4WKcbM2pQDJuHZXGJtiE',
						key: 'note_octaves_common_fclef_play_piano__lvl2b',
					},
				],
			},
			progressionNoteAccidentials: {
				text: 'Förtecken',
				category: 'note',
				progression: true,
				progressionPriority: 0,
				sections: [
					{
						text: 'Förtecken på piano',
						type: 'exercise',
						points: 480,
						pointsSingle: 110,
						activateNext: false,
						showHistory: 1,
						id: 'pju9w5OWXJpYnwUAOM7r',
						key: 'note_octaves_accidentials_common_piano_play__lvl2b',
					},
					{
						text: 'Förtecken och g-klav',
						type: 'exercise',
						points: 160,
						pointsSingle: 55,
						activateNext: false,
						showHistory: 1,
						id: 'kZIGPFwyziXs9vIZz2DY',
						key: 'note_octaves_accidentials_common_gclef_read__lvl2b',
					},
					{
						text: 'Förtecken och g-klav (skriva)',
						type: 'exercise',
						points: 80,
						pointsSingle: 40,
						activateNext: false,
						showHistory: 1,
						id: 'cM0FlJ7M69vO8kJGqAKp',
						key: 'note_octaves_accidentials_common_gclef_write__lvl2b',
					},
					{
						text: 'G-klav på piano, förtecken',
						type: 'exercise',
						points: 150,
						pointsSingle: 55,
						activateNext: false,
						showHistory: 1,
						id: 'D9fQqjwIsAaCEIKQfulr',
						key: 'note_octaves_accidentials_common_gclef_read__lvl2b',
					},
					{
						text: 'Förtecken och f-klav',
						type: 'exercise',
						points: 160,
						pointsSingle: 55,
						activateNext: false,
						showHistory: 1,
						id: 'W6KC7jqYgY2EWuleRQd9',
						key: 'note_octaves_accidentials_common_fclef_read__lvl2b',
					},
					{
						text: 'Förtecken och f-klav (skriva)',
						type: 'exercise',
						points: 80,
						pointsSingle: 40,
						activateNext: false,
						showHistory: 1,
						id: 'b9vPRy8OkmBrss7AADg0',
						key: 'note_octaves_accidentials_common_fclef_write__lvl2b',
					},
					{
						text: 'F-klav på piano, förtecken',
						type: 'exercise',
						points: 150,
						pointsSingle: 55,
						activateNext: false,
						showHistory: 1,
						id: 'x8lhDjxtX9JNQNRIWYk7',
						key: 'note_octaves_accidentials_common_fclef_play_piano__lvl2b',
					},
				],
			},
			progressionKeys: {
				text: 'Dur- och molltonarter',
				category: 'melody',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Durtonart från fasta förtecken',
						type: 'exercise',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						id: 'Tc2r3tmfqK5sYtAYDKVU',
						key: 'key_major_rhymes__6lvl1-prog',
					},
					{
						text: 'Vilka förtecken, dur',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'fsJt9JGgfrOd2BwBLxEr',
						key: 'key_major_signs__6lvl1-prog',
					},
					{
						text: 'Skriva fasta förtecken, dur g-klav',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						activateNextDelayDays: 1,
						showHistory: 1,
						id: 'IUwy8lEfOlkqYFkp6WYS',
						key: 'key_major_signs_write_g__lvl2-prog',
					},
					{
						text: 'Skriva fasta förtecken, dur f-klav',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						showHistory: 1,
						activateNext: false,
						id: '0ktNjVDHHjPZpDSn2Vhm',
						key: 'key_major_signs_write_f__lvl2-prog',
					},
					{
						text: 'Parallelltonart (från dur)',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						activateNext: false,
						showHistory: 1,
						id: '2rP1onXmy0JanFIISlJ2',
						key: 'key_relativeKey_major__3lvl1-prog',
					},
					{
						text: 'Parallelltonart (från moll)',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						activateNext: false,
						showHistory: 1,
						id: 'l22MlRnSGcOOC5fdS5Me',
						key: 'key_relativeKey_minor__3lvl1-prog',
					},
					{
						text: 'Molltonart från fasta förtecken',
						type: 'exercise',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						id: 'F2gMzdfBogq3lEw9yftY',
						key: 'key_minor_rhymes__3lvl1-prog',
					},
					{
						text: 'Vilka förtecken, moll',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						activateNext: false,
						showHistory: 1,
						id: 'J4nlHA2yHmEZRL4dV4e7',
						key: 'key_minor_signs__3lvl1-prog',
					},
					{
						text: 'Skriva fasta förtecken, moll (g-klav)',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						activateNextDelayDays: 1,
						showHistory: 1,
						id: 'sGkfB6sQ50loR8Gm0xyu',
						key: 'key_minor_signs_write_g__3lvl2-prog',
					},
					{
						text: 'Skriva fasta förtecken, moll (f-klav)',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						showHistory: 1,
						activateNext: false,
						id: 'dEJ93Erqbm04uSgYsJj8',
						key: 'key_minor_signs_write_f__3lvl2-prog',
					},
					{
						text: 'Vilken dur-/molltonart?',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'mvJiRym02fmEeg3q7Bbp',
						key: 'key_majorminor_score__3lvl1-prog',
					},
				],
			},
			progressionMelody13: {
				text: 'Melodi, dur 1-3',
				category: 'melody',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Startton 1',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'tb2YKfYwQJSIMx328Axx',
						key: 'melody_key_major_c_tunes_13_play_3-3_1__lvl1',
					},
					{
						text: 'Startton 2',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'pELZUSfLZrOUJPHoJiwU',
						key: 'melody_key_major_c_tunes_13_play_3-3_2__lvl1',
					},
					{
						text: 'Startton 3',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'pZsL4hKgyGwVcFTS7yDt',
						key: 'melody_key_major_c_tunes_13_play_3-3_3__lvl1',
					},
					{
						text: 'Blandade starttoner',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 90,
						showHistory: 1,
						id: 's16IfilnfnWPHIVybCFE',
						key: 'melody_key_major_c_tunes_13_play_3-3_x__lvl1',
					},
					{
						text: 'Okänd startton',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 90,
						showHistory: 1,
						id: '7Y8UM0pau3eo1JMlgIDc',
						key: 'melody_key_major_c_tunes_13_play_3-3_X__lvl1',
					},
					/*{ text: 'Fyra toner', type: 'section' },
          { text: 'Startton 1', type: 'exercise', showHistory: 1, id: 'ndUVjed6XQiYnMZj33iG', key: 'melody_key_major_c_tunes_13_play_4-4_1__lvl1'},
          { text: 'Blandade starttoner', type: 'exercise', showHistory: 1, id: '2um7QuK6ev4KIetOjY8A', key: 'melody_key_major_c_tunes_13_play_4-4_x__lvl1'},*/
				],
			},
			progressionMelody14: {
				text: 'Melodi, dur 1-4',
				category: 'melody',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Startton 1',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'TcyTAE8oO3Oyx1UYvx3y',
						key: 'melody_key_major_c_tunes_14_play_3-3_1__lvl1',
					},
					{
						text: 'Startton 2',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'KD9jsCuFaMBqKqQEcYrk',
						key: 'melody_key_major_c_tunes_14_play_3-3_2__lvl1',
					},
					{
						text: 'Startton 3',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'IHGf12jhWDvUIK9diZId',
						key: 'melody_key_major_c_tunes_14_play_3-3_3__lvl1',
					},
					{
						text: 'Startton 4',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'FA8JgPNV8KWmx8MnaOwF',
						key: 'melody_key_major_c_tunes_14_play_3-3_4__lvl1',
					},
					{
						text: 'Blandade starttoner',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 90,
						howHistory: 1,
						id: '48D7JBXIY07GveqlyX30',
						key: 'melody_key_major_c_tunes_14_play_3-3_x__lvl1',
					},
					{
						text: 'Okänd startton',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 90,
						showHistory: 1,
						id: 't4Liq92VaP2xgxOLZfXw',
						key: 'melody_key_major_c_tunes_14_play_3-3_X__lvl1',
						activateProgression: 'progressionMelody135',
					},
					/*{ text: 'Fyra toner', type: 'section' },
          { text: 'Startton 1', type: 'exercise', showHistory: 1, id: '', key: 'melody_key_major_c_tunes_14_play_4-4_1__lvl1'},
          { text: 'Blandade starttoner', type: 'exercise', showHistory: 1, id: '', key: 'melody_key_major_c_tunes_14_play_4-4_x__lvl1'},*/
				],
			},
			progressionMelody135: {
				text: 'Melodi, dur 1, 3 och 5',
				category: 'melody',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Startton 1',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'moWqiXwRdbNNJxt2Voot',
						key: 'melody_key_major_c_tunes_135_play_3-3_1__lvl1',
					},
					{
						text: 'Startton 3',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'sGPJWpKEnfT2wZDR6y2i',
						key: 'melody_key_major_c_tunes_135_play_3-3_3__lvl1',
					},
					{
						text: 'Startton 5',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'tIxpnjvpK8b7xUYzZWhv',
						key: 'melody_key_major_c_tunes_135_play_3-3_5__lvl1',
					},
					{
						text: 'Blandade starttoner',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 90,
						showHistory: 1,
						id: 'TIDy2vK9Pm6zu7obmE6z',
						key: 'melody_key_major_c_tunes_135_play_3-3_x__lvl1',
					},
					{
						text: 'Okänd startton',
						type: 'exercise',
						points: 150,
						pointsSingle: 90,
						pointsUnlockNext: 90,
						showHistory: 1,
						id: 'a8j3yACSwSP3TE6FlFCo',
						key: 'melody_key_major_c_tunes_135_play_3-3_X__lvl1',
						activateProgression: 'progressionMelody15',
					},
				],
			},
			progressionMelody15: {
				text: 'Melodi, dur 1-5',
				category: 'melody',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Startton 1',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'jgG3mDXtvVLtcfIa601k',
						key: 'melody_key_major_c_tunes_15_play_3-3_1__lvl1',
					},
					{
						text: 'Startton 2',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'IfJu6ipsxOg73hdNyGe2',
						key: 'melody_key_major_c_tunes_15_play_3-3_2__lvl1',
					},
					{
						text: 'Startton 3',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'aXs34j5mAVdvrnOvmwwU',
						key: 'melody_key_major_c_tunes_15_play_3-3_3__lvl1',
					},
					{
						text: 'Startton 4',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'kcAymS6fywqw1SUech7V',
						key: 'melody_key_major_c_tunes_15_play_3-3_4__lvl1',
					},
					{
						text: 'Startton 5',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 55,
						showHistory: 1,
						id: 'TCpbSgWREnst29z6OOLh',
						key: 'melody_key_major_c_tunes_15_play_3-3_5__lvl1',
					},
					{
						text: 'Blandade starttoner',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 90,
						showHistory: 1,
						id: '80KVrBtKgIfZhZBbdH7o',
						key: 'melody_key_major_c_tunes_15_play_3-3_x__lvl1',
					},
					{
						text: 'Okänd startton',
						type: 'exercise',
						points: 150,
						pointsSingle: 64,
						pointsUnlockNext: 90,
						showHistory: 1,
						id: 'TcSAemmJPrOn4LQtIZ1T',
						key: 'melody_key_major_c_tunes_15_play_3-3_X__lvl1',
					},
					/*{ text: 'Fyra toner', type: 'section' },
          { text: 'Startton 1', type: 'exercise', showHistory: 1, id: '', key: 'melody_key_major_c_tunes_15_play_4-4_1__lvl1'},
          { text: 'Blandade starttoner', type: 'exercise', showHistory: 1, id: '', key: 'melody_key_major_c_tunes_15_play_4-4_x__lvl1'},*/
				],
			},
			/*
        Intervall
      */
			progressionIntervalNatural1: {
				// always starts on c
				text: 'Intervall, naturliga 1-5',
				category: 'interval',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Rena/stora 1-5',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'fkBUDAORrl06B9L2Haki',
						key: 'interval_diatonic_listen_15__lvl3',
					},
					{ text: 'Samklang', type: 'section' },
					{
						text: 'Samklang: 2-4',
						points: 190,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'prbz9A3IeRQTw8Gzz0n1',
						key: 'interval_diatonic_listen_24_harmonic__lvl1',
					},
					{
						text: 'Samklang: 3-5',
						points: 190,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'x9Elji1UN7MECmjKWbNw',
						key: 'interval_diatonic_listen_35_harmonic__lvl1',
					},
					{
						text: 'Samklang: 2-5',
						points: 190,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '3WP7B8JtB1tkIsUwOMiK',
						key: 'interval_diatonic_listen_25_harmonic__lvl1',
						activateProgression: 'progressionIntervalNatural2',
					},
				],
			},
			progressionIntervalNatural2: {
				// always starts on c
				text: 'Intervall, naturliga 5-8',
				category: 'interval',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Rena/stora 5-7',
						points: 190,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '4g8EVabyaoa0cBIxtAxT',
						key: 'interval_diatonic_listen_57__lvl1',
					},
					{
						text: 'Rena/stora 6-8',
						points: 190,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '6R5nCJS3lQ3nnqwuVN39',
						key: 'interval_diatonic_listen_68__lvl1',
					},
					{
						text: 'Rena/stora 5-8',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'yvBCH4woTzGIOkMeukmD',
						key: 'interval_diatonic_listen_58__lvl1',
					},
					{ text: 'Samklang', type: 'section' },
					{
						text: 'Samklang: 5-7',
						points: 190,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'zL1AUCyKpUluiHoMP1Wr',
						key: 'interval_diatonic_listen_57_harmonic__lvl1',
					},
					{
						text: 'Samklang: 6-8',
						points: 190,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '3UbBnPNvsfCVAjYsPAlW',
						key: 'interval_diatonic_listen_68_harmonic__lvl1',
					},
					{
						text: 'Samklang: 5-8',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'EmDwLxNXw5WuttkBYW0d',
						key: 'interval_diatonic_listen_58_harmonic__lvl1',
						activateProgression: 'progressionIntervalNatural3',
					},
				],
			},
			progressionIntervalNatural3: {
				// always starts on c
				text: 'Intervall, naturliga 1-8',
				category: 'interval',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Rena/stora 3-6',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '8YWFcqtMG2nrv2BsVWkP',
						key: 'interval_diatonic_listen_36__lvl1',
					},
					{
						text: 'Rena/stora 1-6',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'InG8PFYvIU1bmrnnbxJZ',
						key: 'interval_diatonic_listen_16__lvl1',
					},
					{
						text: 'Rena/stora 4-8',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '3uabA7bMiWEBIHpAJUCw',
						key: 'interval_diatonic_listen_48__lvl1',
					},
					{
						text: 'Rena/stora 1-8',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'RhHap7gRhSr5h3JfhYnq',
						key: 'interval_diatonic_listen_18__lvl1',
					},
				],
			},
			progressionIntervalNatural4: {
				// start notes changes (for the first time)
				text: 'Intervall, naturliga (nivå 2)',
				category: 'interval',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Rena/stora 2-4',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '2Y7G7LCKe6JXhzJntaHx',
						key: 'interval_diatonic_listen_24__lvl3',
					},
					{
						text: 'Rena/stora 3-5',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'EtDPqRyo23RUh886z8vr',
						key: 'interval_diatonic_listen_35__lvl3',
					},
					{
						text: 'Rena/stora 1-5',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'dUCVnqeno2ZFJABCklxM',
						key: 'interval_diatonic_listen_15__lvl3',
					},
					{
						text: 'Rena/stora 4-6',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'BMWycaF8yGXAl4u8AA5b',
						key: 'interval_diatonic_listen_46__lvl3',
					},
					{
						text: 'Rena/stora 5-8',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'rAx7c4NzYdo4NsvZTJG9',
						key: 'interval_diatonic_listen_58__lvl3',
					},
					{
						text: 'Rena/stora 3-6',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'adrR0kwzjv3oZcBzi4kK',
						key: 'interval_diatonic_listen_36__lvl3',
					},
					{
						text: 'Rena/stora 1-8',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'msDJqpc5pmvxXEm4zNg9',
						key: 'interval_diatonic_listen_18__lvl3',
					},
					{
						text: 'Samklang: 2 och 3',
						points: 190,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'qhMq8VAHV05rgl5xrjxE',
						key: 'interval_diatonic_listen_23_harmonic__lvl2',
					},
					{
						text: 'Samklang: 3 och 4',
						points: 190,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '0vt5b3fI1aOt4eQ2Zh5T',
						key: 'interval_diatonic_listen_34_harmonic__lvl2',
					},
					{
						text: 'Samklang: 2-4',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '0sHAdIXmtM6LV01TbiSX',
						key: 'interval_diatonic_listen_24_harmonic__lvl2',
					},
					{
						text: 'Samklang: 4 och 5',
						points: 190,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'DJgPeipdyCFjiWPPWBSl',
						key: 'interval_diatonic_listen_45_harmonic__lvl2',
					},
					{
						text: 'Samklang: 2-5',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'rv8DyW8Uw6fEZrQ7KPx3',
						key: 'interval_diatonic_listen_25_harmonic__lvl2',
					},
				],
			},
			progressionInterval15: {
				text: 'Intervall, 1-5',
				category: 'interval',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Kvart och kvint',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'iKR3MYBJ9aUSql64Bjj1',
						key: 'interval_augDim_listen_45__lvl2',
					},
					{
						text: 'Stor/liten 2 och 3',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'mwyX3uYhE4hPc6jY6EpW',
						key: 'interval_minor_listen_23__lvl2',
					},
					{
						text: '1-5',
						points: 400,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'JsIVGuIybyu21OCtiDSc',
						key: 'interval_augDim_listen_15__lvl2',
					},
					{ text: 'Samklang', type: 'section' },
					{
						text: 'Samklang: Kvart och kvint',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '2Zmu4djWMBxY1uJ52v5U',
						key: 'interval_augDim_listen_45_harmonic__lvl2',
					},
					{
						text: 'Samklang: 2 och 3',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'ztOLGVWN4uCiM0WwPzHg',
						key: 'interval_minor_listen_23_harmonic__lvl2',
					},
					{
						text: 'Samklang: 2-5',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'maKmF9XiaQrP64yl7ePJ',
						key: 'interval_minor_listen_25_harmonic__lvl2',
					},
				],
			},
			progressionInterva58: {
				text: 'Intervall, 5-8',
				category: 'interval',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Stor/liten 6 och 7',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'FY2qKzsIJVOZqknpgUGU',
						key: 'interval_minor_listen_67__lvl2',
					},
					{
						text: '5-8',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'BtSdF6TyMWv6Z6UNIgBB',
						key: 'interval_augDim_listen_58__lvl2',
					},
					{
						text: 'Samklang: 6 och 7',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'bE1oVWeoVykxV3GnQxbM',
						key: 'interval_minor_listen_67_harmonic__lvl2',
					},
					{
						text: 'Samklang: 5-8',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'l6MrvxOWB2WL3dQ84Bmn',
						key: 'interval_augDim_listen_58_harmonic__lvl2',
					},
				],
			},
			progressionInterval18: {
				text: 'Interval, 1-8',
				category: 'interval',
				progression: true,
				progressionPriority: 1,
				sections: [
					{ text: 'Lyssna på intervall, uppåt', type: 'section' },
					{
						text: 'Stor/liten 2, 3, 6 och 7',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'KlrZZzEixfnwhln0qqIM',
						key: 'interval_minor_listen_2367__lvl2',
					},
					{
						text: 'Intervall 1-8',
						points: 480,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'yGVoWY8Usx2heHpMDOgm',
						key: 'interval_augDim_listen_18__lvl2',
					},
					{ text: 'Lyssna på intervall, samklang', type: 'section' },
					{
						text: 'Samklang: Rena/stora 1-8',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'swqZxNpD2SPHhhkW3cde',
						key: 'interval_diatonic_listen_18_harmonic__lvl3',
					},
					{
						text: 'Samklang: Kvart och kvint',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'gDL28UJC8Nb0dcGZjkr5',
						key: 'interval_augDim_listen_45_harmonic__lvl2',
					},
					{
						text: 'Samklang: Stor/liten 2 och 3',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'VA1OBToyvCR5PJcXa64O',
						key: 'interval_minor_listen_23_harmonic__lvl2',
					},
					{
						text: 'Samklang: Stor/liten 6 och 7',
						points: 300,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'hAG0YSiM7wlqF5PFbxMp',
						key: 'interval_minor_listen_67_harmonic__lvl2',
					},
					{
						text: 'Samklang: Stor/liten 2, 3, 6 och 7',
						points: 320,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'G9gRngIIfTkr8GcFa7D7',
						key: 'interval_minor_listen_2367_harmonic__lvl2',
					},
					{
						text: 'Samklang: 1-5',
						points: 320,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'k1RNWEFe3ORgjZTlq9ei',
						key: 'interval_augDim_listen_15_harmonic__lvl2',
					},
					{
						text: 'Samklang: 5-8',
						points: 320,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '76haNXITWkZbk30JvPp6',
						key: 'interval_augDim_listen_58_harmonic__lvl2',
					},
					{
						text: 'Samklang: 1-8',
						points: 480,
						pointsSingle: 110,
						pointsUnlockNext: 110,
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'eDCQX7K40TlTxuucFTDw',
						key: 'interval_augDim_listen_18_harmonic__lvl2',
					},
				],
			},
			progressionIntervaDown15: {
				text: 'Intervall (nedåt), 1-5',
				category: 'melody',
				progression: true,
				progressionPriority: 2,
				sections: [],
			},
			/*
        Ackord
      */
			progressionChordMajorMinor: {
				text: 'Ackord, dur/moll',
				category: 'melody',
				progression: true,
				sections: [],
			},
			progressionChordAugDim: {
				text: 'Ackord, +5/b5',
				category: 'melody',
				progression: true,
				sections: [],
			},
			progressionChordSeventh: {
				text: 'Septimackord',
				category: 'melody',
				progression: true,
				sections: [],
			},
			/*
        Ackordprogressioner
      */
			progressionChordProgMajor145: {
				text: 'Huvudfunktioner i dur',
				category: 'melody',
				progression: true,
				sections: [],
			},
			progressionChordProgMinor145: {
				text: 'Huvudfunktioner i moll',
				category: 'melody',
				progression: true,
				sections: [],
			},
			progressionChordProgMajorParallels: {
				text: 'Paralleller i dur',
				category: 'melody',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Bas: IIm eller IV?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'D1cSrPuK4zu9sNPL5cql',
						key: 'harmony_progression_major_1245_listen_base__lvl1',
					},
					{
						text: 'IIm eller IV?',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '2OWXhsgA5C0FCfTDIz2q',
						key: 'harmony_progression_major_1245_listen__lvl1',
					},
					{
						text: 'Bas: IIm',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'GK6AMiXSs9JP0oMS3qfV',
						key: 'harmony_progression_major_1245_listen_base__lvl2',
					},
					{
						text: 'IIm',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '6aqXKG9HGQmnRXiIlFoO',
						key: 'harmony_progression_major_1245_listen__lvl2',
					},
					{
						text: 'Bas: VIm',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'IvOURQV4gZRXYWtOmigP',
						key: 'harmony_progression_major_12456_listen_base__lvl1',
					},
					{
						text: 'VIm',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '3MH2a30kqSnzCsTAcDhB',
						key: 'harmony_progression_major_12456_listen__lvl1',
					},
					{
						text: 'Bas: IIIm',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'A0egN8v9GjKllSNd9jOp',
						key: 'harmony_progression_major_123456_listen_base__lvl1',
					},
					{
						text: 'IIIm',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'wc7F89ZustFMKB0s468T',
						key: 'harmony_progression_major_123456_listen__lvl1',
					},
				],
			},
			progressionChordProgMinorParallels: {
				text: 'Paralleller i moll',
				category: 'melody',
				progression: true,
				progressionPriority: 1,
				sections: [
					{
						text: 'Bas: bIII',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 's0xfStSwhy6qMAPvBaWD',
						key: 'harmony_progression_minor_1345_listen_base__lvl1',
					},
					{
						text: 'bIII',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: '1YZjstkfG3d6xQoyrQQL',
						key: 'harmony_progression_minor_1345_listen__lvl1',
					},
					{
						text: 'Bas: bVI',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'i7zVw2y4dr8xixQ9488Z',
						key: 'harmony_progression_minor_13456_listen_base__lvl1',
					},
					{
						text: 'bVI',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'k0u0eI6cl1XOS97sfWhH',
						key: 'harmony_progression_minor_13456_listen___lvl1',
					},
					{
						text: 'Bas: bVII',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'MRlMk38B9VaDIxXH3ISh',
						key: 'harmony_progression_minor_134567_listen_base__lvl1',
					},
					{
						text: 'bVII',
						showHistory: 1,
						showHistorySummary: 5,
						type: 'exercise',
						id: 'LIU8C7xtM6S01irFl8Do',
						key: 'harmony_progression_minor_134567_listen__lvl1',
					},
				],
			},
		};
		this.progressionActivators = {};
		Object.entries(Syllabus.chapters).forEach(([key, chapter]) => {
			if (chapter.progression) {
				chapter.sections.forEach((section) => {
					section.progression = true;
				});
			}
			chapter.sections.forEach((section) => {
				if (section.activateProgression) {
					const strPos = section.activateProgression.indexOf(':');
					const id =
						strPos >= 0
							? section.activateProgression.substring(0, strPos)
							: section.activateProgression;
					let taskId =
						strPos >= 0
							? section.activateProgression.substring(strPos + 1)
							: null;
					if (!taskId) {
						Syllabus.chapters[id].sections.forEach((task) => {
							if (!taskId && task.type !== 'section') taskId = task.id;
						});
					}
					this.progressionActivators[taskId] = section.id;
				}
			});
		});
	}

	async init() {
		if (!this.id) return;
		if (!Syllabus.templates || !(this.id in Syllabus.templates)) {
			await Syllabus.loadTemplate(this.id);
		}
		if (!(this.id in Syllabus.templates)) {
			console.warn('MISSING TEMPLATE', this.id);
		}
		this.name = Syllabus.templates[this.id].name;
		this.chapters = Syllabus.templates[this.id].chapters;
		log.debug('syllabus loaded', this, Syllabus.templates, Syllabus.chapters);
		log.debug('syllabus chapters', Syllabus.chapters);
		this.initDone = true;
	}

	reset() {
		if (User.getCurrentUser().isDevUser) {
			this.userData = {};
			this.save();
		}
	}

	async teacherMode() {
		if (User.getCurrentUser().type === 'admin') {
			this.mode = 'teacher';
			const doc = await firebase.db.collection('grades').doc(this.uid).get();
			if (doc.exists) {
				const data = doc.data();
				if ('syllabus' in data) {
					//log.debug('graaade syllabus');
					this.chapters.forEach((chapter) => {
						//log.debug('graaade chapter', chapter);
						Syllabus.chapters[chapter].sections.forEach((task) => {
							//log.debug('graaade task', task, data.syllabus);
							if (task.id in data.syllabus && task.id in this.userData) {
								//task.grade = data.syllabus[task.id];
								this.userData[task.id].grade = data.syllabus[task.id];
								//log.debug('GRAAADE', task);
							}
						});
					});
				}
				this.userNote = doc.data().note;
				this.userNotice = doc.data().notice;
				this.userBook = doc.data().book;
			}
		}
	}

	static async getAll() {
		Syllabus.initData();
		if (!Syllabus.allLoaded) {
			Syllabus.allLoaded = true;
			const result = await firebase.db.collection('syllabuses').get();
			const docs = result.docs.map((doc) => doc);
			for (const doc in docs.docs) {
				//log.debug('syllabus.getAll', doc);
				await Syllabus.loadTemplate(doc.id);
			}
		}
		return Syllabus.templates;
	}

	static async loadTemplate(syllabusId) {
		//log.debug('syllabus load template', syllabusId)
		if (!Syllabus.templates) Syllabus.templates = {};
		const doc = await firebase.db
			.collection('syllabuses')
			.doc(syllabusId)
			.get();
		if (doc.exists) {
			Syllabus.templates[syllabusId] = doc.data();
			if ('chapterData' in Syllabus.templates[syllabusId])
				Syllabus.loadChapters(Syllabus.templates[syllabusId].chapterData);
			for (const chapter of Syllabus.templates[syllabusId].chapters) {
				//.forEach(async chapter => {
				if (!Syllabus.chapters || !(chapter in Syllabus.chapters))
					await Syllabus.loadChapter(chapter);
			}
		} else {
			logger.error('Syllabus does not exists: ' + syllabusId);
		}
	}

	static async loadChapter(chapterId) {
		//log.debug('syllabus load chapter', chapterId)
		if (!Syllabus.chapters) Syllabus.chapters = {};
		const doc = await firebase.db.collection('chapters').doc(chapterId).get();
		if (doc.exists) {
			Syllabus.chapters[chapterId] = doc.data();
		} else {
			log.debug('Syllabus chapter does not exist', chapterId);
			logger.error('Syllabus Chapter does not exists: ' + chapterId);
		}
	}

	static loadChapters(data) {
		if (!Syllabus.chapters) Syllabus.chapters = {};
		Object.entries(data).forEach(([key, value]) => {
			Syllabus.chapters[key] = value;
		});
	}

	async save() {
		const data = { syllabus: this.userData };
		const result = await firebase.db
			.collection('users')
			.doc(this.uid)
			.update(data);
		this.raiseEvent('update', { id: null, status: null, uid: this.uid });
	}

	async skipChapter(chapterId) {
		Syllabus.chapters[chapterId].sections.forEach((item) => {
			this.updateTaskStatus(item.id, 'skipped');
		});
	}

	getTaskById(id) {
		let task = null;
		Syllabus.templates[this.id].chapters.forEach((chapterId) => {
			Syllabus.chapters[chapterId].sections.forEach((item) => {
				if (id === item.id) task = item;
			});
		});
		return task;
	}

	getChapterByTaskId(id) {
		let chapter = null;
		Syllabus.templates[this.id].chapters.forEach((chapterId) => {
			Syllabus.chapters[chapterId].sections.forEach((item) => {
				if (id === item.id) chapter = Syllabus.chapters[chapterId];
			});
		});
		return chapter;
	}

	getChaptersByCategory(category) {
		const result = [];
		Syllabus.templates[this.id].chapters.forEach((chapterId) => {
			if (Syllabus.chapters[chapterId].category === category)
				result.push(Syllabus.chapters[chapterId]);
		});
		return result;
	}

	/**
	 *
	 * @param {string} id - Task.id
	 * @param {('done', 'completed', 'improve', 'active', 'skipped', 'review', '')} status - status string
	 * @param {boolean} alwaysUpdate - Normally done/completed are never updated (ie if done again)
	 * When a exercise is graded by teacher, it is marked as completed (when done by user, it's marked as done).
	 * Improve means a progression task was completed, but should be done again.
	 */
	async updateTaskStatus(
		id,
		status,
		alwaysUpdate = false,
		delayRunDays = 0,
		updateCategoryRun = false
	) {
		if (id) {
			const data = {};
			if (!alwaysUpdate) {
				const curStatus = this.getTaskStatus(id);
				if (
					curStatus === 'done' ||
					curStatus === 'completed' ||
					curStatus === 'improve'
				)
					return;
			}
			if (
				this.getTaskStatus(id) === 'review' &&
				alwaysUpdate &&
				status !== 'done'
			)
				this.removeReview(id);
			data['syllabus.' + id + '.status'] = status;
			if (delayRunDays > 0)
				data['syllabus.' + id + '.delayRun'] =
					new firebase.firebase.firestore.Timestamp(
						dayjs().unix() + 3600 * 24 * delayRunDays,
						0
					);
			if (updateCategoryRun) {
				const chapter = this.getChapterByTaskId(id);
				if (chapter.category) {
					data[
						'syllabus._categories.' + chapter.category + '.lastProgressionRun'
					] = new firebase.firebase.firestore.Timestamp(dayjs().unix(), 0);
				}
			}

			const result = await firebase.db
				.collection('users')
				.doc(this.uid)
				.update(data);
			if (status === 'review') this.addReview(id);
			this.raiseEvent('update', { id: id, status: status, uid: this.uid });
		}
	}

	addReview(id) {
		const task = this.getTaskById(id);
		const chapter = this.getChapterByTaskId(id);
		if (!Array.isArray(this.review)) this.review = [];
		if (this.review.find((item) => item.id === id)) {
			log.error('Syllabus addReview: id already in review', id);
		} else {
			this.review.push({
				text: task.text,
				chapterText: chapter.text,
				id: task.id,
				key: task.key,
				status: 'active',
				type: task.type,
			});
			this.saveReviews();
		}
	}

	removeReview(id) {
		if (!Array.isArray(this.review)) return;
		const index = this.review.findIndex((item) => item.id === id);
		if (index >= 0) {
			this.review.splice(index, 1);
			this.saveReviews();
		}
	}

	saveReviews() {
		firebase.db
			.collection('users')
			.doc(this.uid)
			.update({ syllabusReview: this.review });
	}

	async approveReviews() {
		this.review.forEach((item) => {
			if (item.status === 'done')
				this.updateTaskStatus(item.id, 'completed', true);
		});
		const data = {};
		const doc = await firebase.db
			.collection('reviewHistory')
			.doc(this.uid)
			.get();
		if (doc.exists) {
			data['history.' + Date.now()] = this.review;
			firebase.db
				.collection('reviewHistory')
				.doc(this.uid)
				.update(data)
				.then((result) => {
					this.review = [];
					this.saveReviews();
				});
		} else {
			data['history'] = {};
			data['history']['' + Date.now()] = this.review;
			firebase.db
				.collection('reviewHistory')
				.doc(this.uid)
				.set(data)
				.then((result) => {
					this.review = [];
					this.saveReviews();
				});
		}
	}

	clearReviews() {
		this.review = [];
		this.saveReviews();
		this.chapters.forEach((chapter) => {
			Syllabus.chapters[chapter].sections.forEach((item) => {
				if (this.getTaskStatus(item.id) === 'review') {
					this.updateTaskStatus(item.id, 'completed', true);
				}
			});
		});
	}

	async getReviewHistory() {
		if (!this.reviewHistory) {
			const doc = await firebase.db
				.collection('reviewHistory')
				.doc(this.uid)
				.get();
			if (doc.exists) {
				this.reviewHistory = Object.keys(doc.data().history).map((key) => {
					return { timestamp: key, items: doc.data().history[key] };
				});
				this.reviewHistory = this.reviewHistory.sort((a, b) => {
					return b.timestamp - a.timestamp;
				});
			}
		}
		return this.reviewHistory;
	}

	reviewCount() {
		if (!Array.isArray(this.review)) return 0;
		return this.review.length;
	}

	availableReviewCount() {
		let count = 0;
		this.review.forEach((item) => {
			if (item.status === 'active') count++;
		});
		return count;
	}

	reviewHasId(id) {
		return this.getReviewItem(id) ? true : false;
	}

	addReviewStatistics(id, statistics) {
		const dateString = Date.now();
		const item = this.getReviewItem(id);
		if (!('statistics' in item)) item.statistics = {};
		item.statistics['' + dateString] = statistics;
		item.status = 'done';
		this.saveReviews();
	}

	getReviewItem(id) {
		return this.review.find((item) => item.id === id);
	}

	getReviewTasks() {
		if (!Array.isArray(this.review)) return [];
		return this.review;
	}

	getNextReviewTask() {
		let task = null;
		this.review.forEach((item) => {
			if (item.status === 'active') {
				if (!task) task = this.getTaskById(item.id);
			}
		});
		return task;
	}

	getTaskStatus(id) {
		if (!this.userData) return '';
		if (id in this.userData) {
			return this.userData[id].status;
		} else {
			return '';
		}
	}

	getSelfStudyStatus(id) {
		if (!this.userData) return false;
		if (id in this.userData) return this.userData[id].selfStudy === true;
		return false;
	}

	async toggleSelfStudy(id) {
		const isSelfStudy = this.getSelfStudyStatus(id);
		const data = {};
		data['syllabus.' + id + '.selfStudy'] = !isSelfStudy;

		const result = await firebase.db
			.collection('users')
			.doc(this.uid)
			.update(data);
		//this.raiseEvent('update', { id: id, status: status, uid: this.uid });
	}

	getTaskRunCount(id) {
		if (!this.userData) return 0;
		if (id in this.userData) {
			return this.userData[id].runCount ? this.userData[id].runCount : 0;
		} else {
			return 0;
		}
	}

	getGradeData(id) {
		if (!this.userData) return '';
		if (id in this.userData) {
			return this.userData[id].grade;
		} else {
			return null;
		}
	}

	getTaskHistory(id, count, useLevelScoreIfAvailable = false) {
		if (!this.userData) return [];
		if (
			useLevelScoreIfAvailable &&
			id in this.userData &&
			'levelScoreMax' in this.userData[id]
		) {
			return [{ score: this.userData[id].levelScoreMax }];
		} else if (id in this.userData && 'statistics' in this.userData[id]) {
			const stats = [];
			/*const keys = Object.keys(this.userData[id].statistics).sort((a,b) => b - a).slice(0, count);
            console.log('TAAAAAT', this.userData[id].statistics);
            keys.forEach(key => stats.push(this.userData[id].statistics[key]));*/
			let index = 0;
			const keys = Object.keys(this.userData[id].statistics).sort(
				(a, b) => b - a
			);
			do {
				// do not include cancelled
				if (!this.userData[id][keys[index].cancelled])
					stats.push(this.userData[id].statistics[keys[index]]);
				index++;
			} while (index < keys.length - 1 && stats.length < count);
			return stats;
		} else {
			return [];
		}
	}

	getTaskLevel(id) {
		if (
			!this.userData ||
			!(id in this.userData) ||
			!('level' in this.userData[id])
		) {
			return -1;
		} else {
			return this.userData[id].level;
		}
	}

	/**
	 *
	 * @param {string} id - Task.id
	 * @param {number} level - 0-2
	 */
	async setTaskLevel(id, level, score = null) {
		if (id) {
			const data = {};
			data['syllabus.' + id + '.level'] = level;
			if (score !== null) data['syllabus.' + id + '.levelScoreMax'] = score;
			const result = await firebase.db
				.collection('users')
				.doc(this.uid)
				.update(data);
			//this.raiseEvent('update', {id: id, status: status, uid: this.uid});
		}
	}

	// max score of the best level exercise has been completed at
	getTaskLevelMaxScore(id) {
		if (!(id in this.userData) || !('levelScoreMax' in this.userData[id])) {
			return -1;
		} else {
			return this.userData[id].levelScoreMax;
		}
	}

	getTaskPoints(id) {
		if (
			!this.userData ||
			!(id in this.userData) ||
			!('points' in this.userData[id])
		) {
			return 0;
		} else {
			return this.userData[id].points;
		}
	}

	getTaskMaxPoints(id) {
		if (
			!this.userData ||
			!(id in this.userData) ||
			!('maxPoints' in this.userData[id])
		) {
			return 0;
		} else {
			return this.userData[id].maxPoints;
		}
	}

	getTaskStars(id) {
		if (
			!this.userData ||
			!(id in this.userData) ||
			!('stars' in this.userData[id])
		) {
			return -1;
		} else {
			return this.userData[id].stars;
		}
	}

	getTaskLastRun(id) {
		if (
			!this.userData ||
			!(id in this.userData) ||
			!('lastRun' in this.userData[id])
		) {
			return new firebase.firebase.firestore.Timestamp(0, 0);
		} else {
			return this.userData[id].lastRun;
		}
	}

	getTaskDelayRun(id) {
		if (
			!this.userData ||
			!(id in this.userData) ||
			!('delayRun' in this.userData[id])
		) {
			return new firebase.firebase.firestore.Timestamp(0, 0);
		} else {
			console.log('getTaskDelayRun', id, this.userData[id].delayRun);
			return this.userData[id].delayRun;
		}
	}

	getTaskIsDelayed(id) {
		const delayRun = this.getTaskDelayRun(id);
		return delayRun.seconds > dayjs().unix();
	}

	getLastCategoryRun(category) {
		// syllabus._categories.' + chapter.category + '.lastProgressionRun
		if (
			!this.userData ||
			!('_categories' in this.userData) ||
			!(category in this.userData._categories) ||
			!('lastProgressionRun' in this.userData._categories[category])
		) {
			return new firebase.firebase.firestore.Timestamp(0, 0);
		} else {
			return this.userData._categories[category].lastProgressionRun;
		}
	}

	shouldPrioritizeCategory(category, maxDays = 17) {
		if (!category) return false;
		const lastRun = this.getLastCategoryRun(category);
		const now = new firebase.firebase.firestore.Timestamp(dayjs().unix(), 0);
		const diff = now.seconds - lastRun.seconds;
		return diff > 3600 * 24 * maxDays;
	}

	async addTaskStatistics(id, statistics) {
		if (id && statistics && 'number' in statistics && statistics.number > 0) {
			const data = {};
			const dateString = Date.now();
			const statData = {
				completedQuestions: statistics.number,
				totallyCorrect: statistics.totallyCorrect,
				exerciseCount: statistics.exerciseCount,
				cancelled: statistics.cancelled === true,
			};
			if ('statsId' in statistics) statData.statsId = statistics.statsId;
			if ('score' in statistics) statData.score = statistics.score;
			if ('version' in statistics) statData.version = statistics.version;
			if ('validateLevelMax' in statistics && !statistics.cancelled) {
				statData.validateLevelMax = statistics.validateLevelMax;
				const taskLevel = this.getTaskLevel(id);
				if (
					taskLevel === -1 ||
					taskLevel > statistics.validateLevelMax ||
					(taskLevel === statistics.validateLevelMax &&
						statistics.score > this.getTaskLevelMaxScore(id))
				)
					await this.setTaskLevel(
						id,
						statistics.validateLevelMax,
						statistics.score
					);
			}
			data['syllabus.' + id + '.statistics.' + dateString] = statData;
			if (this.reviewHasId(id)) this.addReviewStatistics(id, statData);
			if (
				statistics.points &&
				'total' in statistics.points &&
				statistics.points.total > 0
			) {
				const points = this.getTaskPoints(id);
				data['syllabus.' + id + '.points'] = points + statistics.points.total;
				if (points > this.getTaskMaxPoints(id))
					data['syllabus.' + id + '.maxPoints'] = points;
			}
			if (
				statistics.points &&
				'stars' in statistics.points &&
				statistics.points.stars > 0
			) {
				const stars = this.getTaskStars(id);
				// only update if no stars or more stars. If zero stars, it's a new task (might also be 0 stars again, so some unnessecary db-writes might occur now...)
				if (stars === 0 || statistics.points.stars > stars)
					data['syllabus.' + id + '.stars'] = statistics.points.stars
						? statistics.points.stars
						: 0;
			}
			data['syllabus.' + id + '.lastRun'] =
				new firebase.firebase.firestore.Timestamp(Date.now() / 1000, 0);

			//console.log('sylstat add', data, statistics)
			const result = await firebase.db
				.collection('users')
				.doc(this.uid)
				.update(data);
			this.raiseEvent('update', {
				id: id,
				status: null,
				uid: this.uid,
				cancelled: statistics.cancelled === true,
				updateStatistics: true,
			});
		}
	}

	async updateTaskLastRun(id) {
		const data = {};
		data['syllabus.' + id + '.lastRun'] =
			new firebase.firebase.firestore.Timestamp(Date.now() / 1000, 0);
		const result = await firebase.db
			.collection('users')
			.doc(this.uid)
			.update(data);
		this.raiseEvent('update', {
			id: id,
			status: null,
			uid: this.uid,
			cancelled: false,
			updateStatistics: true,
		});
	}

	taskIsHomework(id) {
		let isHomework = false;
		Object.keys(Syllabus.chapters).forEach((chapter) => {
			Syllabus.chapters[chapter].sections.forEach((item) => {
				if (item.id && item.id === id && item.isHomework) {
					isHomework = true;
				}
			});
		});
		return isHomework;
	}

	taskActivatesProgression(id) {
		let activatesProgression = false;
		Object.keys(Syllabus.chapters).forEach((chapter) => {
			Syllabus.chapters[chapter].sections.forEach((item) => {
				if (item.id && item.id === id && item.activateProgression) {
					activatesProgression = true;
				}
			});
		});
		return activatesProgression;
	}

	taskIsProgression(id) {
		let isProgression = false;
		Object.keys(Syllabus.chapters).forEach((chapter) => {
			Syllabus.chapters[chapter].sections.forEach((item) => {
				if (item.id && item.id === id && item.progression) {
					isProgression = true;
				}
			});
		});
		return isProgression;
	}

	async updateTaskGrade(id, grade) {
		const docRef = firebase.db.collection('grades').doc(this.uid);
		const doc = await docRef.get();
		if (!doc.exists) {
			const data = { syllabus: {} };
			data.syllabus[id] = {
				grade: grade,
				gradeDate: new firebase.firebase.firestore.Timestamp(
					Date.now() / 1000,
					0
				),
			};
			docRef.set(data);
		} else {
			const data = doc.data();
			if (!('syllabus' in data)) data.syllabus = {};
			if (!(id in data.syllabus)) data.syllabus[id] = {};
			if ('grade' in data.syllabus[id]) {
				// store old grade
				if (!('gradyHistory' in data.syllabus[id]))
					data.syllabus[id].gradeHistory = [];
				data.syllabus[id].gradeHistory.push({
					grade: data.syllabus[id].grade,
					gradeDate: data.syllabus[id].gradeDate,
				});
			}
			data.syllabus[id].grade = grade;
			data.syllabus[id].gradeDate = new firebase.firebase.firestore.Timestamp(
				Date.now() / 1000,
				0
			);
			docRef.set(data);
		}
	}

	async addComment(id, comment) {
		const docRef = firebase.db.collection('grades').doc(this.uid);
		const doc = await docRef.get();
		if (!doc.exists) {
			const data = { syllabus: {} };
			data.syllabus[id] = {
				comments: [
					{
						comment: comment,
						date: new firebase.firebase.firestore.Timestamp(
							Date.now() / 1000,
							0
						),
					},
				],
			};
			docRef.set(data);
		} else {
			const data = doc.data();
			if (!('syllabus' in data)) data.syllabus = {};
			if (!(id in data.syllabus)) data.syllabus[id] = {};
			if ('comments' in data.syllabus[id]) {
				data.syllabus[id].comments.push({
					comment: comment,
					date: new firebase.firebase.firestore.Timestamp(Date.now() / 1000, 0),
				});
			} else {
				data.syllabus[id].comments = [
					{
						comment: comment,
						date: new firebase.firebase.firestore.Timestamp(
							Date.now() / 1000,
							0
						),
					},
				];
			}
			docRef.set(data);
		}
	}

	/**
	 *
	 * completed, active, notStarted, empty
	 */
	getChapterStatus(chapterId, includeDone = true) {
		let status = '';
		let hasActive = false;
		let hasUnverified = false;
		let skippedCount = 0;
		let sectionCount = 0;
		Syllabus.chapters[chapterId].sections.forEach((task) => {
			const taskStatus =
				task.type === 'section'
					? 'section'
					: task.id
					? this.getTaskStatus(task.id)
					: 'skipped';
			switch (taskStatus) {
				case 'section':
					sectionCount++;
					break;
				case 'skipped':
					if (status !== 'active' && status !== '') status = 'completed';
					skippedCount++;
					break;
				case 'completed':
					if (status !== 'active') status = 'completed';
					break;
				case 'done':
					if (includeDone && status !== 'active') status = 'completed';
					if (!includeDone) status = 'active';
					hasUnverified = true;
					break;
				case 'active':
					status = 'active';
					hasActive = true;
					break;
				case '':
					//if (status !== '') status = 'active';
					if (status === 'completed') status = 'active';
					break;
				case 'review':
					status = 'active';
					break;
				case 'improve':
					status = 'active';
					break;
				default:
					logger.error(
						'Syllabus: unknown status ' + taskStatus + ', id ' + chapterId
					);
					log.debug('unknown ', taskStatus);
					status = 'active';
			}
		});
		if (
			skippedCount + sectionCount ===
			Syllabus.chapters[chapterId].sections.length
		)
			status = 'completed';
		if (
			status === 'active' &&
			!hasActive &&
			(includeDone === true || hasUnverified === false)
		) {
			status = 'partial';
		}
		return status
			? status
			: Syllabus.chapters[chapterId].sections.length > 0
			? 'notStarted'
			: 'empty';
	}

	static fromFirebase(data, uid) {
		log.debug('syllabus fromFirebase', data);
		if (!data) data = {};
		if (!('activeSyllabus' in data)) data.activeSyllabus = 'new-user';
		if (!('syllabus' in data)) data.syllabus = {};
		if (data.activeSyllabus) {
			const syllabus = new Syllabus(data.activeSyllabus, uid);
			syllabus.userData = data.syllabus;
			syllabus.review = Array.isArray(data.syllabusReview)
				? data.syllabusReview
				: [];
			return syllabus;
		} else {
			return null;
		}
	}

	compareSyllabusFromFirebase(data) {
		// NOTE: added is not implemented!
		if (!this.userData) return;
		const diff = { updated: {}, added: {}, removed: {} };
		Object.entries(this.userData).forEach(([key, value]) => {
			if (!(key in data.syllabus)) {
				diff.removed[key] = value;
			} else {
				const lastRun = !('lastrun' in data.syllabus[key])
					? new firebase.firebase.firestore.Timestamp(0, 0)
					: data.syllabus[key].lastRun;
				let recentlyRun =
					lastRun.seconds !== 0 &&
					this.getTaskLastRun(key).seconds !== lastRun.seconds;
				if (data.syllabus[key].status !== value.status || recentlyRun) {
					diff.updated[key] = {
						oldStatus: value.status,
						...data.syllabus[key],
					};
					const task = this.getTaskById(key);
					console.log(
						'compareSyllabusFromFirebase',
						task.id,
						lastRun.seconds,
						this.getTaskLastRun(key).seconds,
						recentlyRun,
						data.syllabus[key].status,
						value.status
					);
					if (task)
						this.raiseEvent('update', {
							id: task.id,
							status: data.syllabus[key].status,
							uid: this.uid,
						});
				}
			}
		});
		return diff;
	}

	updateFromFirebase(data) {
		log.debug('syllabus update from firebase', data);
		this.userData = data;
	}

	raiseEvent(event, data) {
		log.debug('syllabus raise event', event, data, this.listeners);
		if (this.listeners) {
			log.debug('syllabus checking listeners', this.listeners);
			this.listeners.forEach((listener) => {
				log.debug('syllabus listener', listener);
				if (listener.event === event) {
					listener.callback({ event: event, ...data });
					log.debug('syllabus event match');
				} else {
					log.debug('syllabus event NOT MATCH', listener.event);
				}
			});
		}
	}

	static async setActive(uid, syllabus) {
		return firebase.db.collection('users').doc(uid).update({
			activeSyllabus: syllabus,
		});
	}

	async updateUserNote(note) {
		this.userNote = note;
		return firebase.db.collection('grades').doc(this.uid).update({
			note: note,
		});
	}

	async updateUserNotice(note) {
		this.userNotice = note;
		return firebase.db.collection('grades').doc(this.uid).update({
			notice: note,
		});
	}
	async updateUserBook(book) {
		this.userBook = book;
		return firebase.db.collection('grades').doc(this.uid).update({
			book: book,
		});
	}

	shouldStartProgressionRun() {
		return dayjs().unix() - this.lastProgressionRun.seconds > 3600 * 12;
	}

	progressionRunActive() {
		return this.progressionRunTimeLeft() > 0;
	}

	progressionRunTimeLeft(defaultIfNegative = false) {
		let timeLeft =
			this.progressionStart.seconds + this.progressionDuration - dayjs().unix();
		if (defaultIfNegative && timeLeft < 0) timeLeft = this.progressionDuration;
		return timeLeft;
	}

	/*getProgressionTasks() {
    let tasks = [[], [], []];
    let tasksImprove = [];
    let lastChapter = '';
    const shouldStartProgressionRun = this.shouldStartProgressionRun();
    const skipImprove = dayjs().unix() - this.lastProgressionImproveRun.seconds < 3600 * 24;
    Syllabus.templates[this.id].chapters.forEach((chapterId) => {
      if (Syllabus.chapters[chapterId].progression) {
        const priority = Syllabus.chapters[chapterId].progressionPriority >= 0 ? Syllabus.chapters[chapterId].progressionPriority : 1;
        Syllabus.chapters[chapterId].sections.forEach((item) => {
          const status = this.getTaskStatus(item.id)
          const lastTaskRun = this.getTaskLastRun(item.id);
          const runIsDelayed = this.getTaskIsDelayed(item.id);
          console.log('getProgressionTasks, item check', this.getTaskLastRun(item.id).seconds, this.lastProgressionRun.seconds, lastTaskRun.seconds === 0 || lastTaskRun.seconds < this.lastProgressionRun.seconds)
          if (!runIsDelayed && (shouldStartProgressionRun || lastTaskRun.seconds === 0 || lastTaskRun.seconds < this.lastProgressionRun.seconds)) {
            if (status === 'active') {
              // only display first active task of each chapter
              if (chapterId !== lastChapter) {
                tasks[priority].push(item);
              }
              lastChapter = chapterId;
            } else if (!skipImprove && status === 'improve') {
              tasksImprove.push(item);
            }
          }
        });
      }
    });
    return {tasks: tasks, improve: tasksImprove, hasTasks: tasks[0].length > 0 || tasks[1].length > 0 || tasks[2].length > 0 || tasksImprove.length > 0};
  }*/
	getProgressionTasks() {
		let tasks = [
			[[], []],
			[[], []],
			[[], []],
		];
		let lastChapter = '';
		const shouldStartProgressionRun = this.shouldStartProgressionRun();
		Syllabus.templates[this.id].chapters.forEach((chapterId) => {
			if (Syllabus.chapters[chapterId].progression) {
				const priority =
					Syllabus.chapters[chapterId].progressionPriority >= 0
						? Syllabus.chapters[chapterId].progressionPriority
						: 1;
				const categoryPriority = this.shouldPrioritizeCategory(
					Syllabus.chapters[chapterId].category
				)
					? 0
					: 1;
				Syllabus.chapters[chapterId].sections.forEach((item) => {
					const status = this.getTaskStatus(item.id);
					if (status === 'active' || status === 'improve') {
						const lastTaskRun = this.getTaskLastRun(item.id);
						const runIsDelayed = this.getTaskIsDelayed(item.id);
						if (
							!runIsDelayed &&
							(shouldStartProgressionRun ||
								lastTaskRun.seconds === 0 ||
								lastTaskRun.seconds < this.lastProgressionRun.seconds)
						) {
							// only display first active task of each chapter
							if (chapterId !== lastChapter) {
								tasks[priority][categoryPriority].push(item);
							}
							lastChapter = chapterId;
						}
					}
				});
			}
		});
		return tasks;
	}

	getHighestPriorityProgressionTasks() {
		const tasks = this.getProgressionTasks();
		if (tasks[0][0].length > 0) return tasks[0][0];
		if (tasks[0][1].length > 0) return tasks[0][1];
		if (tasks[1][0].length > 0) return tasks[1][0];
		if (tasks[1][1].length > 0) return tasks[1][1];
		if (tasks[2][0].length > 0) return tasks[2][0];
		if (tasks[2][1].length > 0) return tasks[2][1];
		return [];
	}

	/**
	 *
	 * @param {*} key id of chapter with optional id of task separated by :
	 */
	activateProgression(key) {
		console.log('activateProgression', key);
		const strPos = key.indexOf(':');
		const id = strPos >= 0 ? key.substring(0, strPos) : key;
		const taskId = strPos >= 0 ? key.substring(strPos + 1) : null;
		if (id in Syllabus.chapters && Syllabus.chapters[id].progression) {
			// match either first non-section or task with specific id
			const task = Syllabus.chapters[id].sections.find((item) => {
				return taskId ? (item.id = taskId) : item.type !== 'section';
			});
			console.log('activateProgression task', task);
			const status = this.getTaskStatus(task.id);
			console.log('activateProgression status', status);
			if (status === '' || status === 'notStarted') {
				console.log('activateProgression activate');
				this.updateTaskStatus(task.id, 'active');
			}
		} else {
			console.log('activateProgression error');
			log.error('Syllabus activateProgression: invalid id', id);
		}
	}

	async addTaskRunCount(id) {
		if (id) {
			const data = {};
			const runCount = this.getTaskRunCount(id) + 1;
			data['syllabus.' + id + '.runCount'] = runCount;
			const result = await firebase.db
				.collection('users')
				.doc(this.uid)
				.update(data);
			const task = this.getTaskById(id);
			const requiredRunCount = task.requiredRunCount
				? task.requiredRunCount
				: 1;
			return requiredRunCount - runCount;
		}
	}

	addEventListener(event, callback) {
		if (!this.listeners) this.listeners = [];
		this.listeners.push({
			event: event,
			callback: callback,
		});
	}

	removeEventListener(event, callback) {
		if (!this.listeners) return;
		const index = this.listeners.find(
			(item) => item.event === event && item.callback === callback
		);
		if (index >= 0) this.listeners.splice(index, 1);
	}
}
